import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../assets/css/infographics.css";

const Infographics = () => {
  return (
    <>
      <Helmet>
        <title>Infographics - NALAS Observatory</title>
      </Helmet>
      <div className="section mapbg">
        <div className="container-fluid">
          <div className="row">
            <h2 className="nalas-title">Infographics</h2>
          </div>
        </div>

        <div className="section-content">
          <div className="container">
            <div className="row">
              <div className="col-sm-3 p-0">
                <Link to={`/infographics/territorial-profiles`}>
                  <img
                    src={require("../../assets/images/infographics/part1.svg")}
                    className="infographics-tab-item"
                    alt="territorial profiles"
                  />
                </Link>
              </div>
              <div className="col-sm-3 p-0">
                <Link to={`/infographics/decentralizations-progress`}>
                  <img
                    src={require("../../assets/images/infographics/part2.svg")}
                    className="infographics-tab-item"
                    alt="decentralizations progress"
                  />
                </Link>
              </div>
              <div className="col-sm-3 p-0">
                <Link to={`/infographics/local-governments-finance`}>
                  <img
                    src={require("../../assets/images/infographics/part3.svg")}
                    className="infographics-tab-item"
                    alt="local governments finance"
                  />
                </Link>
              </div>
              <div className="col-sm-3 p-0">
                <Link to={`/infographics/waste-management`}>
                  <img
                    src={require("../../assets/images/infographics/part4.svg")}
                    className="infographics-tab-item"
                    alt="waste management"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Infographics;
