import React from "react";
import "../../assets/css/infographics.css";
import ColumnChart from "./ColumnCharts";
import RadarChart from "./RadarChart";
import RadarLineGraphChart from "./RadarLineGraphChart";
import RadarTimelineChart from "./RadarTimelineChart";

const DecentralizationProgressList = ({ charts, loading }) => {
  if (loading) {
    var element = document.getElementsByClassName("loader-wrap")[0];
    if (element) element.classList.add("active");
  } else {
    var element = document.getElementsByClassName("loader-wrap")[0];
    if (element) element.classList.remove("active");
  }

  const getChart = (chart) => {
    switch (chart.fe_chart_id) {
      case "FE_RADAR_CHART_1":
        return (
          <RadarChart
            chartData={chart.data}
            id={chart.id}
            type={chart.type}
            title={chart.title}
          />
        );
      case "FE_RADAR_TIMELINE_1":
        return (
          <RadarTimelineChart
            chartData={chart.data}
            id={chart.id}
            type={chart.type}
            title={chart.title}
          />
        );
      case "FE_RADAR_LINE_GRAPH_1":
        return (
          <RadarLineGraphChart
            chartData={chart.data}
            id={chart.id}
            type={chart.type}
            title={chart.title}
          />
        );
      case "FE_COLUMN_CHART_IMAGES_TOP_1":
        return (
          <ColumnChart
            chartData={chart.data}
            id={chart.id}
            type={chart.type}
            title={chart.title}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {charts.map((chart) => {
        return (
          <div key={chart.id} className="col-12">
            <div className="chartCont">{getChart(chart)} </div>
          </div>
        );
      })}
    </div>
  );
};

export default DecentralizationProgressList;
