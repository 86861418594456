import React from "react";
import { Helmet } from "react-helmet";
import LegalFrameworkMap from "./LegalFrameworkMap";
import "../../assets/css/learning.css";

const LegalFramework = () => {
  return (
    <>
      <Helmet>
        <title>Legislation - NALAS Observatory</title>
      </Helmet>
      <div className="section mapbg">
        <div className="container">
          <div className="row">
            <div className="digest-title">
              <h2 className="nalas-title">Legislation</h2>
            </div>
          </div>
          <div className="row margin-bottom-170">
            <LegalFrameworkMap />
          </div>
        </div>
      </div>
    </>
  );
};

export default LegalFramework;
