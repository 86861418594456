import React, { useLayoutEffect } from "react";
import "../../assets/css/infographics.css";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "../../assets/css/swm.css";
import "../../assets/css/charts.css";

am4core.useTheme(am4themes_animated);

const SWMFullstackedColumnChart = ({ chartData, id, type, title }) => {
  const seriesData = chartData.dataset_composed.properties;

  useLayoutEffect(() => {
    let chart = am4core.create(`${type}${id}`, am4charts.XYChart);
    chart.data = chartData.dataset_composed.dataset;

    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.fontSize = 12;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 290;
    categoryAxis.renderer.labels.template.wrap = true;
    categoryAxis.renderer.labels.template.maxWidth = 120;
    categoryAxis.start = 0.5;
    categoryAxis.keepSelection = true;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.minWidth = 50;
    valueAxis.fontSize = 12;

    function createSeries(field, name) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(80);
      series.columns.template.tooltipText = "{name}: {valueY}{unit}";
      series.name = name;
      series.dataFields.categoryX = "category";
      series.dataFields.valueY = field;
      series.dataFields.valueYShow = "totalPercent";
      series.dataItems.template.locations.categoryX = 0.5;
      series.stacked = true;
      series.tooltip.pointerOrientation = "vertical";

      // var bullet1 = series.bullets.push(new am4charts.LabelBullet());
      // bullet1.interactionsEnabled = false;
      // const unitCheck =
      //   chartData.dataset_composed.dataset[0].unit_as_part_of_label;
      // unitCheck === 1
      //   ? (bullet1.label.text = "{valueY}{unit}")
      //   : (bullet1.label.text = "{valueY}");
      // bullet1.label.fill = am4core.color("#ffffff");
      // bullet1.label.fontSize = 12;
      // bullet1.locationY = 0.5;

      return series;
    }

    Object.keys(seriesData).forEach((label) => {
      createSeries(label, seriesData[label]);
    });

    chart.legend = new am4charts.Legend();
    chart.legend.maxHeight = 150;
    chart.legend.scrollable = true;
    chart.legend.labels.template.fontSize = 12;

    chart.scrollbarX = new am4core.Scrollbar();

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = title.replace(/\s/g, "");

    const exportFields = { ...chart.exporting.dataFields };
    Object.keys(seriesData).forEach((label) => {
      if (exportFields[label]) {
        exportFields[label] = seriesData[label];
      }
    });
    delete exportFields.unit;
    chart.exporting.dataFields = exportFields;

    return () => {
      chart.dispose();
    };
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <h5 className="header-with-after-line">{title}</h5>
        </div>
      </div>
      <div id={type + id} className="swm-graph"></div>
    </>
  );
};

export default SWMFullstackedColumnChart;
