import React from "react";
import { Helmet } from "react-helmet";
import "../../assets/css/infographics.css";

const DecentralizationsProgress = () => {
  return (
    <>
      <Helmet>
        <title>Decentralizations Progress - NALAS Observatory</title>
      </Helmet>
      <div className="mapbg">
        <div className="single-page">
          <img
            src={require("../../assets/images/infographics/2.svg")}
            alt="Territorial Profiles"
          />
        </div>
      </div>
    </>
  );
};

export default DecentralizationsProgress;
