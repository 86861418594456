import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import "../../assets/css/charts.css";

const DateBasedChart = ({ id, type, title, chartData }) => {
  const chart = useRef(null);
  const seriesData = chartData.dataset_composed.properties;
  useLayoutEffect(() => {
    // Create chart instance
    let chart = am4core.create(`${type}${id}`, am4charts.XYChart);

    // Add data
    chart.data = chartData.dataset_composed.dataset;

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.fontSize = 12;
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.fontSize = 12;

    function createSeries(field, name) {
      // Create series
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.name = name;
      series.dataFields.dateX = "year";
      series.tooltipText = "{value}";
      series.strokeWidth = 2;
      series.minBulletDistance = 15;

      // Drop-shaped tooltips
      series.tooltip.background.cornerRadius = 20;
      series.tooltip.background.strokeOpacity = 0;
      series.tooltip.pointerOrientation = "vertical";
      series.tooltip.label.minWidth = 40;
      series.tooltip.label.minHeight = 40;
      series.tooltip.label.textAlign = "middle";
      series.tooltip.label.textValign = "middle";

      // Make bullets grow on hover
      let bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.strokeWidth = 2;
      bullet.circle.radius = 4;
      bullet.circle.fill = am4core.color("#fff");

      let bullethover = bullet.states.create("hover");
      bullethover.properties.scale = 1.3;

      // Make a panning cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.behavior = "panXY";
      chart.cursor.xAxis = dateAxis;
      chart.cursor.snapToSeries = series;

      return series;
    }

    Object.entries(seriesData).forEach((field) => {
      createSeries(field[0], field[1]);
    });

    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fontSize = 12;

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = title.replace(/\s/g, "");

    const exportFields = { ...chart.exporting.dataFields };
    delete exportFields.color;
    delete exportFields.unit_as_part_of_label;
    delete exportFields.partOfChart;
    chart.exporting.dataFields = exportFields;

    return () => {
      chart.dispose();
    };
  }, [id, type, title, chartData]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <h5 className="header-with-after-line-2">{title}</h5>
        </div>
      </div>
      <div id={type + id} style={{ width: "100%", height: "500px" }}></div>
    </>
  );
};
export default DateBasedChart;
