import React from "react";
// import { Link } from "react-router-dom";
import "../assets/css/partners.css";
// import { UncontrolledTooltip } from "reactstrap";

// Partners Sections Homepage
const PartnersSection = () => {
  return (
    <>
      <div className="partners-section">
        <div className="container">
          <div className="">
            <div className="row text-center">
              <div className="">
                <h2 className="">NALAS</h2>
              </div>
            </div>
          </div>
          <ul className="clients-grid row">
            <li>
              <a
                href="http://www.acor.ro/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="Association of Communes of Romania"
              >
                <img
                  src={require("../assets/images/members/member nalas-02.png")}
                  alt=""
                  id="nalas-member-2"
                />
              </a>
            </li>
            <li>
              <a
                href="http://www.sogfbih.ba/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="Association of Municipalities and Cities of the Federation of Bosnia and Herzegovina"
              >
                <img
                  src={require("../assets/images/members/member nalas-03.png")}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a
                href="http://www.skupnostobcin.si/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="Association of Municipalities and Towns of Slovenia"
              >
                <img
                  src={require("../assets/images/members/member nalas-04.png")}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a
                href="http://www.zels.org.mk/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="Association of the Units of Local Self-Government of the Republic of North Macedonia"
              >
                <img
                  src={require("../assets/images/members/member nalas-05.jpg")}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a
                href="http://www.marmara.gov.tr/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="Marmara Municipalities Union"
              >
                <img
                  src={require("../assets/images/members/member nalas-06.png")}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a
                href="http://www.skgo.org/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="Standing Conference of Towns and Municipalities"
              >
                <img
                  src={require("../assets/images/members/member nalas-07.jpg")}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a
                href="http://www.udruga-gradova.hr/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="Association of Cities of the Republic of Croatia"
              >
                <img
                  src={require("../assets/images/members/member nalas-08.jpg")}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a
                href="http://www.komunat-ks.net/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="Association of Kosovo Municipalities"
              >
                <img
                  src={require("../assets/images/members/member nalas-09.jpg")}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a
                href="http://www.calm.md/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="Congress of Local Authorities from Moldova"
              >
                <img
                  src={require("../assets/images/members/member nalas-12.png")}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a
                href="http://www.namrb.org/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="National Association of Municipalities in the Republic of Bulgaria"
              >
                <img
                  src={require("../assets/images/members/member nalas-13.png")}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a
                href="http://www.uom.co.me/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="Union of Municipalities of Montenegro"
              >
                <img
                  src={require("../assets/images/members/member nalas-14.jpg")}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a
                href="http://www.udruga-opcina.hr/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="Association of Municipalities in the Republic of Croatia"
              >
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    src={require("../assets/images/members/13.gif")}
                    alt="Association of Municipalities in the Republic of Croatia"
                    style={{
                      height: "95px",
                    }}
                  />
                </div>
              </a>
            </li>
            <li>
              <a
                href="http://www.alvrs.com/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="Association of Municipalities and Towns of Republic of Srpska"
              >
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    src={require("../assets/images/members/14.jpg")}
                    alt="Association of Municipalities and Towns of Republic of Srpska"
                    style={{ height: "95px", backgroundColor: "white" }}
                  />
                </div>
              </a>
            </li>
          </ul>
          <div className="">
            <div className="row text-center">
              <div className="">
                <h2 className="associate">NALAS</h2>
              </div>
            </div>
          </div>
          <ul className="clients-grid row">
            <li>
              <a
                href="http://www.shav.al/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="Association for Local Autonomy"
              >
                <img
                  src={require("../assets/images/members/member nalas-15.png")}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a
                href="http://www.aam.org.al/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="Association of Albanian Municipalities"
              >
                <img
                  src={require("../assets/images/members/member nalas-01.png")}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a
                href="http://www.ccre.org/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="Council of European Municipalities and Regions"
              >
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    src={require("../assets/images/members/22.jpg")}
                    alt="Council of European Municipalities and Regions"
                    style={{ height: "95px", backgroundColor: "white" }}
                  />
                </div>
              </a>
            </li>
            <li>
              <a
                href="http://www.energie-cites.eu/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="Energy Cities"
              >
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    src={require("../assets/images/members/23.png")}
                    alt="Energy Cities"
                    style={{ height: "95px", backgroundColor: "white" }}
                  />
                </div>
              </a>
            </li>
            <li>
              <a
                href="http://www.fedre.org/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="European Foundation for Sustainable Development of the Regions"
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    src={require("../assets/images/members/25.png")}
                    alt="European Foundation for Sustainable Development of the Regions"
                    style={{
                      width: "120px",
                      backgroundColor: "white",
                      marginTop: "30px",
                    }}
                  />
                </div>
              </a>
            </li>
            <li>
              <a
                href="http://www.staedtebund.gv.at/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="The Austrian Association of Cities and Towns - Der Österreichische Städtebund"
              >
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    src={require("../assets/images/members/27.gif")}
                    alt="The Austrian Association of Cities and Towns - Der Österreichische Städtebund"
                    style={{ height: "95px", backgroundColor: "white" }}
                  />
                </div>
              </a>
            </li>
            <li>
              <a
                href="http://www.tdbb.org.tr/"
                target="blank"
                data-toggle="tooltip"
                data-placement="right"
                title="Union of Turkish World Municipalities"
              >
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    src={require("../assets/images/members/21.png")}
                    alt="Union of Turkish World Municipalities"
                    style={{ height: "95px", backgroundColor: "white" }}
                  />
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default PartnersSection;
