import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../assets/css/learning.css";

const Digest = () => {
  return (
    <>
      <Helmet>
        <title>Digest - NALAS Observatory</title>
      </Helmet>
      <div className="section mapbg">
        <div className="container">
          <div className="row" style={{ marginTop: "60px" }}>
            <div className="about-head-bg">
              <div className="about-image-text">
                <div className="digest-cover">
                  Join our mailing list.
                  <div style={{ textAlign: "center" }}>
                    <button className="join-btn">Join now</button>
                  </div>
                </div>
              </div>
              <img src={require("../../assets/images/digest/cover.png")} />
            </div>
            <div className="digest-title">
              <h2 className="nalas-title">Local Government Practices in SEE</h2>
              <div className="by-nalas">by NALAS</div>
            </div>
            <div className="section-content digest" style={{ marginLeft: 0 }}>
              <div className="card-columns">
                <div className="card">
                  <div className="card-body">
                    {/* <Link to={`/digest-item/${1}`} className="digest-item"> */}
                    <img
                      src={require("../../assets/images/digest/waste-management.png")}
                      alt="waste management"
                      className="digest-img"
                    />
                    <div className="mb-4 digest-img-text">Waste Management</div>
                    {/* </Link> */}
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    {/* <Link to={`/digest-item/${4}`} className="digest-item"> */}
                    <img
                      src={require("../../assets/images/digest/digitalization.png")}
                      alt="Digitalization"
                      className="digest-img"
                    />
                    <div className="mb-4 digest-img-text">Digitalization</div>
                    {/* </Link> */}
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <Link to={`/digest-item/${7}`} className="digest-item">
                      <img
                        src={require("../../assets/images/digest/public-health.png")}
                        alt="Public Health"
                        className="digest-img"
                      />
                      <div className="mb-4 digest-img-text">Public Health</div>
                    </Link>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    {/* <Link to={`/digest-item/${10}`} className="digest-item"> */}
                    <img
                      src={require("../../assets/images/digest/sustainable-dev-goals.png")}
                      alt="Sustainable Development Goals"
                      className="digest-img"
                    />
                    <div className="mb-4 digest-img-text">
                      Sustainable Development Goals
                    </div>
                    {/* </Link> */}
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    {/* <Link to={`/digest-item/${2}`} className="digest-item"> */}
                    <img
                      src={require("../../assets/images/digest/water-utility.png")}
                      alt="Water Utility &amp; Sanitation"
                      className="digest-img"
                    />
                    <div className="mb-4 digest-img-text">
                      Water Utility &amp; Sanitation
                    </div>
                    {/* </Link> */}
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <Link to={`/digest-item/${5}`} className="digest-item">
                      <img
                        src={require("../../assets/images/digest/education.png")}
                        alt="Education"
                        className="digest-img"
                      />
                      <div className="mb-4 digest-img-text">Education</div>
                    </Link>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    {/* <Link to={`/digest-item/${8}`} className="digest-item"> */}
                    <img
                      src={require("../../assets/images/digest/advocacy.png")}
                      alt="Advocacy"
                      className="digest-img"
                    />
                    <div className="mb-4 digest-img-text">Advocacy</div>
                    {/* </Link> */}
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    {/* <Link to={`/digest-item/${11}`} className="digest-item"> */}
                    <img
                      src={require("../../assets/images/digest/disaster-risk-management.png")}
                      alt="Disaster Risk Management"
                      className="digest-img"
                    />
                    <div className="mb-4 digest-img-text">
                      Disaster Risk Management
                    </div>
                    {/* </Link> */}
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    {/* <Link to={`/digest-item/${3}`} className="digest-item"> */}
                    <img
                      src={require("../../assets/images/digest/transport.png")}
                      alt="Transport &amp; Mobility"
                      className="digest-img"
                    />
                    <div className="mb-4 digest-img-text">
                      Transport &amp; Mobility
                    </div>
                    {/* </Link> */}
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <Link to={`/digest-item/${6}`} className="digest-item">
                      <img
                        src={require("../../assets/images/digest/social-services.png")}
                        alt="Social Services"
                        className="digest-img"
                      />
                      <div className="mb-4 digest-img-text">
                        Social Services
                      </div>
                    </Link>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      {/* <Link to={`/digest-item/${9}`} className="digest-item"> */}
                      <img
                        src={require("../../assets/images/digest/local-economic-dev.png")}
                        alt="Local Economic Development"
                        className="digest-img"
                      />
                      <div className="mb-4 digest-img-text">
                        Local Economic Development
                      </div>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    {/* <Link to={`/digest-item/${12}`} className="digest-item"> */}
                    <img
                      src={require("../../assets/images/digest/energy-efficiency.png")}
                      alt="Energy Efficiency"
                      className="digest-img"
                    />
                    <div className="mb-4 digest-img-text">
                      Energy Efficiency
                    </div>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
              <div className="second-title">
                <h2 className="nalas-title">
                  Previous editions of the NALAS digest
                </h2>
              </div>
              <div className="readmore-container">
                <a
                  href={`https://us1.campaign-archive.com/home/?u=e2fcd60ad8f04210f9e8a5699&id=3dfbb38e3d`}
                  className="readmore"
                  target="blank"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Digest;
