import React, { useState, useEffect } from "react";
import axios from "../../axios";
import requests from "../../requests";
import { Helmet } from "react-helmet";
import "../../assets/css/infographics.css";
import "../../assets/css/tabs.css";
import "../../assets/css/swm.css";
import "react-tabs/style/react-tabs.css";
import SolidWasteManagementList from "./SolidWasteManagementList";
// import Logo from "../../assets/images/swm/logo.png";

const SolidWasteManagement = () => {
  // Declare State
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [charts, setCharts] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedYear, setSelectedYear] = useState(13);

  const years = [
    {
      id: 9,
      year: "2014",
    },
    {
      id: 10,
      year: "2015",
    },
    {
      id: 13,
      year: "2018",
    },
  ];

  const fireLoading = () => {
    if (loading) {
      var element = document.getElementsByClassName("loader-wrap")[0];
      element.classList.add("active");
    } else {
      var element = document.getElementsByClassName("loader-wrap")[0];
      element.classList.remove("active");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      // Get Datas
      const requestDefaultData = await axios.post(
        requests.getSolidWasteManagementData,
        {
          tab_id: 0,
          // year_id: 0,
        }
      );

      // Set State
      setTabs(requestDefaultData.data.data.tabs);
      setCharts(requestDefaultData.data.data.charts);
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleTabSelect = (tabid) => {
    async function fetchData() {
      setLoading(true);

      const data =
        tabid === 3
          ? {
              tab_id: tabid,
              year_id: 0,
            }
          : {
              tab_id: tabid,
            };

      // Get Datas
      const postGenerateChart = await axios.post(
        requests.getSolidWasteManagementData,
        data
      );

      // Set State
      setCharts(postGenerateChart.data.data.charts);
      setLoading(false);
    }
    fetchData();
  };

  const onChangeYear = (event) => {
    setSelectedYear(event.target.value);
    async function fetchData() {
      setLoading(true);

      // Get Datas
      const requestDefaultData = await axios.post(
        requests.getSolidWasteManagementData,
        {
          tab_id: selectedTab,
          year_id: parseInt(event.target.value),
        }
      );

      // Set State
      const newCharts = [...charts];
      newCharts[0] = requestDefaultData.data.data.charts[0];
      setCharts(newCharts);
      setLoading(false);
    }
    fetchData();
  };

  return (
    <>
      <Helmet>
        <title>Solid Waste Management - NALAS Observatory</title>
      </Helmet>
      <div className="ndv-cont mapbg">
        <div className="container">
          <h2 className="swm-title">
            Solid Waste Management in South-East Europe
          </h2>

          <div className="row mt-4 mb-0">
            {/* <div className="col-lg-4">
              <img src={Logo} alt="waste logo" className="wm-logo" />
            </div> */}
            <div className="col-lg-12 mb-0">
              <div className="main-tabs">
                <div className="main-tabs-titles">
                  {tabs.map((tab) => (
                    <div
                      key={tab.id}
                      tabid={tab.id}
                      onClick={() => {
                        handleTabSelect(tab.id);
                        setSelectedTab(tab.id);
                      }}
                      className={`main-tabs-title ${
                        selectedTab === tab.id ? "selected-tab" : ""
                      }`}
                    >
                      <div className="main-tabs-content">
                        <img
                          src={require(`../../assets/images/swm/${tab.id}.svg`)}
                          alt="tab-icon"
                          className="mr-2 main-tab-item main-tab-image"
                        />
                        <p className="main-tab-item">{tab.title}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {selectedTab === 3 && years.length > 1 ? (
                <div className="col-md-12 m-0 p-0">
                  <div className="nalas-select-cont">
                    <label className="d-block custom-label">Year</label>
                    <select
                      value={selectedYear}
                      className="custom-select"
                      onChange={onChangeYear}
                    >
                      {years.map((year) => (
                        <option key={year.id} value={year.id}>
                          {year.year}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="row ndv-chart-list mt-4">
            <div className="col-md-12 mt-0 pt-0">
              {loading ? fireLoading() : null}
              <SolidWasteManagementList charts={charts} loading={loading} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SolidWasteManagement;
