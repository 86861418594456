import React from "react";
import "../../assets/css/infographics.css";
import SWMClusteredColumnChart from "./SWMClusteredColumnChart";
import SWMCombinedBulletColumnLineChart from "./SWMCombinedBulletColumnLineChart";
import SWMFullstackedColumnChart from "./SWMFullstackedColumnChart";

const SolidWasteManagementList = ({ charts, loading }) => {
  if (loading) {
    var element = document.getElementsByClassName("loader-wrap")[0];
    if (element) element.classList.add("active");
  } else {
    var element = document.getElementsByClassName("loader-wrap")[0];
    if (element) element.classList.remove("active");
  }

  const getChart = (chart) => {
    switch (chart.fe_chart_id) {
      case "FE_CLUSTERED_COLUMN_CHART_1":
        return (
          <SWMClusteredColumnChart
            chartData={chart.data}
            id={chart.id}
            type={chart.type}
            title={chart.title}
          />
        );
      case "FE_FULL_STACKED_COLUMN_CHART_1":
        return (
          <SWMFullstackedColumnChart
            chartData={chart.data}
            id={chart.id}
            type={chart.type}
            title={chart.title}
          />
        );
      case "FE_COMBINED_BULLET_COLUMN_LINE_CHART_1":
        return (
          <SWMCombinedBulletColumnLineChart
            chartData={chart.data}
            id={chart.id}
            type={chart.type}
            title={chart.title}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {charts.map((chart) => {
        return (
          <div key={chart.id}>
            <div className="chartCont">{getChart(chart)} </div>
          </div>
        );
      })}
    </div>
  );
};

export default SolidWasteManagementList;
