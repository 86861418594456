import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { createBrowserHistory } from "history";

// General Assets
import "./assets/plugins/font-awesome/css/all.css";
import "./assets/css/general.css";

// Shared Sections Components
import Navbar from "./shared-components/Navbar";
import Footer from "./shared-components/Footer";

// Pages
import Home from "./pages/Home";
import Publications from "./pages/Publications";
import Citizens from "./pages/Citizens";
import UsefulLinks from "./pages/UsefulLinks";
import About from "./pages/About";
import PublicationsItem from "./shared-components/publications/PublicationsItem";
import LGFI from "./pages/LGFI/LGFI";
import LGFINationalDVList from "./pages/LGFI/NationalDVList";
import LGFINationalDataVisualization from "./pages/LGFI/NationalDataVisalization";
import LGFIRegionalDataVisualization from "./pages/LGFI/RegionalDataVisualization";
import SelfA from "./pages/SelfAssessment";
import Infographics from "./pages/Infographics/Infographics";
import TerritorialProfiles from "./pages/Infographics/TerritorialProfiles";
import DecentralizationsProgress from "./pages/Infographics/DecentralizationsProgress";
import LocalGovernmentsFinance from "./pages/Infographics/LocalGovernmentsFinance";
import WasteManagements from "./pages/Infographics/WasteManagements";
import CircularEconomy from "./pages/WasteManagement/CircularEconomy";
import Articles from "./pages/Learning/Articles";
import Digest from "./pages/Learning/Digest";
import DigestItem from "./pages/Learning/DigestItem";
import LegalFramework from "./pages/Learning/LegalFramework";
import SolidWasteManagement from "./pages/WasteManagement/SolidWasteManagement";
import DecentralizationProgress from "./pages/DecentralizationProgress/DecentralizationProgress";
import LegalFrameworkCountry from "./pages/Learning/LegalFrameworkCountry";

const history = createBrowserHistory();

function App() {
  return (
    <Router history={history}>
      <ScrollToTop>
        <Navbar />
        <div className="b-container">
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/citizens">
              <Citizens />
            </Route>
            <Route path="/articles">
              <Articles />
            </Route>
            <Route path="/digest">
              <Digest />
            </Route>
            <Route path="/digest-item/:id">
              <DigestItem />
            </Route>
            <Route path="/legal-framework/:id">
              <LegalFrameworkCountry />
            </Route>
            <Route path="/legal-framework">
              <LegalFramework />
            </Route>
            <Route exact path="/publications">
              <Publications />
            </Route>
            <Route path="/publications/:publicationsId">
              <PublicationsItem />
            </Route>
            <Route path="/data/local-government-finance-indicators">
              <LGFI />
            </Route>
            <Route path="/data/lgfi/national-data-visualization-list">
              <LGFINationalDVList />
            </Route>
            <Route path="/data/lgfi/national-data-visualization">
              <LGFINationalDataVisualization />
            </Route>
            <Route path="/data/lgfi/regional-data-visualization">
              <LGFIRegionalDataVisualization />
            </Route>
            <Route path="/data/swm-indicators/circular-economy">
              <CircularEconomy />
            </Route>
            <Route path="/data/swm-indicators/swm">
              <SolidWasteManagement />
            </Route>
            <Route path="/decentralisation-progress">
              <DecentralizationProgress />
            </Route>
            <Route path="/self-assessment">
              <SelfA />
            </Route>
            <Route path="/useful-links">
              <UsefulLinks />
            </Route>
            <Route path="/infographics/territorial-profiles">
              <TerritorialProfiles />
            </Route>
            <Route path="/infographics/decentralizations-progress">
              <DecentralizationsProgress />
            </Route>
            <Route path="/infographics/local-governments-finance">
              <LocalGovernmentsFinance />
            </Route>
            <Route path="/infographics/waste-management">
              <WasteManagements />
            </Route>
            <Route path="/infographics">
              <Infographics />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
        <Footer />
      </ScrollToTop>
    </Router>
  );
}

export default App;
