import React from "react";
import { Helmet } from "react-helmet";
import "../assets/css/about.css";

function About() {
  return (
    <>
      <Helmet>
        <title>About US - NALAS Observatory</title>
      </Helmet>
      <div className="about-page mapbg">
        <div className="container">
          <div className="row">
            <h2 className="nalas-title">About us</h2>
            <div className="about-head-bg">
              <div className="about-image-text">
                We're a team of professional people
                <br />
                who loves what they do.
              </div>
              <img
                src={require("../assets/images/about/head_bg.jpg")}
                alt="head logo"
              />
            </div>
            <p>
              The <b>NALAS Regional Decentralisation Observatory</b> is
              developed by NALAS – Network of Association of Local Authorities
              of South-East Europe, in partnership with KDZ – Centre for Public
              Administration Research and with the support of the BACID Program
              - Building Administrative Capacity in the Danube Region and
              Western Balkans, co-funded by the Austrian Development Cooperation
              and implemented by the Austrian Association of Cities and Town.
            </p>
            <p>
              The Observatory is run by the NALAS Secretariat and is supported
              by:
            </p>
            <div className="row about-plinks">
              <div className="col-lg-2 col-md-4 offset-lg-1">
                <div className="about-plinks-img">
                  <img
                    src={require("../assets/images/about/nalas.png")}
                    alt="sponsors logos"
                  />
                </div>
                <a href="http://www.nalas.eu/" target="blank">
                  Read More
                </a>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="about-plinks-img">
                  <img
                    src={require("../assets/images/about/kdz.png")}
                    alt="sponsors logos"
                  />
                </div>
                <a href="https://www.kdz.eu/en" target="blank">
                  Read More
                </a>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="about-plinks-img">
                  <img
                    src={require("../assets/images/about/bacid.png")}
                    alt="sponsors logos"
                  />
                </div>
                <a
                  href="https://www.bacid.eu/BACID_-_Building_Administrative_Capacities_in_the_Danube_Region"
                  target="blank"
                >
                  Read More
                </a>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="about-plinks-img">
                  <img
                    src={require("../assets/images/about/austrian-dev-coop.jpg")}
                    alt="sponsors logos"
                  />
                </div>
                <a href="https://www.entwicklung.at/en/" target="blank">
                  Read More
                </a>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="about-plinks-img">
                  <img
                    src={require("../assets/images/about/austrian-assoc.jpg")}
                    alt="sponsors logos"
                  />
                </div>
                <a href="https://www.staedtebund.gv.at/" target="blank">
                  Read More
                </a>
              </div>
            </div>
            <p>
              We provide local government associations, local government
              policymakers, experts, practitioners, researchers and media with
              access to timely, accurate, reliable and comparable data,
              indicators and information on local governance, presented in a
              user friendly manner.
            </p>
            <div className="about-features row">
              <h3 className="nalas-title wwd">What do we do?</h3>
              <div className="col-md-4">
                <img src={require("../assets/images/about/1.png")} />
                <p>
                  Facilitate visualisation of complex data in a user friendly
                  manner
                </p>
              </div>
              <div className="col-md-4">
                <img src={require("../assets/images/about/2.png")} />
                <p>
                  Facilitate regional comparison of key indicators of local
                  government in South-East Europe
                </p>
              </div>
              <div className="col-md-4">
                <img src={require("../assets/images/about/3.png")} />
                <p>
                  Serve as a unique knowledge hub to learn about the progress of
                  decentralisation in South-East Europe
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
