import React from "react";
import { Helmet } from "react-helmet";
import "../assets/css/selfa.css";

function SelfA() {
  return (
    <>
      <Helmet>
        <title>Self-Assessment - NALAS Observatory</title>
      </Helmet>
      <div className="selfa-page mapbg">
        <div className="container">
          <div className="row">
            <h2 className="nalas-title">
              Municipal Finances Self-Assessment (MFSA)
            </h2>
            <div>
              <div className="selfa-first">
                <div className="selfa-logo">
                  <a href="https://mfsa.nalas.eu/" target="blank">
                    <div>Access MFSA tool here</div>
                    <div>
                      <img
                        src={require("../assets/images/selfa.png")}
                        alt="self assessment"
                      />
                    </div>
                  </a>
                  <div className="mt-4">
                    <div style={{ color: "gray" }}>
                      Developed by the World Bank Urban Partnership Program
                    </div>
                    <a
                      href="http://www.seecities.eu/seecities.eu/"
                      target="blank"
                    >
                      <div>
                        <img
                          src={require("../assets/images/self/urban-partnership-program.jpg")}
                          alt="World Bank Urban Partnership Program"
                        />
                      </div>
                    </a>
                  </div>
                </div>
                <a
                  href="http://www.seecities.eu/seecities.eu/Portals/0/Images/Upload/Documents/MFSA/Intro%20to%20MFSA_final.pdf?ver=2020-09-17-160500-107"
                  target="blank"
                >
                  MFSA
                </a>
                , an assessment tool developed by the World Bank{" "}
                <a href="http://www.seecities.eu/seecities.eu/" target="blank">
                  Urban Partnership Program{" "}
                </a>
                UPP and customized for SEE with the support of international and
                local experts, is aimed at evaluating cities’ financial health
                and identifying specific actions to improve mobilization of
                local resources, financial management, public spending, public
                assets management, investment programming and access to external
                funding. It performs several functions:
                <ul>
                  <li>
                    It reviews municipal budgets (revenues and expenditures),
                    financial management practices, savings capacity, investment
                    efforts, and financial projections for the next five years.
                  </li>
                  <li>
                    It provides some benchmarking through a set of simple and
                    comparable key indicators and ratios.
                  </li>
                  <li>
                    It defines key actions to be included in a Municipal Finance
                    Improvement Plan aiming for greater accountability,
                    visibility and efficiency in the use of public funds.
                  </li>
                </ul>
                <p>
                  So far more than 90 municipalities and cities took part in the
                  Municipal Finances Self-Assessment. What have we done through
                  the UPP Program? We managed to scale up the use of diagnostic
                  tools for local governments and took advantage of the great
                  momentum to replicate the LG tools in more municipalities
                  across the region; we introduced the{" "}
                  <a href="https://mfsaonline.assyst.in/" target="blank">
                    MFSA Online Tool web-based platform
                  </a>{" "}
                  <a
                    href="http://www.seecities.eu/seecities.eu/MFSA"
                    target="blank"
                  >
                    (manual){" "}
                  </a>
                  with the sole aim to help the municipalities and automate the
                  self-assessment process, make it more user friendly and leave
                  more time for drawing lessons and analyzing results. We are
                  now facing a global challenge, with COVID19 providing a new
                  context for the MFSA work to be undertaken in the region.
                </p>
              </div>
            </div>
            <img
              className="self-vector"
              src={require("../assets/images/self-vector.png")}
              alt="self vector"
            />
            <p>
              <b>On the other hand we have MFSA</b> - the Municipal Finances
              Self-Assessment, as the right tool exactly designed to help in
              answering some of these questions. Even though the main use of the
              MFSA is to assess a city’s financial health, creditworthiness and
              to identify specific actions to improve mobilization of local
              resources, public spending, public assets management and
              maintenance, it is also the right tool, given its design, to
              assess the scale of the COVID-19 impact on LSGs and what LSGs can
              do in order to mitigate the risks on revenue collections and local
              expenditures pressures.
            </p>
            <p>
              <b>
                The MFSA was so far used to identify a range of risks that may
                threaten many LSGs in the UPP.
              </b>{" "}
              To do so, the World Bank’s Municipal Finance Self-Assessment
              (MFSA) tool was used to test alternative scenarios for LSGs in
              FY2020 and FY2021 (I believe this should be deleted as it just
              seems to repeat the previous part of the sentence). Using the MFSA
              tool, especially the General Accounting Framework (GAF),
              optimistic and pessimistic scenarios for both FY2020 and FY2021
              were analyzed and the results are presented alongside an
              appropriate recommendation.
            </p>
            <p>
              <b>MFSA Methodology</b>
            </p>
            <p>
              The MFSA analysis framework includes eight steps organized in four
              complementary modules and is continually being evolved in practice
              and validated by all stakeholders. It provides a platform for
              making a vital connection between data inputs from various
              municipal financial and technical departments and allows a
              creditworthy assessment and strategic and capital investment
              planning anchored in financial realism.
            </p>

            <div className="self-numbers row">
              <div className="col-md-3">
                <img src={require("../assets/images/self/1.png")} />
              </div>
              <div className="col-md-3">
                <img src={require("../assets/images/self/2.png")} />
              </div>
              <div className="col-md-3">
                <img src={require("../assets/images/self/3.png")} />
              </div>
              <div className="col-md-3">
                <img src={require("../assets/images/self/4.png")} />
              </div>
              <div className="col-md-3">
                <img src={require("../assets/images/self/5.png")} />
              </div>
              <div className="col-md-3">
                <img src={require("../assets/images/self/6.png")} />
              </div>
              <div className="col-md-3">
                <img src={require("../assets/images/self/7.png")} />
              </div>
              <div className="col-md-3">
                <img src={require("../assets/images/self/8.png")} />
              </div>
            </div>
            <p>
              <b>MFSA and COVID-19</b>
            </p>
            <p>
              Among other things, the COVID-19 pandemic puts under stress public
              finances at the central and local level, puts risks on the central
              and local governments’ capacity to provide for basic public
              services, and pressures people to live and work in an emergency
              environment. The most urgent questions are related to the scale of
              the COVID-19 impact on LSGs and what the LSGs can do in order to
              mitigate the risks on revenue collections and local expenditures
              pressures, while at the same time providing for the public
              services without major interruptions.
            </p>
            <p>Please check the links to summary reports:</p>
            <a
              target="_blank"
              className="self-links"
              href="http://www.seecities.eu/Portals/0/Images/Upload/Documents/MFSA/Covid-19%20financial%20impact%20Kisela%20Voda-1.pdf?ver=2020-09-17-160357-890"
            >
              COVID-19 financial impact - Kisela Voda
            </a>
            <a
              target="_blank"
              className="self-links"
              href="http://www.seecities.eu/Portals/0/Images/Upload/Documents/MFSA/Power%20point%20COVID%2019%20Impact_Local%20Economy_MGB%20Budget_26%2006%202020.pdf?ver=2020-09-17-160504-613"
            >
              COVID-19 impact local economy - MGB budget
            </a>
            <a
              target="_blank"
              className="self-links"
              href="http://www.seecities.eu/Portals/0/Images/Upload/Documents/MFSA/Covid-19%20MFSA%20impact%20Kraljevo.pdf?ver=2020-11-17-062536-150"
            >
              Covid-19 MFSA impact Kraljevo
            </a>
            <a
              target="_blank"
              className="self-links"
              href="http://www.seecities.eu/Portals/0/Images/Upload/Documents/Workshops/Webinar%20%20COVID-19%C2%A0Pandemic%C2%A0Risk%20and%20Potential%20Impact%20on%20LSG%20Finances/Presentation%20TUZLA%20MFSA%20Covid-19.pdf?ver=2020-11-04-153320-550"
            >
              Tuzla MFSA COVID-19
            </a>
            <a
              target="blank"
              className="self-links"
              href="http://www.seecities.eu/Portals/0/Images/Upload/Documents/MFSA/Municipality%20of%20Shkoder_ppt%20Potential%20impact%20of%20Covid19.pdf?ver=2020-12-06-133013-433"
            >
              Municipality of Shkoder - Potential impact of Covid19
            </a>
            <h3 className="partner-title">Partners</h3>
            <div className="row pimg">
              <div className="col-md-4">
                <img
                  className="align-center"
                  src={require("../assets/images/about/world.png")}
                  alt="partner"
                />
              </div>
              <div className="col-md-4">
                <img
                  className="align-center"
                  src={require("../assets/images/about/fmra.png")}
                  alt="partner"
                />
              </div>
              <div className="col-md-4">
                <img
                  className="align-center"
                  src={require("../assets/images/about/nalas.png")}
                  alt="partner"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelfA;
