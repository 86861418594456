import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import "../../assets/css/charts.css";

const DonutChart = ({ id, type, title, chartData }) => {
  const newData = chartData.map((x) => {
    if (x.as_part_of_chart == 1) {
      return {
        category: x.name,
        percentage: x.dataset[0].value,
        partOfChart: x.as_part_of_chart,
        unit: x.unit.name_readable,
      };
    }
    return null;
  });

  const insideLabels = chartData.map((x) => {
    if (x.as_part_of_chart == 0) {
      return x.name + " €" + x.dataset[0].value + " " + x.unit.name_readable;
    }
    return null;
  });

  const chart = useRef(null);
  useLayoutEffect(() => {
    let chart = am4core.create(`${type}${id}`, am4charts.PieChart);
    chart.data = newData.filter((el) => el !== null);

    // Set inner radius
    chart.radius = am4core.percent(80);
    chart.innerRadius = am4core.percent(50);

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "percentage";
    pieSeries.dataFields.category = "category";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template.tooltipText = "{category}: {value}{unit}";

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    pieSeries.ticks.template.disabled = true;
    pieSeries.labels.template.disabled = true;

    pieSeries.alignLabels = false;
    pieSeries.labels.template.fontSize = 12;
    pieSeries.labels.template.wrap = true;
    pieSeries.labels.template.bent = true;
    pieSeries.labels.template.margin(0, 0, 0, 0);

    var container = new am4core.Container();
    container.parent = pieSeries;
    container.horizontalCenter = "middle";
    container.verticalCenter = "middle";
    container.width = am4core.percent(40) / Math.sqrt(2);
    container.fill = "white";

    var label = new am4core.Label();
    label.parent = container;
    label.text = insideLabels[0] + " \n" + insideLabels[1];
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 12;
    label.fullWords = true;
    label.wrap = true;
    label.maxWidth = 170;

    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";
    chart.legend.maxHeight = 150;
    chart.legend.scrollable = true;
    chart.legend.labels.template.fontSize = 12;
    chart.legend.labels.template.text = "{category}: {value}{unit}";
    chart.legend.valueLabels.template.disabled = true;

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = title.replace(/\s/g, "");

    const exportFields = { ...chart.exporting.dataFields };
    delete exportFields.color;
    delete exportFields.unit_as_part_of_label;
    delete exportFields.partOfChart;
    chart.exporting.dataFields = exportFields;

    return () => {
      chart.dispose();
    };
  }, [id, type, title, chartData]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <h5 className="header-with-after-line-2">{title}</h5>
        </div>
      </div>
      <div id={type + id} style={{ width: "100%", height: "500px" }}></div>
    </>
  );
};
export default DonutChart;
