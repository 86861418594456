import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../assets/css/learning.css";
import axios from "../../axios";
import requests from "../../requests";
import { withRouter } from "react-router-dom";
import { digestItemContentById } from "./DigestItemContents";
import "../../assets/css/digest.css";

const data = [
  {
    category: "Lorem Ipsum",
    items: [
      {
        date: "01/27/2021",
        text: "NALAS Digest 27 January 2021",
      },
      {
        date: "01/27/2021",
        text: "NALAS Digest 27 January 2021",
      },
      {
        date: "01/27/2021",
        text: "NALAS Digest 27 January 2021",
      },
    ],
  },
  {
    category: "Lorem Ipsum",
    items: [
      {
        date: "01/27/2021",
        text: "NALAS Digest 27 January 2021",
      },
      {
        date: "01/27/2021",
        text: "NALAS Digest 27 January 2021",
      },
      {
        date: "01/27/2021",
        text: "NALAS Digest 27 January 2021",
      },
    ],
  },
  {
    category: "Lorem Ipsum",
    items: [
      {
        date: "01/27/2021",
        text: "NALAS Digest 27 January 2021",
      },
      {
        date: "01/27/2021",
        text: "NALAS Digest 27 January 2021",
      },
      {
        date: "01/27/2021",
        text: "NALAS Digest 27 January 2021",
      },
    ],
  },
  {
    category: "Lorem Ipsum",
    items: [
      {
        date: "01/27/2021",
        text: "NALAS Digest 27 January 2021",
      },
      {
        date: "01/27/2021",
        text: "NALAS Digest 27 January 2021",
      },
      {
        date: "01/27/2021",
        text: "NALAS Digest 27 January 2021",
      },
    ],
  },
  {
    category: "Lorem Ipsum",
    items: [
      {
        date: "01/27/2021",
        text: "NALAS Digest 27 January 2021",
      },
      {
        date: "01/27/2021",
        text: "NALAS Digest 27 January 2021",
      },
      {
        date: "01/27/2021",
        text: "NALAS Digest 27 January 2021",
      },
    ],
  },
  {
    category: "Lorem Ipsum",
    items: [
      {
        date: "01/27/2021",
        text: "NALAS Digest 27 January 2021",
      },
      {
        date: "01/27/2021",
        text: "NALAS Digest 27 January 2021",
      },
      {
        date: "01/27/2021",
        text: "NALAS Digest 27 January 2021",
      },
    ],
  },
];

const names = [
  "",
  "",
  "",
  "",
  "",
  "Education",
  "Social Services",
  "Public Health",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
];

const DigestItem = ({
  match: {
    params: { id },
  },
}) => {
  useEffect(() => {
    const fetchData = async () => {
      await axios.get(`${requests.getDigestItemData}?category=${id}`);
    };
    fetchData();
  }, []);

  const digestItem = digestItemContentById[id] || {};

  return (
    <>
      <Helmet>
        <title>Digest - NALAS Observatory</title>
      </Helmet>
      <div className="section mapbg">
        <div className="container">
          <div className="row">
            <div className="digest-title">
              <h2 className="nalas-title">{names[id]}</h2>
              <div className="by-nalas">by NALAS</div>
            </div>
          </div>
          <div className="row digest-content">
            <div className="margin-bottom-10">{digestItem.opening}</div>
            <div>
              {digestItem.paragraphs &&
                digestItem.paragraphs.map((el) => {
                  return (
                    <div>
                      <div className="margin-bottom-10">
                        {el.contents[0] && el.contents[0].image && (
                          <img
                            className={el.contents[0].float}
                            style={{ height: "250px" }}
                            src={el.contents[0].image}
                            alt="digest item img"
                          />
                        )}
                        <div className="margin-bottom-10">
                          {el.title} {el.contents[0].text}
                        </div>
                      </div>
                      <div>
                        {el.contents.map((el, index) => {
                          return index !== 0 ? (
                            <div className="margin-bottom-10">
                              {el.image && (
                                <img
                                  className={el.float}
                                  style={{ height: "250px" }}
                                  src={el.image}
                                  alt="digest item img"
                                />
                              )}
                              <div>{el.text} </div>
                            </div>
                          ) : null;
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {/* <div className="row digest-item-category">
            {data.map((el) => (
              <div className="col-sm-6">
                <p>{el.category}</p>
                {el.items.map((item) => (
                  <div>
                    {item.date} - {item.text}
                  </div>
                ))}
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default withRouter(DigestItem);
