import React from "react";
import "../../assets/css/infographics.css";
import SWChart from "./SWChart";

const SWChartsList = ({ charts, loading }) => {
  if (loading) {
    var element = document.getElementsByClassName("loader-wrap")[0];
    if (element) element.classList.add("active");
  } else {
    var element = document.getElementsByClassName("loader-wrap")[0];
    if (element) element.classList.remove("active");
  }

  return (
    <div>
      {charts.map((chart) => {
        return (
          <span key={chart.id}>
            <SWChart chartData={chart} />
          </span>
        );
      })}
    </div>
  );
};

export default SWChartsList;
