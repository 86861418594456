import React, { useState, useEffect } from "react";
import axios from "../../axios";
import requests from "../../requests";
import { Helmet } from "react-helmet";
import "../../assets/css/infographics.css";
import "../../assets/css/general.css";
import "../../assets/css/tabs.css";
import "../../assets/css/rdo.css";
import "react-tabs/style/react-tabs.css";
import DecentralizationProgressList from "./DecentralizationProgressList";
import keyBy from "lodash.keyby";

const DecentralizationProgress = () => {
  // Declare State
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [tabsById, setTabsById] = useState([]);
  const [subTabs, setSubTabs] = useState([]);
  const [charts, setCharts] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);

  const fireLoading = () => {
    if (loading) {
      var element = document.getElementsByClassName("loader-wrap")[0];
      element.classList.add("active");
    } else {
      var element = document.getElementsByClassName("loader-wrap")[0];
      element.classList.remove("active");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      // Get Datas
      const requestDefaultData = await axios.post(requests.getRDOData, {
        tab_id: 0,
      });

      // Set State
      setTabs(requestDefaultData.data.data.tabs);
      setCharts(requestDefaultData.data.data.charts);
      setTabsById(keyBy(requestDefaultData.data.data.tabs, (el) => el.id));
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleTabSelect = (tabid) => {
    async function fetchData() {
      setLoading(true);

      const data = {
        tab_id: tabid,
      };

      // Get Datas
      const postGenerateChart = await axios.post(requests.getRDOData, data);

      // Set State
      setCharts(postGenerateChart.data.data.charts);
      setLoading(false);
      if (tabsById[tabid]) {
        setSubTabs(tabsById[tabid].sub_tabs);
      }
    }
    fetchData();
  };

  return (
    <>
      <Helmet>
        <title>Decentralization Progress - NALAS Observatory</title>
      </Helmet>
      <div className="ndv-cont mapbg">
        <div className="container">
          <h2 className="rdo-title">Decentralization Progress</h2>
        </div>
        <div className="container-fluid mb-4">
          <div className="row">
            <div className="main-tabs">
              <div className="main-tabs-titles">
                {tabs &&
                  tabs.map((tab) => (
                    <div
                      key={tab.id}
                      tabid={tab.id}
                      onClick={() => {
                        handleTabSelect(tab.id);
                        setSelectedTab(tab.id);
                      }}
                      className={`main-tabs-title ${
                        selectedTab === tab.id ? "selected-tab" : ""
                      }`}
                    >
                      <div className="main-tabs-content">
                        <img
                          src={require(`../../assets/images/rdo/${tab.id}.png`)}
                          alt="tab-icon"
                          className="mr-2 main-tab-item main-tab-image"
                        />
                        <p className="main-tab-item">{tab.title}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="react-tabs-updated">
              <div className="rdo-sub-titles">
                {subTabs &&
                  subTabs.map((tab) => (
                    <div
                      key={tab.id}
                      tabid={tab.id}
                      onClick={() => {
                        handleTabSelect(tab.id);
                        setSelectedTab(tab.id);
                      }}
                      className={`rdo-sub-title ${
                        selectedTab === tab.id ? "selected-tab" : ""
                      }`}
                    >
                      {tab.title}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row ndv-chart-list">
            <div className="col-md-12 mt-4 pt-0">
              {loading ? fireLoading() : null}
              <DecentralizationProgressList charts={charts} loading={loading} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DecentralizationProgress;
