import React, { useLayoutEffect } from "react";
import "../../assets/css/infographics.css";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "../../assets/css/swm.css";
import "../../assets/css/charts.css";

am4core.useTheme(am4themes_animated);

const SWMCombinedBulletColumnLineChart = ({ chartData, id, type, title }) => {
  useLayoutEffect(() => {
    // Create chart instance
    let chart = am4core.create(`${type}${id}`, am4charts.XYChart);
    chart.data = chartData.dataset_composed.dataset;
    const labels = chartData.dataset_composed.properties;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.tooltip = false;
    categoryAxis.renderer.cellStartLocation = 0.2;
    categoryAxis.renderer.cellEndLocation = 0.8;
    categoryAxis.fontSize = 14;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 290;
    categoryAxis.renderer.labels.template.wrap = true;
    categoryAxis.renderer.labels.template.maxWidth = 120;
    // categoryAxis.start = 0.5;
    // categoryAxis.keepSelection = true;

    // M2
    // var label = categoryAxis.renderer.labels.template;
    // label.wrap = true;
    // label.maxWidth = 120;

    let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());

    // let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis2.title.text = "Average grade";
    // valueAxis2.renderer.opposite = true;
    // valueAxis2.renderer.grid.template.disabled = true;

    chart.scrollbarX = new am4charts.XYChartScrollbar();

    let serie;
    Object.keys(labels).forEach((label) => {
      serie = chart.series.push(new am4charts.ColumnSeries());
      serie.dataFields.valueY = label;
      serie.dataFields.categoryX = "category";
      serie.yAxis = valueAxis1;
      serie.name = labels[label];
      serie.tooltipText = "{name}:[bold font-size: 20]{valueY}";
      serie.strokeWidth = 0;
      serie.columns.template.width = am4core.percent(100);

      //   Add scrollbar
      chart.scrollbarX.series.push(serie);
      chart.scrollbarX.parent = chart.bottomAxesContainer;
    });

    //   Add avarage with bullets
    // let series14 = chart.series.push(new am4charts.LineSeries());
    // series14.dataFields.valueY = "average-grade";
    // series14.dataFields.categoryX = "category";
    // series14.name = "Average grade";
    // series14.strokeWidth = 2;
    // series14.tensionX = 0.2;
    // series14.yAxis = valueAxis2;
    // series14.tooltipText =
    //   "{categoryX} average:[bold font-size: 20]{valueY}[/]";
    // let bullet3 = series14.bullets.push(new am4charts.CircleBullet());
    // bullet3.circle.radius = 3;
    // bullet3.circle.strokeWidth = 2;
    // bullet3.circle.fill = am4core.color("#fff");

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = title.replace(/\s/g, "");

    return () => {
      chart.dispose();
    };
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <h5 className="header-with-after-line">{title}</h5>
        </div>
      </div>
      <div id={type + id} className="swm-graph"></div>
    </>
  );
};

export default SWMCombinedBulletColumnLineChart;
