import React from 'react';

function Citizens() {
  return (
    <div className="section">
      <div className="container">
        <div className="row">
          <h2>Citizens</h2>
        </div>
      </div>
    </div>
  );
}

export default Citizens;
