import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../assets/css/learning.css";

const data = [
  {
    name: "Name1",
    image: "small.png",
  },
  {
    name: "Name2",
    image: "large.png",
  },
  {
    name: "Name3",
    image: "small.png",
  },
  {
    name: "Name4",
    image: "large.png",
  },
  {
    name: "Name5",
    image: "large.png",
  },
  {
    name: "Name6",
    image: "small.png",
  },
  {
    name: "Name7",
    image: "large.png",
  },
  {
    name: "Name8",
    image: "small.png",
  },
  {
    name: "Name9",
    image: "small.png",
  },
  {
    name: "Name10",
    image: "large.png",
  },
  {
    name: "Name11",
    image: "small.png",
  },
  {
    name: "Name12",
    image: "large.png",
  },
];

const Articles = () => {
  return (
    <>
      <Helmet>
        <title>Articles - NALAS Observatory</title>
      </Helmet>
      <div className="section mapbg">
        <div className="container">
          <div className="row">
            <div className="digest-title">
              <h2 className="nalas-title">Articles</h2>
            </div>
          </div>

          <div className="row margin-bottom-170">
            <div id="columns">
              {data.map((el) => {
                return (
                  <div>
                    <figure>
                      <img
                        src={require(`../../assets/images/articles/${el.image}`)}
                        alt={el.name}
                      />
                      <figcaption>{el.name}</figcaption>
                    </figure>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Articles;
