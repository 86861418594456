import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import "../../../assets/css/charts.css";

const ZoomableBubbleChart = ({ id, type, title, chartData }) => {
  const chart = useRef(null);

  useLayoutEffect(() => {
    let chart = am4core.create(`${type}${id}`, am4charts.XYChart);
    chart.data = chartData.dataset_composed.dataset;

    let valueAxisX = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxisX.renderer.ticks.template.disabled = true;
    valueAxisX.renderer.axisFills.template.disabled = true;

    let valueAxisY = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxisY.renderer.ticks.template.disabled = true;
    valueAxisY.renderer.axisFills.template.disabled = true;

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueX = "value";
    series.dataFields.valueY = "value";
    series.dataFields.value = "value";
    series.strokeOpacity = 0;
    series.sequencedInterpolation = true;
    series.tooltip.pointerOrientation = "vertical";

    let bullet = series.bullets.push(new am4core.Circle());
    bullet.fill = am4core.color("#ff0000");
    bullet.propertyFields.fill = "color";
    bullet.strokeOpacity = 0;
    bullet.strokeWidth = 2;
    bullet.fillOpacity = 0.5;
    bullet.stroke = am4core.color("#ffffff");
    bullet.hiddenState.properties.opacity = 0;
    bullet.tooltipText = "[bold]{country}:[/]\nProperty Tax: {value.value}";

    let outline = chart.plotContainer.createChild(am4core.Circle);
    outline.fillOpacity = 0;
    outline.strokeOpacity = 0.8;
    outline.stroke = am4core.color("#ff0000");
    outline.strokeWidth = 2;
    outline.hide(0);

    let blurFilter = new am4core.BlurFilter();
    outline.filters.push(blurFilter);

    bullet.events.on("over", function (event) {
      let target = event.target;
      outline.radius = target.pixelRadius + 2;
      outline.x = target.pixelX;
      outline.y = target.pixelY;
      outline.show();
    });

    bullet.events.on("out", function (event) {
      outline.hide();
    });

    let hoverState = bullet.states.create("hover");
    hoverState.properties.fillOpacity = 1;
    hoverState.properties.strokeOpacity = 1;

    series.heatRules.push({
      target: bullet,
      min: 2,
      max: 60,
      property: "radius",
    });

    bullet.adapter.add("tooltipY", function (tooltipY, target) {
      return -target.radius;
    });

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomXY";
    chart.cursor.snapToSeries = series;

    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarY = new am4core.Scrollbar();
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = title.replace(/\s/g, "");

    const exportFields = { ...chart.exporting.dataFields };
    delete exportFields.color;
    delete exportFields.partOfChart;
    delete exportFields.unit_as_part_of_label;
    chart.exporting.dataFields = exportFields;

    return () => {
      chart.dispose();
    };
  }, [id, type, title, chartData]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <h5 className="header-with-after-line">{title}</h5>
        </div>
      </div>
      <div id={type + id} style={{ width: "100%", height: "500px" }}></div>
    </>
  );
};
export default ZoomableBubbleChart;
