import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import "../../assets/css/charts.css";

const FullColumnStackedChart = ({ id, type, title, chartData }) => {
  const chart = useRef(null);
  const seriesData = chartData.dataset_composed.properties;

  useLayoutEffect(() => {
    let chart = am4core.create(`${type}${id}`, am4charts.XYChart);
    chart.data = chartData.dataset_composed.dataset;

    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.fontSize = 12;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.start = 0.5;
    categoryAxis.keepSelection = true;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.minWidth = 50;
    valueAxis.fontSize = 12;

    function createSeries(field, name) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(80);
      series.columns.template.tooltipText = "{name}: {valueY}{unit}";
      series.name = name;
      series.dataFields.categoryX = "year";
      series.dataFields.valueY = field;
      series.dataFields.valueYShow = "totalPercent";
      series.dataItems.template.locations.categoryX = 0.5;
      series.stacked = true;
      series.tooltip.pointerOrientation = "vertical";

      var bullet1 = series.bullets.push(new am4charts.LabelBullet());
      bullet1.interactionsEnabled = false;
      const unitCheck =
        chartData.dataset_composed.dataset[0].unit_as_part_of_label;
      unitCheck === 1
        ? (bullet1.label.text = "{valueY}{unit}")
        : (bullet1.label.text = "{valueY}");
      bullet1.label.fill = am4core.color("#ffffff");
      bullet1.label.fontSize = 12;
      bullet1.locationY = 0.5;

      return series;
    }

    Object.entries(seriesData).forEach((field) => {
      createSeries(field[0], field[1]);
    });

    chart.legend = new am4charts.Legend();
    chart.legend.maxHeight = 150;
    chart.legend.scrollable = true;
    chart.legend.labels.template.fontSize = 12;

    chart.scrollbarX = new am4core.Scrollbar();

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = title.replace(/\s/g, "");

    const exportFields = { ...chart.exporting.dataFields };
    delete exportFields.color;
    delete exportFields.unit_as_part_of_label;
    delete exportFields.partOfChart;
    chart.exporting.dataFields = exportFields;

    return () => {
      chart.dispose();
    };
  }, [id, type, title, chartData]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <h5 className="header-with-after-line-2">{title}</h5>
        </div>
      </div>
      <div id={type + id} style={{ width: "100%", height: "500px" }}></div>
    </>
  );
};
export default FullColumnStackedChart;
