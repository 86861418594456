import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import "../../assets/css/charts.css";

const BigColumnsChart = ({ id, type, title, chartData }) => {
  const chart = useRef(null);
  const newData = chartData.map((x) => ({
    name: x.name,
    points: x.dataset[0].value,
    color: x.color,
    unit: x.unit.name_readable,
  }));

  useLayoutEffect(() => {
    let chart = am4core.create(`${type}${id}`, am4charts.XYChart);
    chart.data = newData;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.fontSize = 12;

    var label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 120;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.strokeDasharray = "4,4";
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.fontSize = 12;

    // Remove padding
    chart.paddingBottom = 0;

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "points";
    series.dataFields.categoryX = "name";
    series.columns.template.propertyFields.fill = "color";
    series.columns.template.propertyFields.stroke = "color";
    series.columns.template.column.cornerRadiusTopLeft = 15;
    series.columns.template.column.cornerRadiusTopRight = 15;
    series.columns.template.tooltipText =
      "{categoryX}: [bold]{valueY}{unit}[/b]";

    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.dy = -15;
    labelBullet.label.fontSize = 18;
    const unitCheck = chartData[0].unit_as_part_of_label;
    unitCheck === 1
      ? (labelBullet.label.text =
          "{values.valueY.workingValue.formatNumber('#.##')}{unit}")
      : (labelBullet.label.text =
          "{values.valueY.workingValue.formatNumber('#.##')}");

    labelBullet.label.truncate = false;

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = title.replace(/\s/g, "");

    const exportFields = { ...chart.exporting.dataFields };
    delete exportFields.color;
    delete exportFields.partOfChart;
    delete exportFields.unit_as_part_of_label;
    chart.exporting.dataFields = exportFields;

    return () => {
      chart.dispose();
    };
  }, [id, type, title, chartData]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <h5 className="header-with-after-line-2">{title}</h5>
        </div>
      </div>
      <div id={type + id} style={{ width: "100%", height: "500px" }}></div>
    </>
  );
};
export default BigColumnsChart;
