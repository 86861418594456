import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { Helmet } from "react-helmet";
import requests from "../../requests";
import "../../assets/css/learning.css";
import { useParams } from "react-router";

const countriesById = {
  1: {
    name: "Albania",
  },
  7: {
    name: "Kososvo",
  },
  8: {
    name: "Macedonia",
  },
  12: {
    name: "Serbia",
  },
  2: {
    name: "Bosnia and Herzegovina",
  },
  6: {
    name: "Croatia",
  },
  13: {
    name: "Slovenia",
  },
  11: {
    name: "Romania",
  },
  9: {
    name: "moldavia",
  },
  5: {
    name: "bulgaria",
  },
  14: {
    name: "turkey",
  },
  10: {
    name: "montenegro",
  },
};

const LegalFrameworkCountry = () => {
  const [data, setData] = useState([]);
  let { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${requests.getLegalFrameworks}/country?country=${id}`
      );
      setData(response.data.data);
      console.log(response.data.data);
    };
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Legislation - NALAS Observatory</title>
      </Helmet>
      <div className="section mapbg">
        <div className="container">
          <div className="row">
            <div className="digest-title">
              <h2 className="nalas-title">Legislation</h2>
            </div>
          </div>

          <div className="margin-bottom-170" style={{ marginLeft: "-12px" }}>
            <div>
              <h2>{countriesById[id].name}</h2>
            </div>
            <br />
            <div>
              {data.map((el) => (
                <div>
                  <a href={el.pdf_path} target="blank">
                    {/* <img
                    src={require(`../../assets/images/legal/${el.id}.svg`)}
                    alt="legal-icon"
                    className="mr-2"
                  /> */}
                    <span>{el.title}</span>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LegalFrameworkCountry;
