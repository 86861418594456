import React from "react";
import { Helmet } from "react-helmet";
import "../../assets/css/infographics.css";

const TerritorialProfiles = () => {
  return (
    <>
      <Helmet>
        <title>Territorial Profiles - NALAS Observatory</title>
      </Helmet>
      <div className="mapbg">
        <div className="single-page">
          <img
            src={require("../../assets/images/infographics/1.svg")}
            alt="Territorial Profiles"
          />
        </div>
      </div>
    </>
  );
};

export default TerritorialProfiles;
