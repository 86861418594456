import React from "react";
import { Helmet } from "react-helmet";
import NationalMap from "./NationalMap";
import "../../assets/css/NDVList.css";

const NationalDVList = () => {
  return (
    <>
      <Helmet>
        <title>National Data Visualization - NALAS Observatory</title>
      </Helmet>
      <div className="section mapbg">
        <div className="container">
          <div className="row">
            <h2 className="nalas-title">National Data Visualizations</h2>
          </div>
        </div>
        <div className="mapscont">
            <NationalMap />
          </div>
      </div>
    </>
  );
};

export default NationalDVList;
