import React, { useRef, useLayoutEffect, useState } from "react";
import axios from "../../axios";
import requests from "../../requests";
import { useLocation, useHistory } from "react-router-dom";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CSCWithTabs = ({ id, type, title, chartData, filters }) => {
  let query = useQuery();

  const [chData, setChData] = useState(chartData.dataset_composed.dataset);
  const [checkUnit, setUnitCheck] = useState(
    chartData.dataset_composed.dataset[0].unit_as_part_of_label
  );
  const chart = useRef(null);
  const [seriesData, setSeriesData] = useState(
    chartData.dataset_composed.properties
  );
  const [unitName, setUnitName] = useState(filters[0].unit_name);

  //
  async function fetchUnitData(unit_id) {
    // Get Datas
    const postGenerateChart = await axios.post(requests.generateUnitChart, {
      country_id: parseInt(query.get("country")),
      unit_id: unit_id,
      chart_id: id,
    });
    // Set State
    setChData(postGenerateChart.data.data.dataset_composed.dataset);
    setSeriesData(postGenerateChart.data.data.dataset_composed.properties);
    setUnitCheck(
      postGenerateChart.data.data.dataset_composed.dataset[0]
        .unit_as_part_of_label
    );
  }

  async function getUnitName(unit_name) {
    setUnitName(unit_name);
  }
  useLayoutEffect(() => {
    let chart = am4core.create(`${type}${id}`, am4charts.XYChart);

    chart.data = chData;
    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.fontSize = 12;
    categoryAxis.start = 0.29;
    categoryAxis.keepSelection = true;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.fontSize = 12;

    // Create series
    function createSeries(field, name) {
      // Set up series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "year";
      series.sequencedInterpolation = true;

      // Make it stacked
      series.stacked = true;

      // Configure columns
      series.columns.template.tooltipText =
        "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY} {unit}";

      // Add label
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      checkUnit === 1
        ? (labelBullet.label.text = "{valueY}{unit}")
        : (labelBullet.label.text = "{valueY}");
      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = false;
      labelBullet.label.fontSize = 12;

      return series;
    }

    Object.entries(seriesData).forEach((field) => {
      createSeries(field[0], field[1]);
    });

    chart.legend = new am4charts.Legend();
    chart.legend.maxHeight = 150;
    chart.legend.scrollable = true;
    chart.legend.labels.template.fontSize = 10;
    chart.marginTop = 20;
    chart.scrollbarX = new am4core.Scrollbar();
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = title.replace(/\s/g, "");

    const exportFields = { ...chart.exporting.dataFields };
    delete exportFields.color;
    delete exportFields.unit_as_part_of_label;
    delete exportFields.partOfChart;
    chart.exporting.dataFields = exportFields;

    return () => {
      chart.dispose();
    };
  }, [chData, seriesData, checkUnit]);

  return (
    <>
      <h5 className="chart-title">
        {title} {unitName}
      </h5>
      {filters.map((filter) => (
        <div
          className="unit-filter"
          key={filter.unit_id}
          onClick={() => {
            fetchUnitData(filter.unit_id);
            getUnitName(filter.unit_name);
          }}
        >
          {filter.unit_name}
        </div>
      ))}
      <div id={type + id} style={{ width: "100%", height: "500px" }}></div>
    </>
  );
};
export default CSCWithTabs;
