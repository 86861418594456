import React, { useEffect } from "react";
import monitor from "../assets/images/monitor.png";
import lgf from "../assets/images/lgf.png";
import waste from "../assets/images/waste.png";
import "aos/dist/aos.css";
import Aos from "aos";
import { Link } from "react-router-dom";
import "../assets/css/features.css";
import "../assets/css/slider.css";

function HomeVideo() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="main-slider" style={{ position: "relative" }}>
      <div
        dangerouslySetInnerHTML={{
          __html: `
        <video
          width="100%"
          loop
          muted
          id="vid"
          autoplay
          playsinline
          src="${require("../assets/videos/SlidesVideoNALAS.mp4")}"
        />,
      `,
        }}
      ></div>
      {/* <video
        width="100%"
        loop="true"
        autoplay="autoplay"
        id="vid"
        muted
        playsinline
      >
        <source
          src={require("../assets/videos/SlidesVideoNALAS.mp4")}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video> */}
      <div className="visualize">
        Visualise data and access information on local governance in South-East
        Europe
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "11%",
          width: "100%",
          color: "white",
        }}
      >
        <div
          className="container-fluid"
          style={{ width: "80%", marginRight: "auto", marginLeft: "auto" }}
        >
          <div className="row">
            <div className="col-4">
              <Link
                to="/decentralisation-progress"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className={`feature-item feature-item1`}>
                  <img
                    className="feature-image"
                    src={monitor}
                    alt="Monitor Decentralisation"
                  />
                  <p className="feature-p" style={{ marginTop: "23px" }}>
                    Monitor Decentralisation
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-4">
              <Link
                to="/data/local-government-finance-indicators"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="feature-item feature-item2">
                  <img
                    className="feature-image"
                    src={lgf}
                    alt="Local Government Finance"
                  />
                  <p className="feature-p" style={{ marginTop: "12px" }}>
                    Local Government Finance
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-4">
              <Link
                to="/data/swm-indicators/swm"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="feature-item feature-item3">
                  <img
                    className="feature-image"
                    src={waste}
                    alt="Waste Management Indicators"
                  />
                  <p className="feature-p" style={{ marginTop: "10px" }}>
                    Waste Management Indicators
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeVideo;
