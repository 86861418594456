import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import "../../../assets/css/charts.css";
am4core.options.autoDispose = true;

const RegionalRealTimeChart = ({ id, type, title, chartData }) => {
  const chart = useRef(null);
  const seriesData = chartData.dataset_composed.properties;
  useLayoutEffect(() => {
    let chart = am4core.create(`${type}${id}`, am4charts.XYChart);
    chart.data = chartData.dataset_composed.dataset;
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "country";
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.fontSize = 12;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 270;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.fontSize = 12;
    valueAxis.extraMax = 0.1;

    function createSeries(field, name) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = "country";
      series.dataFields.valueY = field;
      series.tooltipText = "{valueY.value}";
      series.columns.template.strokeOpacity = 0;
      series.columns.template.column.cornerRadiusTopRight = 10;
      series.columns.template.column.cornerRadiusTopLeft = 10;
      series.columns.template.tooltipText =
        "{values.valueY.workingValue}{unit}";
      series.columns.template.showTooltipOn = "hit";

      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.dy = -30;
      labelBullet.label.fontSize = 12;
      const unitCheck =
        chartData.dataset_composed.dataset[0].unit_as_part_of_label;
      unitCheck === 1
        ? (labelBullet.label.text = "{values.valueY.workingValue}{unit}")
        : (labelBullet.label.text = "{values.valueY.workingValue}");
      labelBullet.label.rotation = -90;
      labelBullet.label.truncate = false;
      chart.zoomOutButton.disabled = true;

      // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
      series.columns.template.adapter.add("fill", function (fill, target) {
        return chart.colors.getIndex(target.dataItem.index);
      });

      return series;
    }

    Object.entries(seriesData).forEach((field) => {
      createSeries(field[0], field[1]);
    });

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = title.replace(/\s/g, "");
    chart.layout = "horizontal";

    const exportFields = { ...chart.exporting.dataFields };
    delete exportFields.color;
    delete exportFields.partOfChart;
    delete exportFields.unit_as_part_of_label;
    chart.exporting.dataFields = exportFields;

    return () => {
      chart.dispose();
    };
  }, [id, type, title, chartData]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <h5 className="header-with-after-line">{title}</h5>
        </div>
      </div>
      <div id={type + id} style={{ width: "100%", height: "500px" }}></div>
    </>
  );
};
export default RegionalRealTimeChart;
