import React, { useState, useEffect } from "react";
import axios from "../../axios";
import requests from "../../requests";
import { Helmet } from "react-helmet";
import "../../assets/css/infographics.css";
import { useLocation, useHistory } from "react-router-dom";
import SWChartsList from "./SWChartsList";
import "../../assets/css/tabs.css";
import "../../assets/css/rdo.css";
import "../../assets/css/swm.css";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CircularEconomy = () => {
  // const history = useHistory();
  // let query = useQuery();
  // const iconFolder = "../../assets/images/ndv-icons/";
  // Declare State
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [charts, setCharts] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  // const [tabIndex, setTabIndex] = useState(0);

  const fireLoading = () => {
    if (loading) {
      var element = document.getElementsByClassName("loader-wrap")[0];
      element.classList.add("active");
    } else {
      var element = document.getElementsByClassName("loader-wrap")[0];
      element.classList.remove("active");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      // Get Datas
      const requestDefaultData = await axios.post(
        requests.getCircularEconomyData,
        {
          country_id: 0,
          tab_id: 0,
        }
      );

      // Set State
      setCountries(requestDefaultData.data.data.countries);
      setTabs(requestDefaultData.data.data.tabs);
      setCharts(requestDefaultData.data.data.charts);
      setLoading(false);
    };
    fetchData();
  }, []);

  const onChangeCountry = (event) => {
    setSelectedCountry(event.target.value);
    async function fetchData() {
      setLoading(true);

      const postGenerateChart = await axios.post(
        requests.getCircularEconomyData,
        {
          country_id: parseInt(event.target.value),
          tab_id: selectedTab,
        }
      );

      setCharts(postGenerateChart.data.data.charts);
      setLoading(false);
    }
    fetchData();
  };

  const handleTabSelect = (tabid) => {
    async function fetchData() {
      setLoading(true);

      // Get Datas
      const postGenerateChart = await axios.post(
        requests.getCircularEconomyData,
        {
          country_id: selectedCountry,
          tab_id: tabid,
        }
      );

      // Set State
      setLoading(false);
      setCharts(postGenerateChart.data.data.charts);
    }
    fetchData();
  };

  return (
    <>
      <Helmet>
        <title>Circular Economy - NALAS Observatory</title>
      </Helmet>
      <div className="ndv-cont mapbg">
        <div className="container">
          <h2 className="swm-title">
            Circular Economy Indicators in South-East Europe
          </h2>
          <div className="row mt-4 mb-4">
            {/* <div className="col-lg-4">
              <img
                src={require(`../../assets/images/circular-economy/logo.png`)}
                alt="waste logo"
                className="wm-logo"
              />
            </div> */}
            <div className="col-lg-12">
              <div className="row ml-1">
                <div className="col-12 m-0 p-0">
                  {countries.length > 0 ? (
                    <div className="nalas-select-cont">
                      <label className="d-block custom-label">Country</label>
                      <select
                        value={selectedCountry}
                        className="custom-select"
                        onChange={onChangeCountry}
                      >
                        {countries.map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}
                </div>
                <div className="main-tabs">
                  <div className="main-tabs-titles">
                    {tabs.map((tab) => (
                      <div
                        key={tab.id}
                        tabid={tab.id}
                        onClick={() => {
                          handleTabSelect(tab.id);
                          setSelectedTab(tab.id);
                        }}
                        className={`main-tabs-title ${
                          selectedTab === tab.id ? "selected-tab" : ""
                        }`}
                      >
                        <div className="main-tabs-content">
                          <img
                            src={require(`../../assets/images/circular-economy/${tab.id}.svg`)}
                            alt="tab-icon"
                            className="mr-2 main-tab-item main-tab-image"
                          />
                          <p className="main-tab-item">{tab.name}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row ndv-chart-list">
            <div className="col-md-12 mt-0 pt-0">
              {loading ? fireLoading() : null}
              <SWChartsList
                charts={charts}
                loading={loading}
                countries={countries}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CircularEconomy;
