import React from "react";
import { Helmet } from "react-helmet";

function UsefulLinks() {
  return (
    <>
      <Helmet>
        <title>Useful Links - NALAS Observatory</title>
      </Helmet>
      <div className="section">
        <div className="container">
          <div className="row">
            <h2>Useful Links</h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default UsefulLinks;
