import React, { useLayoutEffect } from "react";
import "../../assets/css/infographics.css";
import "../../assets/css/rdo.css";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "../../assets/css/swm.css";
import "../../assets/css/charts.css";

am4core.useTheme(am4themes_animated);

const ColumnChart = ({ chartData, id, type, title }) => {
  useLayoutEffect(() => {
    const chart = am4core.create(`${type}${id}`, am4charts.XYChart);
    chart.data = chartData.dataset_composed.dataset;

    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.inside = true;
    categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
    categoryAxis.renderer.labels.template.fontSize = 20;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 1;
    valueAxis.max = 10;
    valueAxis.renderer.grid.template.disabled = true;

    // Do not crop bullets
    chart.maskBullets = false;

    // Remove padding
    chart.paddingBottom = 0;

    // Create series
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "category";
    series.columns.template.column.cornerRadiusTopLeft = 15;
    series.columns.template.column.cornerRadiusTopRight = 15;
    series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/b]";

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = title.replace(/\s/g, "");

    const exportFields = { ...chart.exporting.dataFields };
    delete exportFields.parent;
    chart.exporting.dataFields = exportFields;

    return () => {
      chart.dispose();
    };
  }, [chartData, id, type, title]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <h5 className="header-with-after-line">{title}</h5>
        </div>
      </div>
      <div
        id={type + id}
        className="rdo-graph"
        style={{ height: "500px" }}
      ></div>
    </>
  );
};

export default ColumnChart;
