import React from "react";
import "../assets/css/about.css";
import one from "../assets/images/about-section/1.svg";
import two from "../assets/images/about-section/2.svg";
import three from "../assets/images/about-section/3.svg";

function AboutSection() {
  return (
    <>
      <div className="section padding-top-0">
        <div className="container">
          <div className="row align-items-center col-spacing-50">
            <div className="col-12">
              <h3 className="about-title text-center">
                We provide <span>ACCESS</span> to timely, accurate, reliable and
                comparable <span>Indicators</span> and <span>Information</span>{" "}
                on local Governance
              </h3>
              <div className="about-icons">
                <img src={one} />
                <img src={two} />
                <img src={three} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutSection;
