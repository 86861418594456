import React from "react";

export const digestItemContentById = {
  5: {
    opening:
      "Since the onset of the COVID-19 pandemic schools have been closed for most of the year and learning has taken place through various distance learning platforms. All levels of government in South-East Europe have been involved in the switch to online and distance learning, in particular as regards ensuring equipment and internet access for all pupils and students. Local Government Associations have played a key role in coordinating such efforts between levels of government and other stakeholders to ensure a smooth transition to distance learning or the safe reopening of schools. The use of technology in the educational process stimulates the learning process, makes it more attractive for the students and helps them more in their future activity. Digitalisation of education is becoming a necessity, to address the disparities in terms of quality of and access to education of children, pupils and students from more remote and rural areas or coming from disadvantaged households.",
    paragraphs: [
      {
        title: <strong>Digitalisation of education.</strong>,
        contents: [
          {
            text: (
              <span>
                The municipality of <strong>Ferizaj</strong> (Kosovo) has
                stepped efforts to ensure online and distant learning for
                children from disadvantaged households, by providing{" "}
                <strong>tablets for children in need</strong> in order to ensure
                equal opportunities for all children to follow online classes.
                This was made possible through a cooperation with the business
                community and donors. The municiapality of{" "}
                <strong>Kocevje</strong> (Slovenia), has provided{" "}
                <strong>
                  computer equipment and internet connection for distance
                  learning
                </strong>{" "}
                is provided to families in need by schools, Municipality of
                Kočevje and donors from the local environment. The municipality
                of <strong>Logatec</strong> (Slovenia), has been supporting the
                delivery of{" "}
                <strong>online classes for pupils and students</strong> from
                primary and secondary schools. Also, activities of children's
                art school became{" "}
                <a
                  href="https://www.jskd.si/kulturna-mreza/osrednja-slovenija/logatec/korona_20_razstava.htm"
                  target="blank"
                >
                  online
                </a>
                . The municipality also provided free{" "}
                <a
                  href="ttps://www.mojaobcina.si/logatec/novice/prostovoljstvo/ucna-pomoc--2015.html"
                  target="blank"
                >
                  online learning assistance
                </a>
                , which information were available on the website of
                municipality. Students from <strong>Măgura</strong> commune
                (Romania) will receive tablets for online schooling too. With
                the decision of the municipality and the school administration,
                200 tables were to be purchased for all students, to ensure a
                smooth development of the school curriculum.
              </span>
            ),
            image: require("../../assets/images/5/1.jpg"),
            float: "float-right",
          },
          {
            text:
              "However, providing access to education through digital means requires significant financial resources. In this framework, the Board of Directors of the Timiș County Branch of the Association of Communes of Romania (AcoR), asked for financial help for local governments on online schooling. The request to provide students and teachers tablets or laptops, internet access and measures for the protection of personal data, involve financial resources that most administrative-territorial units cannot provide, primarily due to small budgets, secondly, due to low revenues due to the extension of deadlines for the payment of local taxes and fees and thirdly, due to many expenses incurred to combat the COVID-19, disinfection, aid for disadvantaged people, etc.",
          },
        ],
      },

      {
        title: (
          <strong>
            Six schools in Burgas will receive funding for opening innovative
            technology centres and specialized cabinets.
          </strong>
        ),
        contents: [
          {
            text: (
              <span>
                Six schools in Burgas municipality (Bulgaria) were approved and
                will receive funding for the opening of innovative technology
                centres and specialized cabinets for practical training of
                children. The approved educational institutions, which will open
                technological centres under the program of the Ministry of
                Education and Science “Building a STEM school environment" /
                STEM - Science, Technology, Engineering, Math. All technology
                centres will have modern design and furniture, as well as
                specialized technical equipment. In the new labs and cabinets
                new educational content will be integrated, consistent with the
                interests and age characteristics of the students and with an
                emphasis on the development of their creativity and practical
                skills. A Centre for Space Research, related to the newest
                subject included in the curriculum of the school will also be
                established in a primary school, where simulations on research
                and engineering processes in the high-tech industries and
                professions of the future will be performed. The centre will be
                thematically divided into laboratories, united by the so-called
                "Cosmodrum" - a space for sharing the results of students’
                projects. This will stimulate self-study skills, teamwork and
                critical thinking. Specific focus will also be dedicated to
                vocational schools with the creation of Centre for Digital
                Creators.
              </span>
            ),
          },
        ],
      },

      {
        title: <strong>Don't let the rural schools perish!</strong>,
        contents: [
          {
            text: (
              <span>
                To respond to the local community needs and respond to education
                challenges in rural areas, the commune of{" "}
                <strong>Ciugud</strong> (Romania) established a{" "}
                <strong>Smart School</strong>, in which about 130 children
                previously studying in several villages of Ciugud will study
                together in the new school with 9 classes, starting with the
                preparatory one (0) and up to the eighth grade. Children have
                virtual textbooks, and lessons can be tracked from home, in the
                online environment. The intelligent system implemented in the
                school allows the lighting and air conditioning in each class to
                be adjusted according to preset scenarios or with the help of
                sensors. In the case of lighting, the sensors turn on or off the
                light when they notice the presence in the classroom and then
                adjust it according to the daylight so as not to affect the eyes
                of the children. Gheorghe DĂMIAN, mayor of Ciugud commune
                highlighted that{" "}
                <i>
                  “the most important investment to be made in a community is in
                  children because they represent the future. We have noticed in
                  recent years that more and more parents are choosing to take
                  their children to school in Alba Iulia city. As a result of
                  the discussions with them, I understood that in Ciugud commune
                  there is a need for a modern, friendly school in which we must
                  use the most modern teaching methods. This is how the idea of
                  a smart school was born, in which teachers will use technology
                  in the teaching process so that lessons become more attractive
                  to children. We believe that the village school should be
                  supported and should not be allowed to die because all
                  children in rural areas have the right to education. We thank
                  all those who supported us in this project."{" "}
                </i>
              </span>
            ),
            image: require("../../assets/images/5/2.jpg"),
            float: "float-right",
          },
          {
            text: (
              <span>
                The Association of Communes of Romania (ACoR) have developed a
                series of public policy proposals that lead to the sustainable
                development of the Romanian village. It is obvious that rural
                development requires education, and this means school
                (supporting it, even if the number of children does not fall
                within the limits imposed at national level), in this regard we
                have developed the proposal for a public policy on education.
                ACoR members aim at increase in the enrolment rate in preschool,
                primary and secondary education in rural areas by at least 15%,
                but also the reduction of school dropout related to primary and
                secondary education to 1%, in the next 10 years. They understand
                the need investments to equip schools with IT equipment (in
                these difficult times it is necessary to equip and train both
                teachers and students with such equipment, and it is clear how
                deep are the inequalities village - city are from this point of
                view). For increased promotion and better school results of
                students in rural areas are needed laboratories, libraries,
                non-formal activities spaces, gyms (which could benefit the
                entire community). We believe that the provision of such
                facilities (such as access to health services and education)
                discourages the depopulation of the Romanian village. The
                Association of Communes of Romania wants to protect and preserve
                the traditions of the Romanian village, but also to modernize it
                in order to become productive and sustainable.
              </span>
            ),
            // image: require("../../assets/images/5/2.jpg"),
            // float: "float-right",
          },
        ],
      },

      {
        title: (
          <strong>
            The EU has supported the process of switching to digitalisation in
            education.
          </strong>
        ),
        contents: [
          {
            text: (
              <span>
                The Government of Romania Emergency Ordinance no. 144/2020which
                provides for the allocation of 175 million euros from European
                funds for the purchase of IT equipment to be granted to students
                in order to access online learning platforms, protective
                equipment for students and teachers and modular spaces for safe
                deployment of the educational process or for the arrangement of
                the sanitary groups. The implementing authority is the Ministry
                of European Funds, through the relevant departments, and the
                beneficiaries are the local public authorities and / or the
                educational units.
              </span>
            ),
            // image: require("../../assets/images/5/2.jpg"),
            // float: "float-right",
          },
        ],
      },

      {
        title: (
          <strong>
            Overlapping responsibilities risk hindering education process during
            the pandemic.
          </strong>
        ),
        contents: [
          {
            text: (
              <span>
                CALM has supported intergovernmental dialogue and coordination
                between local governments and the national government as regards
                the challenges faced by local governments in managing the
                activity of educational institutions in their communities during
                the pandemics, as a result of the unclear legislation on roles
                and responsibilities across levels of government. The Minister
                of Education of Moldova recognized three fundamental challenges
                stemming out from the COVID-19 crisis: the lack of technology,
                the lack of internet and the lack of digital skills in a part of
                the population. The Minister also committed to providing
                equipment and internet connection to teachers and students who
                do not have computer technology, while also stepping up efforts
                to build the capacities of teachers on the use of digital
                resources. According to CALM President, Ms. Tatiana Badan, mayor
                of Selemet village, Cimișlia, during this period, in many
                villages in the Republic of Moldova it was not possible to
                remotely continue the education process in kindergartens.{" "}
                <i>
                  "From the moment the state of emergency in the Republic of
                  Moldova was declared, the employees of the kindergartens from
                  Cimișlia district were granted annual leave. But how will we
                  proceed in the summer when parents will have to go to work? At
                  the same time, there must be legal clarity in salary
                  relationships. If we keep paying the technical staff of these
                  institutions, we could, for example, involve them in other
                  activities"
                </i>
                , said Ms. Badan. The President of CALM proposed also that from
                the savings made now, it should be possible to purchase
                computers or connect to the Internet of preschools children
                where it is lacking, but for this it is necessary to give local
                governments the possibility to make changes in local budgets
                since it refers to conditional grants. CALM Executive Director
                Viorel Furdui underlined that LPAs need more leverage to be able
                to make more decisions, depending on the situation in each
                community. According to CALM Executive Director, the
                methodologies developed by the Ministry that also target the
                work of local authorities should be consulted with LPA
                representatives when conceptualizing them, thus avoiding many of
                the discussions that are taking place now and many of the issues
                they currently face.
              </span>
            ),
          },
        ],
      },

      {
        title: (
          <strong>
            Old Town Municipality – Sarajevo animates elementary school students
            to paint and write rhymes and awards their work on the topic
            #stayathome.
          </strong>
        ),
        contents: [
          {
            text: (
              <span>
                To help students who are not allowed to leave their homes to get
                through this difficult period of isolation,{" "}
                <strong>Old Town Municipality – Sarajevo</strong> (Bosnia and
                Herzegovina) launched a competition for students from grade I to
                IX. Students were invited to paint works of art and write rhymes
                on the topic #stay-at-home and submit them to their schools.
                They are expected to convey their emotions and everything they
                experience as a result of the situation across the country and
                the spread of the COVID 19 virus: fears, doubts, worries or
                carelessness, loneliness and lack of friends, worries and joys
                on paper through pictures and songs. Out of the submitted
                papers, a competent committee will select the best 12 works (six
                fine arts and six literary works) to be voted on through the
                municipal FB page. The students whose works will have the most
                likes will receive a cash prize.
              </span>
            ),
          },
        ],
      },

      {
        title: <strong>Competition on “Reading-friendly municipality”</strong>,
        contents: [
          {
            text: (
              <span>
                The Association of Municipalities and Towns of Slovenia (SOS) n
                cooperation with the Slovenian Public Libraries Association, and
                Ministry of Culture of the Republic of Slovenia organised the
                4th Reading-Friendly Municipality” competition”. The selected
                dates of competitive activities have a symbolic meaning, because
                they are related to the date of dead and birth of one of the
                greatest Slovenian poets, France Prešeren. The aim of the
                competition is to encourage{" "}
                <strong>
                  municipalities to implement measures for the improvement of
                  reading literacy, promotion of reading and development of
                  reading culture
                </strong>{" "}
                as well as raising the awareness of reading as a value of life.
                Until 2020, the competition has been successfully encouraged by
                many participative municipalities and in total 38 of them have
                been already titled. SOS is aware of the meaning of literacy
                among citizens and therefore has a pleasure to support
                operations of local public organisations that support
                educational processes.
              </span>
            ),
          },
        ],
      },

      {
        title: <strong>Promoting sports and culture in a new way.</strong>,
        contents: [
          {
            text: (
              <span>
                Municipalities in Turkey have come up with new methods to
                sustain their public services in order to prevent the spread of
                coronavirus. In the field of education and training, several
                municipalities continue to provide education through online
                tools. The sport teachers affiliated with the Sport Centers of
                Kocaeli Metropolitan Municipality (Turkey) have been sharing
                training videos for students and their families to keep them
                doing their exercises. On the other hand, Pendik Municipality
                Art Academy and Ümraniye Municipality Music Academy in Turkey
                started to deliver online courses to trainees. Moreover,
                Beylikdüzü Municipality (Turkey) have given online access to
                more than 3000 books without demanding a fee for those having
                membership in the municipality’s libraries.
              </span>
            ),
            image: require("../../assets/images/5/3.jpg"),
            float: "float-right",
          },
        ],
      },

      {
        title: <strong>Liberating minds within confined spaces.</strong>,
        contents: [
          {
            text: (
              <span>
                The outbreak of Covid-19 and the consequent psychical distancing
                measures taken to contain the virus have been negatively
                affecting the psychology of people. For those who have been
                already staying at home for weeks, it became harder to continue
                living in a limited space as such. Municipalities in Turkey,
                being determined to keep people at home, have come up with
                several solutions to help people to deal with the current
                situation in confinement. Of such solutions, we see that several
                municipalities organizing cultural activities.{" "}
                <strong>Ankara Metropolitan Municipality</strong> started a free
                if charge virtual exhibit a total of 127 paintings of around 50
                artists on its website. Several municipalities such as{" "}
                <strong>Sancaktepe</strong> and <strong>Maltepe</strong> have{" "}
                <strong>broadcasted cultural activities</strong> such as theatre
                performances or music concerts on their social media accounts
                including You Tube.
              </span>
            ),
            // image: require("../../assets/images/5/3.jpg"),
            // float: "float-right",
          },
        ],
      },

      {
        title: <strong>Stay Home, Stay with Books. </strong>,
        contents: [
          {
            text: (
              <span>
                A different project comes from <strong>Sakarya</strong>{" "}
                Metropolitan Municipality (Turkey) which has set out with the
                motto “Stay Home, Stay with Books” and distributed books to its
                residents. Within the project, around 3 thousand books have been
                delivered to the new owners to date. Tuzla Municipality (Turkey)
                has organized several competitions such as “A Scene from Home”
                photography contest and “Stay Home, Play for Turkey” music
                contest, bringing excitement and joy to houses.
              </span>
            ),
            // image: require("../../assets/images/5/3.jpg"),
            // float: "float-right",
          },
        ],
      },

      {
        title: (
          <strong>
            At the time of the pandemic, turn up the radio a little.
          </strong>
        ),
        contents: [
          {
            text: (
              <span>
                In April 2020 the <strong>Herceg Novi</strong> April Theater
                Festivities migrated to the radio. The HAPS radio motto "Turn up
                the radio a little" brings different but quality cultural
                content to the homes - radio dramas.{" "}
                <i>
                  Theatre lovers will have the opportunity to listen to 15 radio
                  dramas on three radio stations, in four terms. "It was very
                  important that at the time of the pandemic, with all the
                  restrictions that are imposed, we hold this festival,"
                </i>{" "}
                the organizers said. The production of contemporary radio drama
                was presented through radio phonic works of national public
                broadcasters, as well as art faculties / academies from the
                region, and this year HAPS participants are Croatian Radio,
                Radio of Bosnia and Herzegovina, Radio Belgrade, Radio of
                Montenegro, Academy of Dramatic Arts of the University in
                Zagreb, Academy of Performing Arts, University of Sarajevo,
                Faculty of Dramatic Arts, University of Arts in Belgrade,
                Faculty of Media and Communication, Singidunum University and
                Faculty of Dramatic Arts, University Montenegro.
              </span>
            ),
            image: require("../../assets/images/5/4.jpg"),
            float: "float-right",
          },
        ],
      },
    ],
  },

  7: {
    opening: (
      <div>
        <div className="margin-bottom-10">
          <strong>COVID-19 IMPACT AND RESPONSE IN HEALTHCARE</strong>
        </div>
        <div className="margin-bottom-10">
          <strong>MUNICIPAL HEALTHCARE RESPONSE TO THE COVID-19</strong>
        </div>
      </div>
    ),
    paragraphs: [
      {
        title: (
          <strong>
            Bulgarian municipalities started testing of citizens for COVID-19.{" "}
          </strong>
        ),
        contents: [
          {
            text: (
              <span>
                On their own initiative several municipalities in Bulgaria
                started testing of citizens with quick COVID-19 tests. In{" "}
                <strong>Bansko</strong>, which was the first town under
                lockdown, two medical teams of Regional Health Inspection have
                started the gradual testing of people from risk groups for
                COVID-19 in Medical Centre “Holy Annunciation”. Testing was
                conducted under a pre-drafted list to avoid gatherings of
                people. Pool testing for COVID-19 virus conducted in{" "}
                <strong>Burgas</strong> as well. Testing by pool methodology has
                been verified and lists of risk groups were drawn up and phased
                in. When several samples were collected and tested positive,
                individual testing was carried out. In this way, the number of
                the surveyed persons was significantly increased and data on the
                health status of the population be collected in a much shorter
                time. The testing was conducted by nasopharynx swab sampling.
                That way up to 10 to 20 people could be tested in one go.
                Similar measures were taken in the capital -{" "}
                <strong>Sofia</strong> and <strong>Stara Zagora</strong>{" "}
                municipalities. First to undergo the procedure were police
                officers, doctors, social workers, administrative employees at
                front desks working with clients, employees of social patronage,
                teams that deliver food products to homes. Testing was made by
                specialists from Burgas hospitals, Reference laboratory,
                Pulmonary Hospital and the Regional Health Inspectorate. Upon a
                positive result in the pool group, re-examination of all the
                checkers was performed. In April 2020, in 79 municipal hospitals
                in Bulgaria, 233 beds were allocated for intensive treatment and
                monitoring of patients with COVID-19, following the Order of
                Minister of Health. A total of 2 908 beds were provided for
                patients without complications. In many places, the
                implementation of the order was related to unscheduled repairs
                and reconstructions on the territory of municipal health
                facilities, which were supported by funds from municipal
                budgets.
              </span>
            ),
            image: require("../../assets/images/7/1.jpg"),
            float: "float-right",
          },
          {
            text:
              "In April 2020, in 79 municipal hospitals in Bulgaria, 233 beds were allocated for intensive treatment and monitoring of patients with COVID-19, following the Order of Minister of Health. A total of 2 908 beds were provided for patients without complications. In many places, the implementation of the order was related to unscheduled repairs and reconstructions on the territory of municipal health facilities, which were supported by funds from municipal budgets.",
          },
        ],
      },

      {
        title: <strong>Virtual Health Clinic in Bansko</strong>,
        contents: [
          {
            text: (
              <span>
                Doctors, specialists in various fields, assist with online
                consultations the residents of Bansko (Bulgaria) as the city is
                under full lockdown. The Virtual Health Clinic was established
                on March 26th, and in just a few hours 15 doctors joined the
                initiative of Prof. Pandev, the founder of the group in
                Facebook, who is also one of the leading endocrine surgeons in
                Europe.{" "}
                <i>
                  "They are from Lozenets University Hospital, I am a
                  representative of ISUL, Alexandrovska Hospital, Sofia. We are
                  in different profiles - from endocrine surgeon,
                  gastroenterologist, resuscitator-anesthesiologist,
                  radiologist, general surgeons, etc. The idea is simple - to
                  provide Bansko residents with the appropriate online medical
                  help for free the fastest way possible, since Bansko is under
                  strict quarantine."
                </i>{" "}
                – shared Prof.Pandev. The first consultations were also made and
                treatment was prescribed. Doctors discuss the cases with each
                other and if necessary, contact patients via telephone on the
                platform. The unique online health consultation initiative
                compensates for the quarantine restrictions in Bansko and helps
                available physicians in the city, which are only 9.
              </span>
            ),
          },
        ],
      },

      {
        title: <strong>Three more temporary hospitals in Belgrade. </strong>,
        contents: [
          {
            text: (
              <span>
                Deputy Mayor of Bellgrade, Mr. Goran Vesic on 1 April 2020
                announced the preparation of three more temporary hospitals in
                Belgrade with the installation of beds in the “Sports Hall”, the
                “Pioneer Hall” and the “Stark Arena”. The three new temporary
                hospitals have a capacity between 2000-2500 beds helping the
                city manage the pressure on hospital structures. The deputy
                mayor urged for the understanding of citizens on the seriousness
                of the situation and warned that it takes only one percent of
                people who do not comply with the emergency measures can
                endanger the other 99 percent.
              </span>
            ),
          },
        ],
      },

      {
        title: (
          <strong>
            Novo Sarajevo Municipality provides funds for purchasing Respiratory
            Supplies.
          </strong>
        ),
        contents: [
          {
            text: (
              <span>
                The Municipality of Novo Sarajevo (Bosnia and Herzegovina),
                through the Civil Protection Service allocated funds for the
                procurement of two respirators for two Sarajevo hospitals in
                order to help combat the spread of the virus corona epidemic. In
                his address, Mayor Nedzad Koldzo said that one of the decisions
                of the Civil Protection Headquarters was to direct part of the
                funds towards these two primary medical institutions in the
                Sarajevo Canton.{" "}
                <i>
                  “By allocating these funds, we, as a local community, want,
                  not only declaratively, but also practically to assist in the
                  procurement of equipment for the General Hospital and the
                  Clinical Centre of the University of Sarajevo, especially with
                  what is the priority today when it comes to the treatment of
                  patients with coronary viruses, the procurement respirators
                  over which has been much controversy in the public recently”
                </i>
                , said Mayor Koldzo.
              </span>
            ),
            image: require("../../assets/images/7/2.jpg"),
            float: "float-right",
          },
          {
            text: (
              <span>
                <strong>
                  Municipalities engage additional healthcare staff to assist
                  citizens.
                </strong>{" "}
                All municipalities of Kosovo have engaged additional staff to
                assist their citizens in sensitizing, preventing and monitoring
                the condition of those infected with COVID-19. The Collegium for
                Health of the Association of Kosovo Municipalities (AKM)
                highlights that most municipalities have set up tents for the
                reception of citizens in which they have engaged the staff of
                Main Family Medical Centre’s (MFMCs) and field teams.
                Municipalities have been giving their contribution at border
                crossings, where the primary health care staff in municipalities
                have their own teams engaged at Kosovo border crossings
                depending on the geographical position and the region in which
                they are located. According to the Municipal Directors,
                epidemiologists or infectiologists are included in these teams
                as support staff. According to the Chairman of the Collegium of
                Directors of Health at the AKM, Dr. Bujar Gashi, municipalities
                are giving their maximum contribution to overcome this situation
                despite the difficulties they may have.
              </span>
            ),
          },
          {
            text: (
              <span>
                <strong>
                  Municipalities ready to provide services for receiving
                  parenteral therapy for patients with COVID-19
                </strong>
                . Despite the challenges that municipalities are facing, being
                in the front line in providing services and managing this
                pandemic situation, municipal departments of health are faced
                with numerous difficulties in meeting the requirements and
                provision of health services in defence of COVID-19. Due to the
                increase in the number of infected with COVID-19, Kosovo
                municipalities faced the constant demands of citizens in
                providing services for parenteral therapy at the Main Family
                Medicine Centres (MFMC). Although not foreseen in the concept of
                family medicine, a local responsibility, municipalities are
                committed to overcoming this situation where they have taken
                responsibility in providing this therapy for the benefit of
                patients. Despite the workloads and limited capacities in
                providing this therapy, the municipalities have taken
                initiatives in defining a special centre which would be in
                function for the treatment and in offering this therapy. Such an
                initiative was undertaken after the increase of visits by
                patients with COVID-19, who are referred by the secondary or
                tertiary level of health. Also, for this purpose, the Municipal
                Health Directorates have formed teams for family visits in
                offering this therapy so that patients do not walk to different
                centres from case identification to treatment and provision of
                parenteral services. One of the problems was the lack of oxygen
                in health institutions, so such a concern should be addressed
                and taken seriously by the Ministry of Health. In terms of
                cooperation with the central level, it remains the main link in
                advising and taking measures and decisions regarding preventive
                measures to be taken in the future.
              </span>
            ),
            image: require("../../assets/images/7/3.jpg"),
          },
        ],
      },

      {
        title: (
          <strong>
            Banja Luka "You are not alone” campaign: Delivery of medicines for
            people over 65.
          </strong>
        ),
        contents: [
          {
            text: (
              <span>
                The City of <strong>Banja Luka</strong> (Bosnia and Herzegovina)
                issued a public invitation to health institutions - pharmacies
                to join the "You are not alone" campaign and deliver medicines
                to elders above 65 years old in their homes so that they
                minimise their exposure risk. Delivering medications to the
                elderly and those in need remains one of the fundamental aspects
                of the SEE local governments response to the COVID-19 crisis.
              </span>
            ),
          },
        ],
      },

      {
        title: (
          <strong>
            Municipalities takes matters at hand and produce and distribute
            protective masks.
          </strong>
        ),
        contents: [
          {
            text: (
              <span>
                The early weeks into the pandemic shown a new challenge for all
                levels of government – ensuring the availability of protective
                masks. SEE municipalities have made great efforts to equip
                residents with masks in order to prevent the spread of COVID-19.
                The municipality of <strong>Sakarya</strong> (Turkey), engaged
                in{" "}
                <strong>
                  producing and distributing about 4 million masks
                </strong>{" "}
                to all citizens, purchasing and{" "}
                <strong>
                  setting new disinfectant pumps all over the city
                </strong>
                , especially in front of the ATMs, public building, hospitals,
                bus stops etc. The municipality has also supported hospital
                staff by providing them disinfectants and necessary protective
                gears. Other metropolitan municipalities such as{" "}
                <strong>Kocaeli, Bursa</strong> and <strong>Tekirdağ</strong> as
                well as district municipalities like <strong>Beyoğlu</strong>{" "}
                and <strong>Büyükçekmece</strong> in Istanbul have started to
                produce their own masks. Several other municipalities such as{" "}
                <strong>
                  Istanbul Metropolitan Municipality, Edirne, Bilecik, Yalova,
                  Kepez
                </strong>{" "}
                and <strong>Kartal</strong> Municipalities distribute free masks
                to the residents. The Commune of <strong>Sadu</strong> (Romania)
                engaged in <strong>producing protective masks</strong>, which
                were distributed to each house within the "one mask for each
                house" campaign.
              </span>
            ),
            image: require("../../assets/images/7/4.jpg"),
            float: "float-right",
          },
        ],
      },

      {
        title: (
          <strong>A dedicated place for healthcare professionals. </strong>
        ),
        contents: [
          {
            text: (
              <span>
                <strong>Izmir Metropolitan Municipality</strong> (Turkey),{" "}
                <strong>
                  rented a dormitory and a hotel for health care professionals
                </strong>{" "}
                to have a rest and for the safety of their relatives to be
                guaranteed.
              </span>
            ),
          },
        ],
      },

      {
        title: (
          <strong>
            Providing protective equipment for healthcare workers and
            front-liners.
          </strong>
        ),
        contents: [
          {
            text: (
              <span>
                The town hall of <strong>Straseni</strong> (Moldova) called upon
                the whole community to{" "}
                <strong>
                  donate "one working day for the equipment of the health
                  institutions of Straseni”.{" "}
                </strong>{" "}
                This appeal was addressed to all citizens, to the expatriates
                (citizens living abroad), to companies and associations. In this
                context among others 316 employees of institutions subordinated
                to Straseni town hall, donated their "salary for one working
                day" to purchase equipment for the health institutions. The
                municipality of <strong>Colibasi</strong> (Moldova) asked its
                diaspora for
                <strong>
                  {" "}
                  support in providing hygienic and medical equipment for local
                  healthcare centers.{" "}
                </strong>{" "}
                Thus, 4000 tubes of disinfectant, 6,000 pairs of gloves, masks,
                disinfectants, etc. arrived from Great Britain and France.
                Supplies were delivered to all medical facilities in the
                municipality, although medical facilities are not in the
                competence of Moldovian Local Governments of 1st level. The
                local Colibasi Health Center also provides its services to two
                other municipalities (11,000 people) and the emergency medical
                station. Beneficiaries of this action were also staff of grocery
                shops and public institutions in Colibasi. The municipality of
                Straseni (Moldova) has created an{" "}
                <strong>online platform for gathering equipment</strong> for
                those in the first line (medical staff, police).
              </span>
            ),
            image: require("../../assets/images/7/5.jpg"),
            float: "float-right",
          },
          {
            text: (
              <span>
                The City of <strong>Leova</strong> (Moldova) has supported the
                medical staff, the employees of the rayon hospital, the public
                health center, the emergency medicine, from the hospitalization
                department although our resources are very limited. The mayor of
                Leova, Alexandru Bujorean highlights that they have also
                launched a fundraising campaign, collected resources, including
                from local businesses, diaspora and local citizens and came up
                with support. With this support they bought masks, protective
                equipment and disinfectants, a good part of which will go to
                medical institutions. The Mayor highlights that the Central
                Government must take care these days, first and foremost, of the
                doctors, to address maximum support to the health care system,
                because, if the health care system ceases, all other
                institutions in the state are worthless.{" "}
                <i>
                  “I'm afraid we will get to the situation where the number of
                  beds in hospitals will not be the problem, but the number of
                  doctors”,
                </i>{" "}
                Mr Bujorean said. Similarly, the municipalities of Sângera and
                Edinet in Moldova have taken active measures to secure
                protective materials for the medical institutions and those
                working at the frontline.
              </span>
            ),
          },
          {
            text: (
              <span>
                The municipality of <strong>Troyan</strong> (Bulgaria) conducted
                a{" "}
                <strong>
                  donation campaign to raise funds for disease prevention and to
                  support the treatment and monitoring of patients
                </strong>{" "}
                with COVID-19 at local hospitals.
              </span>
            ),
          },
          {
            text: (
              <span>
                <strong>Lipăneşti City Hall</strong> (Romania) bought protective
                masks and distributed them to inhabitants. The protection
                package contains two masks and two forms for self-statements,
                plus two addresses with recommendations. The distribution to the
                population is made by city officials and volunteers. The
                Community Medical-Social Center Luncavița (Romania), inaugurated
                on 10 October 2018, crowns the immense work of a team that laid
                the foundation of a prototype system of integrated functioning
                of medical and social services. Thus,{" "}
                <strong>Luncavița commune</strong> operates a system whereby the
                elderly, children at risk, persons with disabilities or those
                who are not medically insured, single parents, all those in
                difficulty, receive integrated services from community medical
                assistants, social assistants, elderly caregivers at home, the
                staff of the Mayor's specialized apparatus, the representatives
                of the school and the Church, in order to prevent
                marginalization and social exclusion, with the purpose of
                improving the health and quality of life. The City Hall provides
                to the centers, the local voluntary service for emergency
                situations and to the local police protective masks, which were
                distributed first to the vulnerable persons, to the
                representatives of public institutions and to the citizens in
                need, within the limits of the available stocks.
                <div className="margin-top-10">
                  The Civil Protection of the Municipality of{" "}
                  <strong>Kočevje</strong> (Slovenia) supplies{" "}
                  <strong>
                    protective equipment to the most exposed emergency services
                    and disinfects critical points in public areas,{" "}
                  </strong>{" "}
                  where contact occurs (e.g. ATM keys). The Municipality of{" "}
                  <strong>Logatec</strong> (Slovenia) has distributed{" "}
                  <strong>protective masks to citizens</strong>, by giving a
                  priority to elderly, chronic patients, single mothers and
                  other vulnerable groups.
                </div>
              </span>
            ),
            image: require("../../assets/images/7/6.jpg"),
            float: "float-right",
          },
          {
            text: (
              <span>
                <strong>
                  EU-funded COVID-19 test kits delivered to Republic of Srpska
                  (Bosnia and Herzegovina – BiH).{" "}
                </strong>
                As of 6 April, total of 2,500 corona virus test kits, financed
                by the European Union and procured by the United Nations
                Development Programme (UNDP) in Bosnia and Herzegovina (BiH),
                have been delivered to Cantonal hospitals in Sarajevo, Mostar
                and Tuzla, and to the Republic of Srpska Institute of Public
                Health. EU Ambassador in BiH Johann Sattler stated:{" "}
                <i>
                  “This is the first in a series of deliveries under the EU
                  financial assistance for emergency medical needs of Bosnia and
                  Herzegovina. A total of €7 million in EU funds will enable the
                  procurement of 80 respirators, 7,500 test kits and 15000
                  pieces of personal protective equipment."{" "}
                </i>
              </span>
            ),
            image: require("../../assets/images/7/7.jpg"),
            float: "float-right",
          },
          {
            text: (
              <span>
                <strong>
                  Voluntary blood donors active during the state of emergency.
                </strong>{" "}
                The Institute for Blood Transfusion Niš and Red Cross Prokuplje
                (Serbia) organized a blood donation action in April, the third
                one since the state of emergency was introduced in the Republic
                of Serbia. According to the authorities in the Red Cross, 31
                units of this valuable liquid have been collected, which is
                always necessary, especially during the difficult period in
                which we are.{" "}
                <i>
                  "The appeal and commitment of our volunteers have contributed
                  to the response of regular donors, but also those who have
                  just decided to become voluntary blood donors and thus
                  contribute to saving lives. “
                </i>{" "}
                Voluntary blood donation actions were carried out with special
                precautions to protect donors, members of the Institute for
                Blood Transfusion Niš and Red Cross volunteers.
              </span>
            ),
            image: require("../../assets/images/7/8.jpg"),
            float: "float-left",
          },
          {
            text: (
              <span>
                <strong>
                  3D printing in support for fight against Covid-19.
                </strong>{" "}
                As the Coronavirus pandemic is spreading globally, the health
                care systems of the most developed countries become overloaded
                with the COVID -19. The shortage of medical supplies has become
                one of the main problems. The Capital City of{" "}
                <strong>Podgorica</strong> (Montenegro) and Montenegrin
                Employers Federation, together with the Amateur Radio Club of
                Montenegro, the Polytechnic-Montenegrin Association for
                Technical Culture and Montenegro Robotics, have joined in the
                initiative of providing the missing equipment for health workers
                of the Clinical Center and other health institutions from the
                territory of Podgorica. On this occasion, hundreds of visors are
                being developed at the Capital’s Competence Center, which are
                part of the protective equipment of health workers and have been
                approved by the competent Institute for Public Health. The
                deputy Mayor Sladjana Vujacic stated that “The Capital will
                continue to work hard to develop this protective equipment,
                together with our partners, in order to preserve the health of
                our health care professionals and thus preserve the health of
                all citizens of Montenegro”. The visors are manufactured on five
                3D printers that are part of valuable equipment provided through
                the cross-border project “competenceNET” which is funded within
                EU programme INTERREG – IPA CBC Croatia-Bosnia and
                Herzegovina-Montenegro 2014 – 2020. More info{" "}
                <a
                  href="https://podgorica.me/2020/03/26/u-centru-kompetencija-u-toku-izrada-vise-stotina-vizira-koji-su-dio-zastitne-opreme-zdravstvenih-radnika/"
                  target="blank"
                >
                  here
                </a>{" "}
                and{" "}
                <a
                  href="https://www.youtube.com/watch?v=1bRGR1gJ6lY"
                  target="blank"
                >
                  here
                </a>
                . <strong>Muratpaşa Municipality</strong> (Turkey), has also
                produced face shields with 3D printers which have then been
                distributed to professionals at risk.
              </span>
            ),
            image: require("../../assets/images/7/9.jpg"),
            float: "float-right",
          },
        ],
      },

      {
        title: (
          <div className="margin-bottom-10">
            <strong>MENTAL HEALTHCARE SUPPORT</strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                Due to the quarantine, the pandemic development, and eventual
                loss of economic security, psychological disorders such as fear,
                anger, anxiety, insomnia, depression have become very common.
                Local governments have responded by providing psychological
                support their citizens through online consultations.
              </span>
            ),
          },
          {
            text: (
              <span>
                The Municipality of <strong>Ferizaj</strong> (Kosovo), organized
                an <strong>online platform of communication</strong> with
                citizens, for psychological needs involving video lectures from
                psychologist and psychiatrist, doctors, religious
                clerics/representatives with aim of providing care of citizens
                mental health, helping them manage stress, anxiety and panic.
                The Municipality of <strong>Shkodër</strong> (Albania), also has
                provided online psychological support for its citizens. The
                municipality of <strong>Selemeti</strong> (Moldova), also
                offered{" "}
                <strong>
                  information and psychological support via telephone
                </strong>{" "}
                to citizens with the support of young volunteers. The
                municipality of <strong>Kocaeli, </strong> (Turkey) started
                providing <strong>video psychological support</strong> to
                citizens of all ages at home. Psychoeducation processes have
                been carried out to ensure effective communication and quality
                time in the home for adults, children, individuals over 65 years
                old and families.{" "}
                <strong>
                  Istanbul Metropolitan Municipality (Turkey) created a
                  telephone support
                </strong>{" "}
                line in order to provide a psychological consulting for those
                who are negatively influenced by the social distancing and
                self-isolation measures. The Psychological Counseling Line which
                allows people to talk to a psychologist is serving every day of
                the week. Izmit Municipality <strong> (Turkey) </strong> has
                also started to provide psycho-social support to its citizens in
                dealing with Covid-19 stress where psychologists and social
                workers provide service during working hours.
              </span>
            ),
          },
          {
            text: (
              <span>
                The municiapality of <strong>Kocevje</strong> (Slovenia), has
                provided <strong>psychological support</strong> through the
                Kočevje Health Centre in mental distress in the face of the
                epidemic. The municipality of <strong>Logatec</strong>{" "}
                (Slovenia), has provided{" "}
                <strong>
                  psychological support for reduction of panic and stress
                </strong>{" "}
                of citizens in duration of the pandemic (in cooperation with
                Logatec Health Centre).
                <div className="margin-top-10">
                  The municipality of <strong>Kolasin</strong> (Montenegro)
                  provides psychosocial support and empowerment for its citizens
                  as part of measures to overcome the coronavirus crisis. The
                  goal of psychosocial support is, above all, the well-being of
                  the individual, family and community, and mitigating the
                  impact of a crisis event, facilitating the recovery process,
                  and preventing or reducing the potential psychological impact.
                  It is intended for all people who are affected by a crisis and
                  are unable to overcome a situation they consider painful and
                  stressful. The support is provided each day from 10am to 2pm.
                </div>
              </span>
            ),
            image: require("../../assets/images/7/10.jpg"),
            float: "float-right",
          },
          {
            text: (
              <span>
                Citizens of <strong>Zvezdara</strong> (Serbia) of all
                generations who need help and support or to talk to someone in a
                state of emergency can call on a dedicated line to submit their
                request to speak with an expert. Their request and basic
                information is forwarded within 24 hours to the expert
                volunteers of the Zvezdara Volunteer Service who contact them.
                Citizens can also contact the Counselling Services for
                individuals and families by e-mail. In this way, the Counselling
                Centre of the City Municipality of Zvezdara seeks to help
                interested citizens to overcome the time of isolation, fear,
                uncertainty, leisure and loneliness with as little consequences
                as possible. The assistance of lawyers is also envisaged to
                provide information in the field of social rights. Free and
                professional psychosocial assistance by telephone is intended
                especially for elderly fellow citizens, families caring for
                immobile, demented and long-term sick and infirm family members,
                parents and families with partner and authority problems, to
                overcome the negative effects on mental health during the state
                of emergency.
              </span>
            ),
          },
        ],
      },
    ],
  },

  6: {
    opening: (
      <div>
        <div className="margin-bottom-10">
          <strong>
            COVID-19 IMPACT AND RESPONSE IN SOCIAL PROTECTION - LEAVE NO ONE
            BEHIND
          </strong>
        </div>
      </div>
    ),
    paragraphs: [
      {
        contents: [
          {
            text: (
              <span>
                Immediately after the announcement of the first cases of
                coronavirus infections, South-East European municipalities took
                a number of measures to protect the population and limit the
                spread of the virus. Following the declaration of the state of
                emergency, local governments mobilized significant resources -
                human and financial – to fully implement orders of the health
                authorities and to preserving the health and safety of citizens
                while providing also the basic municipal services to citizens.
                However, the tasks assigned to the municipalities in relation to
                the anti-epidemic measures and monitoring exceed by far the
                funds planned in the municipal budgets, and reserves in many
                places are exhausted.
              </span>
            ),
            // image: require("../../assets/images/7/1.jpg"),
            // float: "float-right",
          },
          {
            text: (
              <span>
                In Bulgaria, during the state of emergency and up to three
                months after its abolition, mayors are not entitled to close or
                reduce the capacity of social services delegated by the state
                due to lack of users, with the exception of specialized
                institutions for children. It should be noted that social
                distancing requirements led to a significant increase in the
                number of people being cared for by the municipal social
                services. Volunteers’ teams for food and medicine delivery were
                mobilized in all municipalities. At the same time the
                municipalities at their own expense provide volunteers, engaged
                staff and senior citizens with protective equipment, fuel,
                vehicles and provide logistics.
                <div className="margin-top-10 margin-bottom-10">
                  <strong>CARING ABOUT THE MOST VULNERABLE</strong>
                </div>
              </span>
            ),
          },
        ],
      },
      {
        title: (
          <strong>Municipalities’ unimpeded service for the disabled. </strong>
        ),
        contents: [
          {
            text: (
              <span>
                The COVID-19 outbreak has impacted different groups in different
                ways, requiring that local governments handle the situation for
                each group in a different manner. This is especially valid if
                the group in question is people with disabilities who experience
                the world differently. To ensure the protection of the disabled,
                several municipalities in South-East Europe have been taking
                measures which are specifically targeting their special needs.
              </span>
            ),
          },
          {
            text: (
              <span>
                The Support Centre for Disabled Children and Their Families at{" "}
                <strong>Sarıyer</strong> Municipality (Turkey), has prepared a
                roadmap to support disabled children and their families in
                quarantine to spend quality time at home. Within the framework
                of the roadmap, families are provided with the psychological
                consultation by the experts who reach families in question
                through voice or video call. The centre is also streaming
                informative videos in its social media account.{" "}
                <strong>Saray</strong> Municipality, on the other hand, is
                delivering food to the disabled. <strong>Eyüpsultan</strong> and{" "}
                <strong>İzmit</strong> Municipalities have addressed the special
                need of hearing-impaired people who have difficulty in
                communicating due to facemasks. The municipality has started to
                produce a transparent facemask specifically designed for those
                people to communicate with their environment through lip
                reading. Some mayors have also been active in supporting the
                disabled in dealing with the situation. Alinur Aktaş, Mayor of
                Bursa Metropolitan Municipality, met the disabled people through
                online platforms and listened their problems and ensured them
                that the municipality would take care of their needs.
              </span>
            ),
          },
        ],
      },

      {
        title: <strong>Municipal Social Fund for COVID-19. </strong>,
        contents: [
          {
            text: (
              <span>
                The municipality of <strong>Gabrovo</strong> (Bulgaria), created
                a{" "}
                <strong>
                  Municipal Social Fund for COVID-19 to support individuals and
                  families experiencing difficulties
                </strong>{" "}
                as a result of the economic consequences of the pandemic. The
                need for the fund arose in the process of identifying people in
                difficulty who have not been part of the users of common social
                services. They needed a temporary support and stability, not
                constant care. The Fund is financed entirely by the budget of
                the municipality. The support is in the form of food vouchers.
                The value depends on the number of members of the household. The
                creation and operation of the fund was a joint work of the
                municipal administration and volunteers. Additional efforts
                include the creation of a <strong>Crisis Social Kitchen</strong>
                , establishment of a group of specials working in social
                services to respond to citizens requests and needs, coordinating
                with volunteers and delivering support packages with food and
                medicines. A largescale campaign was conducted to inform people
                about the types of support and assistance they can receive. In
                addition, the municipalities of{" "}
                <strong>Gabrovo, Burgas, Devnya, Hisarya, Dimitrovgrad</strong>{" "}
                in Bulgaria, have organized campaigns for raising money for
                purchasing food packages or in-kind donation for people with low
                income, elderly, lonely living or disabled citizens.
              </span>
            ),
          },
        ],
      },

      {
        title: (
          <strong>
            Stay at Home portal for helping the eldest, donations and
            volunteering launched in Belgrade.
          </strong>
        ),
        contents: [
          {
            text: (
              <span>
                The City of Belgrade has launched the "Stay at Home"{" "}
                <a href="https://ostanikodkuce.beograd.gov.rs" target="blank">
                  web-portal
                </a>{" "}
                as part of Belgrade Municipal Response the COVID-19 crisis.
                Through this portal, assistance is provided to citizens over the
                age of 65 and the most vulnerable citizens. In a few very simple
                steps, the oldest citizens can choose the municipality in which
                they live, contact the municipal crisis staff by telephone or
                fill out and send an electronic request for assistance, thus
                quickly and easily ordering the necessary supplies and
                medicines. After receiving the electronic request, volunteer
                teams will quickly take orders and make sure that the most
                vulnerable are delivered to their home addresses as soon as
                possible. At the same time, the web portal provides the
                opportunity for citizens to donate and volunteer.
              </span>
            ),
            image: require("../../assets/images/6/1.jpg"),
            float: "float-right",
          },
        ],
      },

      {
        title: <strong>Caught between the earthquake and the pandemic.</strong>,
        contents: [
          {
            text: (
              <span>
                The municipality of <strong>Tirana</strong> (Albania), has
                provided{" "}
                <strong>
                  support to 36.000 families caught between the earthquake and
                  the pandemic
                </strong>{" "}
                in Tirana with food, shelter and medicines through the network
                of the municipal social service employees and community
                volunteers.
              </span>
            ),
          },
        ],
      },

      {
        title: <strong>No rents for social housing.</strong>,
        contents: [
          {
            text: (
              <span>
                The municipality of <strong>Tirana</strong> (Albania) supported
                vulnerable groups by abrogating rent payments for tenants in
                social housing buildings. The decision was approved by the
                municipal council and a total of 385 families benefited from
                this initiative. At the same time, the municipality urged
                private landlords to do the same in favour of families and small
                businesses to help them overcome financial difficulties because
                of the COVID-19 situation. Similarly, <strong>Bursa</strong>{" "}
                Metropolitan Municipality (Turkey), exempted those aged 65 and
                above utilizing municipality’s holdings from the payment of the
                rental fee.
              </span>
            ),
            // image: require("../../assets/images/7/4.jpg"),
            // float: "float-right",
          },
        ],
      },

      {
        title: <strong>No utility bills for the most vulnerable.</strong>,
        contents: [
          {
            text: (
              <span>
                The Municipality of <strong>Mali Zvornik</strong> (Serbia)
                provided direct assistance and support to the most vulnerable in
                the context of the pandemic. The municipality settled the
                utility for garbage and water supply services and provided
                additional aid in terms food packages, gloves and protective
                masks for the most endangered citizens.
              </span>
            ),
          },
        ],
      },

      {
        title: (
          <strong>Support to the most vulnerable community members.</strong>
        ),
        contents: [
          {
            text: (
              <span>
                The Municipality of <strong>Shkodër</strong> (Albania) has
                created a database with all the families and persons who
                requested support from the municipality, identifying the
                different typologies of needs, challenges, risks etc., so that
                the most adequate support is provided to citizens in need. The
                assistance was provided in cooperation with public and
                non-public entities, including international, national and local
                NGOs, the Network of Community Centers and Social Care
                Institutions. The municipality has created dedicated telephone
                lines and call centers to engage with citizens. Shkoder has also
                been monitoring vulnerable or affected residents in areas
                endangered by the risk of other natural disasters such as
                landslides, floods, etc.
              </span>
            ),
            // image: require("../../assets/images/7/5.jpg"),
            // float: "float-right",
          },
          {
            text: (
              <span>
                The municipality of <strong>Roskovec</strong> (Albania), in
                close coordination with civil society and voluntary groups has
                assisted groups in severe economic distress with social and
                financial assistance. Besides persons with economic
                difficulties, support was provided also to persons that worked
                in the informal labor marked and that lost their job because of
                the pandemic. Since the onset of the COVID-19 pandemics,
                supporting the most vulnerable, in particular those in extreme
                poverty and the elderly has been at the core of the activities
                adopted by Albanian municipalities as a response to the crisis.
                It is reported that between March-June 2020, 233.248 families
                have been supported with food packages, medicines and other
                types of support.
              </span>
            ),
          },
          {
            text: (
              <span>
                The Municipality of <strong>Ferizaj</strong> (Kosovo) also has
                been providing support to the most vulnerable community members,
                with food and medical supplies, including clothing and hygienic
                materials. Efforts were coordinated with local businesses,
                volunteers and village leaders to identify families in need. In
                total, Ferizaj has provided support to about 20% of its
                population for about 100 days.
              </span>
            ),
          },
          {
            text: (
              <span>
                <strong>Sultanbeyli</strong> Municipality (Turkey), delivered
                hot meals to the elderly twice a day. The meals were cooked in
                the cafeteria of the municipality and then delivered to homes by
                responsible teams in accordance with the hygiene and health
                rules. Additionally, the municipality disinfects the homes of
                the elderly and meets their basic needs such as food and
                cleaning materials. Also, the support teams are making grocery
                and pharmacy shopping for them regularly.{" "}
                <strong>Kağıthane</strong> Municipality addresses the psychology
                of the older people at home and provides psychological support
                to those who are suffering from anxiety and boredom via phone.
              </span>
            ),
            // image: require("../../assets/images/7/6.jpg"),
            // float: "float-right",
          },
          {
            text: (
              <span>
                In most communes in <strong>Romania</strong>, vulnerable people
                received home care with the help of community nurses and health
                mediators. Most of the staff of the town halls volunteered to
                buy food and medicine for those who could not leave their homes
                during the state of emergency.
              </span>
            ),
            // image: require("../../assets/images/7/7.jpg"),
            // float: "float-right",
          },
        ],
      },

      {
        title: (
          <div>
            <strong>“Heart of Kotor” beats for the citizens of Kotor. </strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                Volunteering, tolerance, empathy, solidarity, a sense of
                fulfilment and satisfaction are just some of the characteristics
                that make volunteers stand out. They are the most important
                resource for implementing social change. Since the beginning of
                coronavirus crisis in mid of March, “Heart of Kotor” volunteers
                distributed 1000 packages to those in need, had 250 home visits
                and transported citizens 240 times to their desired location.{" "}
                <i>
                  "We help absolutely everyone who calls us. We are here to do
                  everything we can to make this easier to overcome. It's hard
                  for everyone, including us. Sometimes we would like to provide
                  more, better and faster ... In addition to the basic goal of
                  helping and transporting fellow citizens, we must not forget
                  that we must all try to stay healthy, and all of us together
                  have to bring this ship sailing for over a month now to a
                  peaceful port"
                </i>{" "}
                says one of the “Heart of Kotor” volunteers Ranko Borozan. With
                the work and engagement of volunteers more than 3000 citizens of
                Kotor have received help and support as of May 2020.
              </span>
            ),
            image: require("../../assets/images/6/2.jpg"),
            float: "float-right",
          },
        ],
      },

      {
        title: (
          <div>
            <strong>Fiscal relief measures for vulnerable groups.</strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                The municipality of <strong>Shkodër</strong> (Albania), adopted
                several fiscal relief measures, by both reducing or eliminating
                fiscal obligations for specific categories of taxpayers and by
                postponing the timelines for the payment of reduced local taxes
                and fees.
              </span>
            ),
          },
        ],
      },

      {
        title: (
          <div>
            <strong>Adopt a grandmother initiative.</strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                The municipality of <strong>Tirana</strong> (Albania), launched
                the <strong>Adopt a Grandmother initiative </strong>– with young
                people volunteering to supporting lonely elderly neighbors by
                sharing a home-cooked meal or providing them with food and
                medical supply.
              </span>
            ),
          },
        ],
      },

      {
        title: (
          <div>
            <strong>
              Solidarity in the times of crisis: Turkish municipalities say: “We
              are here”{" "}
            </strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                Municipalities across Turkey display solidarity with the public
                in several ways in order to alleviate the economic burden on
                people’s shoulder during the corona crisis. Some municipalities
                in Turkey have launched aid campaigns for people in need.{" "}
                <strong>Ankara Metropolitan Municipality</strong>, for instance,
                has created a “<strong>solidarity platform</strong>” which
                targets different vulnerabilities originating from different
                reasons. Within the framework of the platform, people aged 65
                and above have been informed on the markets which are available
                to their services, and people with a poor economic condition or
                those whose economic situation has been negatively influenced by
                the corona crisis will be provided with assistance in cash or in
                kind. On the other hand,{" "}
                <strong>Bursa Metropolitan Municipality</strong>, which has also{" "}
                <strong>
                  exempted those aged 65 and above utilizing municipality’s
                  holdings from the rental fee
                </strong>
                , launched a service called “Card16” in which approximately 130
                thousand households are provided in-kind assistance. Similarly,{" "}
                <strong>Osmangazi Municipality</strong> provided food assistance
                to those in need.
              </span>
            ),
          },
          {
            text: (
              <span>
                The municipality of <strong>Lovech</strong> (Bulgaria){" "}
                <strong>
                  cooperated with volunteer network to distribute food and
                  medicines or provide psychological support to elderly citizens
                </strong>{" "}
                that live alone and persons with chronical illnesses, including
                the vulnerable groups.
              </span>
            ),
          },
          {
            text: (
              <span>
                The Municipality of <strong>Shkodër</strong> (Albania), has
                provided{" "}
                <strong>
                  assistance to the elderly to cash in their monthly pensions in
                  safety
                </strong>
                , avoiding ques and travel to Social Insurance Institutions that
                distribute pensions. In order to avoid gathering of pensioners
                at one place in the post offices, where usually elderly people
                receive their pensions, the municipality of
                <strong> Smyadovo</strong> (Bulgaria) sought the help of
                Bulgarian posts EAD Shumen for organizing their delivery. The
                municipality is ready to organize the distribution of pensions
                to the homes of the elderly. The information Centre also
                prepared a specimen of an authorization to receive a pension
                that can be used. In the villages, mayors have this specimen and
                they will be responsible for organizing the delivery.
              </span>
            ),
          },
          {
            text: (
              <span>
                The Municipality of <strong>Sakarya</strong> (Turkey), engaged
                in securing and{" "}
                <strong>
                  delivering food and medical supplies to elderly citizens
                </strong>{" "}
                above 65 years old. The Elderly Support Centre (YADEM), of the
                Sakarya Metropolitan Municipality, worked to meet the needs of
                citizens over the age of 65 during curfew. Elderly citizens who
                needed regular needle therapy were treated. The municipality has
                also been distributing free books to encourage reading while
                staying at home. Books were delivered to thousands of families
                with the “Stay At Home with Book” campaign.
              </span>
            ),
          },
          {
            text: (
              <span>
                The municiapality of <strong>Kocevje</strong> (Slovenia), have
                provided emergency care for the elderly and other vulnerable
                groups with food and medicines. The Municipality of{" "}
                <strong>Logatec</strong> (Slovenia), has provided free meals and
                food delivery for vulnerable groups. The Municipality of{" "}
                <strong>Velenje</strong> (Slovenia), helped the elderly,
                disabled and other risk groups by providing them with a free
                food and medicine delivery service. A group of volunteers was
                responsible for delivering warm meals once a day, necessary
                medicine, and other goods from the store. People could also
                order fresh fruit and vegetables from local suppliers since the
                market had to be closed.
              </span>
            ),
          },
          {
            text: (
              <span>
                The municipality <strong>Selemeti</strong> (Moldova), in the
                framework of the project{" "}
                <strong> “Be Better for the saddest than us” </strong> financed
                by the international humanitarian organization "NORGE-MOLDOVA"
                and in partnership with the Local Public Authority provided
                support with food and hygiene products like gloves and
                disinfectants etc. to socially the vulnerable citizens. Young
                volunteers also supported the provision of{" "}
                <strong>
                  information and psychological support via telephone
                </strong>{" "}
                to Selemeti citizens.
              </span>
            ),
          },
          {
            text: (
              <span>
                After the distribution of more than 2,800 packages to pensioners
                whose pensions are below 30,000 dinars, volunteers from the
                Municipality of <strong>Žabalj</strong> (Serbia) prepared an
                additional 1,000 packages to be distributed to seniors who were
                not covered by the first package distribution. Čedomir Božić,
                the Mayor of Žabalj, emphasized that the assistance and support
                provided daily to the elderly, in addition to the introduced
                measures, is of great importance in the fight against the
                Coronavirus. According to Mr. Božić local government is there to
                pay attention to every individual!
              </span>
            ),
          },
        ],
      },

      {
        title: (
          <div>
            <strong>Helping Hand - Platform for Municipalities.</strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                The Association of Municipalities and Towns of Slovenia has
                collaborated with the Faculty of Electrical Engineering of the
                University of Ljubljana in preparation of a helpful portal for
                municipalities, named{" "}
                <a href="https://pomocnadlani.4pdih.com/" target="blank">
                  HELPING HAND
                </a>{" "}
                (sl. POMOČ NA DLANI). The web tool provides a free assistance to
                people in need, and is available to all citizens of all
                municipalities. The portal provides assistance in the field of
                food, pharmacies and childcare with people and organisations who
                offer this help for free. The working team has in this way
                partnered with municipalities and local civic initiatives to
                provide with a questionnaire as many as possible credible
                organizations and verified help providers in their area. The
                Platform is beneficial also for municipalities, because they
                receive an insight into the (geo)location of needs and therefore
                information about properly organized needs. The portal was
                developed as part of the 4PDIH digital innovation hub and is a
                result of a strategic partnership of both organisations. The
                project was funded by the Ministry of Public Administration and
                will be used to prepare measures and policies to fund the
                digitalisation activities in the municipalities.
              </span>
            ),
            image: require("../../assets/images/6/3.jpg"),
            float: "float-right",
          },
        ],
      },

      {
        title: (
          <div>
            <strong>Sewing protective masks for fellow citizens. </strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                Members of the women association{" "}
                <strong>Worthy Hands of Kovin</strong> from the Municipality of{" "}
                <strong>Kovin</strong> (Serbia) sew cotton masks to help their
                citizens protect themselves against the virus. The municipal
                headquarters provided and distributed all the necessary
                material. The masks have so far been delivered to health care
                workers, police officers and Red Cross volunteers. In their
                spare time, municipal women's associations shifted to making
                cotton masks in home workshops. Senior citizens have sewn 3,000
                masks so far, and another 5,000 are planned. Marija Stanković of
                the <strong>"Worthy Hands of Kovin"</strong> Women Association
                turned her kitchen space into a tailoring workshop. She has made
                about 500 masks so far.{" "}
                <i>
                  "I found myself in this business. I do not waste time, I sew
                  100 masks daily, and it is not difficult for me to know that
                  they are helpful to our fellow citizens"
                </i>{" "}
                said Stanković. Mirjana Drenča, president of the "Worthy Hands
                of Kovin", is very proud of her members.{" "}
                <i>
                  "Even in these years, nothing is difficult for us, because
                  this is a struggle of all our citizens. To save even one
                  person’s life will be our greatest success”
                </i>{" "}
                Drenča says, urging citizens to listen to the President of
                Serbia and to follow the prescribed measures. In sign of
                gratitude the President of the Municipality, Sanja Petrović
                visited the women of the association to personally acknowledge
                their efforts.{" "}
                <i>
                  “Today, together with the Kovin Tourist Organisation director,
                  Divna Kirilov, I visited almost all women from the
                  associations of women from the territory of the Municipality
                  of Kovin to thank them for their hard work and humanity that
                  they show through this wonderful action. I am especially
                  pleased that they are joyful while sewing masks because they
                  believe that they are helping to keep our population as
                  healthy as possible. They are safe in their homes, but at the
                  same time, they appeal to everyone else, to whom we will share
                  masks, to stay at home, and if they go out to wear masks,
                  which they have lovingly sewn. I thank them for a large number
                  of masks they sew daily, for the hours they spend on the
                  sewing machine, and we are there for them, for everything they
                  need"
                </i>{" "}
                said Sanja Petrović.
              </span>
            ),
            image: require("../../assets/images/6/4.jpg"),
            float: "float-right",
          },
        ],
      },

      {
        title: (
          <div>
            <strong>
              Volunteers sign up in support the efforts of municipalities in
              Bulgaria.
            </strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                In only less than 3 weeks into the pandemic, 3604 volunteers
                have enrolled in the campaign to support the efforts of the
                municipalities in responding to the COVID-19. Volunteers have
                expressed their willingness to provide funds, private cars and
                to support the food and medicine delivery to elderly and people
                in need. Along with the formations that operate in most
                municipalities and the volunteers that the Bulgarian Red Cross
                system works with, the data collected by NAMRB was integrated
                into a single information system that mayors have access to. The
                platform makes it clear which volunteer team is available at any
                given time in the municipality. This enables mayors who run
                crisis staffs locally to effectively plan where assistance is
                needed, including whether transfers to neighbouring
                municipalities might be necessary. In 186 municipalities in the
                country, telephone lines have been opened for food, medicine and
                essential goods and services requests by citizens. 233
                municipalities have volunteer units with 3140 registered and
                certified volunteers. The coordination mechanism, established on
                29 March at a meeting between Deputy Prime Minister and NAMRB,
                will allow, if necessary, a volunteer resource to be directed to
                settlements in need of assistance. The Volunteer Corps will be
                used also for assistance in hospitals, both for non-medical
                activities and for supporting medical teams (for volunteers with
                relevant education and training). Volunteers will support the
                activities of the National Crisis HQ, the bodies and structures
                of the Ministry of Health for conduction control on people under
                quarantine. They will also assist in other municipal activities
                such as disinfection, food and medicines supply for the elderly,
                single living people and people with disabilities etc.
              </span>
            ),
            // image: require("../../assets/images/6/3.jpg"),
            // float: "float-right",
          },
        ],
      },

      {
        title: (
          <div>
            <strong>Tivat volunteers provide support to citizens.</strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                The Municipality of Tivat (Montenegro) formed the first
                volunteer team as well as the Call Center to support at-risk
                population groups on March 20. The volunteer team consists of
                local government employees and members of the Tivat Red Cross
                municipal organization. Tivat Municipality Volunteer Team has
                assisted and facilitated the municipal support to its citizens.{" "}
                <i>
                  "The action is aimed at keeping the people at high risk of
                  coronavirus in their homes and getting food in an efficient
                  and fast way,"
                </i>{" "}
                said the team coordinator Dubravka Koparan. Persons over the age
                of 65, chronic patients, impaired persons, pregnant women, and
                other at-risk categories are entitled to food delivery through
                the Call Center service. The call center is available 24 hours
                while volunteers are on the ground daily from 9am to 7pm.
                According to Ms. Koparan, the Call Center receives various
                calls, from service calls to requests for humanitarian
                assistance, calls for services of purchase and delivery of
                groceries and medicines, payment of bills, etc. as well as calls
                for psychosocial support.
              </span>
            ),
            image: require("../../assets/images/6/5.jpg"),
            float: "float-right",
          },
        ],
      },

      {
        title: (
          <div>
            <strong>Emergency button for the elderly. </strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                The elderly is more at risk from the COVID-19, prompting
                governments to take active preventive measures for the elderly,
                including isolation in their homes. Local governments have
                devised various measures to help the elderly at home to meet
                their different needs. <strong>Beşiktaş</strong> Municipality
                (Turkey), for instance, has provided a device on which an{" "}
                <i>“emergency button”</i> is placed. The device allows its 75+
                residents to receive direct and urgent physical and health
                support after they press it. Likewise,{" "}
                <strong>Sancaktepe</strong> Municipality (Turkey) supplied older
                people with the similar emergency device to support them in
                meeting their needs.
              </span>
            ),
            image: require("../../assets/images/6/6.jpg"),
            float: "float-right",
          },
        ],
      },

      {
        title: (
          <div className="margin-bottom-10">
            <strong>CARING ABOUT CHILDREN</strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                The coronavirus crisis creates a challenge for many people, and
                in particular for children. Confining regulations have had a
                major impact on children’s lives. As a result, children were
                required to spend their whole day at home, which has not been
                very easy or even acceptable for the young generations. To help
                children enjoy their times at home and keep them engaged, the
                municipal authorities in South-East Europe have adopted certain
                practices.
              </span>
            ),
          },
        ],
      },

      {
        title: (
          <div>
            <strong>We read together.</strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                In the context of the coronavirus epidemic, and the schools
                being closed <strong>Berceni commune Town Hall</strong>,
                (Romania), meets the need of children through the program: “We
                read together!” where the employees of the Culture / Library
                Department read stories and interact with the "listeners" in the
                online environment, provoking them with questions and contests.
                More information can be found{" "}
                <a
                  href="https://m.facebook.com/primariaberceniph/"
                  target="blank"
                >
                  here
                </a>
                . Similarly, the Municipality of Tirana (Albania) launched an
                initiative where artists read books to children on social media.
              </span>
            ),
          },
        ],
      },

      {
        title: (
          <div>
            <strong>
              The first on-line regional festival for children theatre - PIR
              FEST in Montenegro.{" "}
            </strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                Podgorica City Theater presented the First On-line Regional
                Festival of Children’s Theater - PIR FEST from 8 to 16 April
                2020. During the festival, the youngest audience had the
                opportunity to watch 9 regional theatres on the YouTube channel
                of the Podgorica City Theater, starting at 5pm. The Podgorica
                City Theater has initiated, and most of the regional children's
                theaters accepted, a new action that allows children to attend
                theater performances during isolation. The festival is of a
                competitive character, the performances will be valued by the
                votes of the audience, the so-called likes. Podgorica City
                Theater participates with "The Wizard of Oz"; Bosko Buha Theater
                from Belgrade with “The Little Mermaid; Rijeka City Puppet
                Theater with "Adventures of Little Juju"; Novi Sad Youth Theater
                will show "Beauty and the Beast"; Sarajevo Youth Theater
                "Kekec"; Subotica Children's Theater "A pair of shoes"; Little
                Theater "Dusko Radovic" from Belgrade "Scary Stories of the
                Grimm Brothers"; Pinokio Theater "The Jungle Book"; and Kotor
                Children's Theater Festival with the project "Care for Children:
                How Big People Grow”.
              </span>
            ),
            image: require("../../assets/images/6/7.jpg"),
            float: "float-right",
          },
        ],
      },

      {
        title: (
          <div>
            <strong>Fairytale Time. </strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                <strong>Maltepe Municipality</strong> (Turkey), launched
                “Fairytale Time” broadcast on the YouTube channel of the Mayor
                Ali Kılıç. Upon the same channel, a child movie titled “The
                Talpidaes: Hounted Forest” has been shown.{" "}
                <strong>Beşiktaş Municipality</strong> (Turkey), likewise
                broadcasts programmes for children on the municipality’s YouTube
                channel. <strong>Sarıyer</strong> Municipality (Turkey), has
                been carrying its activities organized within the framework of
                “Sarıyer 95 Project” to the online platform. It continues to
                provide voluntary consultancy services with the parents to
                inform them how to reorganize physical spaces at home in a way
                that allows little children to play in there; and share photos
                coming from those parents on the project’s Instagram page titled
                “sariyer95_projesi”. <strong>Beyoğlu Municipality</strong>,
                (Turkey), on the other hand, organized a series of online
                contests with a motto of{" "}
                <strong>
                  {" "}
                  “Stay Away from Outside, Not from Competition”{" "}
                </strong>
                . These contests focus on the field of poetry, painting, and
                design. Apart from these activities, several mayors gave special
                attention to children. Mehmet Murat Çalık, the Mayor of{" "}
                <strong>Beylikdüzü</strong> Municipality (Turkey), made a video
                chat with the autistic children and ensured them that their
                education at the Beylikdüzü Municipality Unimpeded Life Center
                will continue when the corona crisis will end.
              </span>
            ),
            image: require("../../assets/images/6/8.jpg"),
            float: "float-right",
          },
        ],
      },

      {
        title: (
          <div>
            <strong>Childcare for front-liners’ children. </strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                The municipalities of <strong>Kocevje and Logatec</strong>{" "}
                (Slovenia), has provided an opportunity for{" "}
                <strong>
                  childcare to children with parents working in critical sectors
                  as regards the COVID-19 response
                </strong>
                , such as medical sector, police, army and other crucial fields.
                Similarly, the Municipality of <strong>Velenje</strong>{" "}
                (Slovenia), provided childcare for every family in need. Parents
                that justifiably could not use leave from their workplace and
                therefore could not provide care for their children, announced
                the need of home care assistance to the information centre and
                the municipality tried to provide them childcare provider
                through the system of protection and rescue of the municipality.
              </span>
            ),
          },
        ],
      },

      {
        title: (
          <div>
            <strong>Psychological support for the youngest of all. </strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                The COVID-19 pandemics highlighted the importance of the
                psychologist's service in preschool education. Before the
                COVID-19 pandemic, a survey prepared by the{" "}
                <a href="http://bashkiteforta.al/" target="blank">
                  Bashki të Forta
                </a>{" "}
                (BtF) project showed that there were only 23 municipalities that
                provided psychological-social service for preschool children in
                Albania. Thanks to a successful engagement between the Local
                Offices of Pre-University Education and the Preschool Education
                Departments, a series of webinars and video conferences were
                organized to provide service to citizens. Experts in the field
                emphasized the need to form a sustainable triangle scheme
                (parent, kindergarten teacher, psychologist). BtF supported
                these activities and the successful example in the city of
                Durrës, for a referral platform for parents gave rise to other
                municipalities to develop information activities and provide
                services.
              </span>
            ),
          },
        ],
      },

      {
        title: (
          <div className="margin-bottom-10">
            <strong>CARING ABOUT EACH OTHER</strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                The economic situation of many has been severely impacted by the
                COVID-19 outbreak. In these difficult times, some people have
                lost their jobs, seen their income decrease dramatically whereas
                some others have been forced to take extended unpaid leaves. As
                a result, many people started to have difficulty in meeting
                their needs. The situation has become even worse for those who
                had already been suffering from economic difficulties. In order
                to alleviate the economic burden over the shoulder of those in
                need, several municipalities in Marmara Region are establishing
                varying solidarity channels through which the well-off residents
                help people in need to meet their economic needs.
              </span>
            ),
          },
        ],
      },

      {
        title: (
          <div className="margin-bottom-10">
            <strong>
              Municipalities in Marmara Region are Building Solidarity Channels
              Among Their Residents
            </strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                The{" "}
                <strong>
                  Metropolitan Municipality of Istanbul (Turkey) introduced the
                  Askıda Fatura ‘Pay-It-Forward’ campaign, that aims at
                  supporting disadvantaged neighbors and citizens who are not
                  able to pay their utlity bills.{" "}
                </strong>{" "}
                With a spirity of solidarity and community mobilisation,
                citizens were able to help their fellow neighbours by paying
                their bills anonymously through a digital platform. The
                initiative borrows its name from a longstanding Turkish
                tradition that dates back centuries, whereby a person would go
                to a bakery and pay for two loaves of bread instead of one,
                telling the baker that the other loaf will be “on the hook” for
                another person in need. who might be unable to afford bread –
                hence the name paying the favor forward. The Askıda Fatura
                ‘Pay-It-Forward’ campaign provided the citizens of Istanbul with
                an opportunity to help their fellow neighbours burdened with
                utility bills during the COVID-19 crisis. The campaign helped
                22,000 families in Istanbul to have their outstanding bills paid
                in under 10 hours, using an anonymous payment algorithm on the
                IMM website. Donations received as of July 9th total 175.519
                invoices valued at 3.086.066 Euros. The campaign is being
                expanded to include also monthly support to mothers for the
                hygienic needs of their babies.
              </span>
            ),
            image: require("../../assets/images/6/9.jpg"),
            float: "float-right",
          },
          {
            text: (
              <span>
                <strong>Solidarity Hook: Beylikdüzü and Beşiktaş</strong>{" "}
                Municipalities initiated this campaign with the aim of meeting
                the grocery needs of those with financial burdens. The campaign
                works in both municipalities as the follows: People make grocery
                shopping through online platforms and send products to the
                municipalities. Then, the municipalities deliver products to
                people in need.
              </span>
            ),
          },
          {
            text: (
              <span>
                <strong>Food on the Hook: Izmit</strong> Municipality started
                the campaign where the food expense of the needy is targeted.
                Within the framework of the campaign, the municipality makes an
                agreement with the markets which accept to have a “hook” where
                the extra products bought by well-off citizens are put for the
                needy. The foods on the hook in the markets are then collected
                by the municipal officials in order to be delivered to people in
                need.
              </span>
            ),
          },
          {
            text: (
              <span>
                <strong>Food Bank: Silivri</strong> Municipality is collecting
                food through the Food Banks that they established and then
                delivers those foods to the needy.
              </span>
            ),
          },
        ],
      },

      {
        title: (
          <div>
            <strong>Plovdiv – City of Goodness.</strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                “Plovdiv – City of Goodness” is a continuation of the activity
                "The most decent person". The mayor Zdravko Dimitrov stressed
                that there is a need to talk about the goodness and examples of
                people with big hearts should be shown, distinguished and
                thanked.{" "}
                <i>
                  “The idea of the campaign is to thank those who completely
                  unselfishly lend a hand to a person in need or are making
                  efforts for the development of society. There's nothing like
                  helping a person in distress and that came out especially
                  important in the year of the COVID 19 pandemic”, he said.
                  “Many donors and volunteers have supported the municipality in
                  the sphere of healthcare or social services, proving that in
                  difficult times we can be good and responsive”,
                </i>{" "}
                added the mayor. He has called on all citizens of Plovdiv to
                nominate and distinguish modern examples of kindness. Plovdiv
                citizens could make their nominations in four categories:
                educational activity, social activity, urban development and
                good deeds. The jury consisted of nine specialists with
                experience in one of the four nomination categories. The award
                ceremony took place on 27 June in the Tsar Simeon's Garden.
              </span>
            ),
            // image: require("../../assets/images/6/8.jpg"),
            // float: "float-right",
          },
        ],
      },

      {
        title: (
          <div>
            <strong>Equality Starts from Home. </strong>
          </div>
        ),
        contents: [
          {
            text: (
              <span>
                COVID-19 deepens the existing inequalities between men and women
                as the latter are more vulnerable to the multi-faceted impacts
                of the COVID-19. One manifestation of this situation is that
                women in some households are the sole responsible for the house
                works while they have to work from home. In order to empower
                women in these circumstances, several municipalities in Turkey
                have been carrying out specific activities. As one example of
                such attempts, <strong>Beşiktaş Municipality</strong> has
                launched a social media campaign titled “Equality Starts from
                Home”. Rıza Akpolat, the Mayor of Beşiktaş Municipality, shared
                images on gender equality with the tag “EşitlikEvdeBaşlar”
                (Equality Starts from Home) and stated that there are three
                unwritten rules in the quarantine times which are as the
                followings:
                <ul>
                  <li> No women’s work, no men’s work; just housework</li>
                  <li> Everyone can manage to clean the house</li>
                  <li>
                    Taking care of children is a common task of both parents
                  </li>
                </ul>
              </span>
            ),
            image: require("../../assets/images/6/10.jpg"),
            float: "float-right",
          },
        ],
      },
    ],
  },
};
