import React, { useLayoutEffect } from "react";
import "../../assets/css/infographics.css";
import "../../assets/css/rdo.css";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "../../assets/css/swm.css";
import "../../assets/css/charts.css";

am4core.useTheme(am4themes_animated);

const RadarChart = ({ chartData, id, type, title }) => {
  useLayoutEffect(() => {
    const themeColor = am4core.color("#6A85A5");

    const chart = am4core.create(`${type}${id}`, am4charts.RadarChart);

    chart.cursor = new am4charts.RadarCursor();
    chart.cursor.behavior = "none";
    chart.cursor.lineY.disabled = true;
    chart.cursor.fullWidthLineX = true;
    // chart.cursor.lineX.strokeWidth = 0;
    // chart.cursor.lineX.fillOpacity = 0.5;
    chart.zoomOutButton.disabled = true;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.min = 1;
    valueAxis.max = 10;
    valueAxis.renderer.gridType = "polygons";

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.layout = "none";
    categoryAxis.tooltip = false;
    categoryAxis.renderer.gridType = "polygons";

    chart.data = chartData.dataset_composed.dataset;

    let series = chart.series.push(new am4charts.RadarSeries());
    series.dataFields.categoryX = "category";
    series.dataFields.valueY = "value";
    series.tooltipText = `{category}: [bold]{value}[/]`;
    series.stroke = themeColor;
    series.fill = themeColor;
    series.name = "Decentralization Progress";
    series.strokeWidth = 2;
    series.strokeOpacity = 0.95;
    series.fillOpacity = 0;

    let circleBullet = series.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.stroke = am4core.color("#6A85A5");
    circleBullet.circle.strokeWidth = 2;
    series.minBulletDistance = 18;

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = title.replace(/\s/g, "");

    const exportFields = { ...chart.exporting.dataFields };
    delete exportFields.parent;
    chart.exporting.dataFields = exportFields;

    return () => {
      chart.dispose();
    };
  }, [chartData, id, type, title]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <h5 className="header-with-after-line">{title}</h5>
        </div>
      </div>
      <div id={type + id} className="rdo-graph"></div>
    </>
  );
};

export default RadarChart;
