import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const LineAndStackedChart = ({ id, type, title, chartData }) => {
  const chart = useRef(null);
  const seriesData = chartData.dataset_composed.properties;

  useLayoutEffect(() => {
    let chart = am4core.create(`${type}${id}`, am4charts.XYChart);
    chart.data = chartData.dataset_composed.dataset;
    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.fontSize = 12;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.start = 0.5;
    categoryAxis.keepSelection = true;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.fontSize = 12;

    // Create series
    function createSeries(field, name) {
      if (field == "total") {
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "total";
        series.dataFields.categoryX = "year";
        series.name = "Total";
        series.stroke = am4core.color("#ff0000"); // red
        series.fill = am4core.color("#ff0000");
        series.strokeWidth = 2;
        series.tensionX = 0.7;
        series.yAxis = valueAxis;

        let bullet3 = series.bullets.push(new am4charts.CircleBullet());
        bullet3.circle.radius = 3;
        bullet3.circle.strokeWidth = 2;
        bullet3.circle.fill = am4core.color("#fff");

        bullet3.circle.tooltipText =
          "{name}\n[bold font-size: 20]{valueY}{unit}[/]";
        bullet3.circle.showTooltipOn = "hit";
        return series;
      } else {
        // Set up series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "year";
        series.sequencedInterpolation = true;

        // Make it stacked
        series.stacked = true;

        // Configure columns
        series.columns.template.width = am4core.percent(90);
        series.columns.template.tooltipText =
          "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY} {unit}";

        // Add label
        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        const unitCheck =
          chartData.dataset_composed.dataset[0].unit_as_part_of_label;
        unitCheck === 1
          ? (labelBullet.label.text = "{valueY}{unit}")
          : (labelBullet.label.text = "{valueY}");
        labelBullet.locationY = 0.5;
        labelBullet.fontSize = 12;
        labelBullet.label.hideOversized = true;

        return series;
      }
    }

    Object.entries(seriesData).forEach((field) => {
      createSeries(field[0], field[1]);
    });

    // Legend
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fontSize = 12;

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = title.replace(/\s/g, "");

    const exportFields = { ...chart.exporting.dataFields };
    delete exportFields.color;
    delete exportFields.unit_as_part_of_label;
    delete exportFields.partOfChart;
    chart.exporting.dataFields = exportFields;
    
    return () => {
      chart.dispose();
    };
  }, [id, type, title, chartData]);

  return <div id={type + id} style={{ width: "100%", height: "500px" }}></div>;
};
export default LineAndStackedChart;
