import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import "../assets/css/footer.css";

// Footer component
function Footer() {
  return (
    <footer>
      <div className="section-sm bg-dark">
        <div className="container">
          <div className="row col-spacing-20">
            <div className="col-6 col-sm-6 col-lg-3">
              <img src={logo} />
            </div>
            <div className="col-6 col-sm-6 col-lg-3">
              <ul className="footer-list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/decentralisation-progress">
                    Decentralization progress
                  </Link>
                </li>
                <li>
                  <Link to="/data/local-government-finance-indicators">
                    Local government finance
                  </Link>
                </li>
                <li>
                  <Link to="/data/swm-indicators/swm">Waste management</Link>
                </li>
              </ul>
            </div>
            <div className="col-6 col-sm-6 col-lg-3">
              <ul className="footer-list">
                <li>
                  <Link to="/learning">Learning</Link>
                </li>
                <li>
                  <Link to="/self-assessment">Self Assessment</Link>
                </li>
                {/* <li>
                  <Link to="/useful-links">Useful links</Link>
                </li> */}
                <li>
                  <Link to="/about">About us</Link>
                </li>
              </ul>
            </div>
            <div className="col-6 col-sm-6 col-lg-3">
              <ul className="list-unstyled">
                <li>
                  Varshavska 36 A, 1000 Skopje, Republic of North Macedonia
                </li>
                <li>info@nalas-observatory.eu</li>
                <li>+389 23090818</li>
              </ul>
            </div>
          </div>

          <div className="row col-spacing-10">
            <div className="col-md-5 float-left footer-partners">
              <div className="supportoff">Developed by: </div>
              <img
                alt="logo"
                style={{ height: "80px" }}
                src={require("../assets/images/footer/nalas.svg")}
              />
              <img alt="logo" src={require("../assets/images/footer/1.png")} />
              <img alt="logo" src={require("../assets/images/footer/2.png")} />
            </div>
            <div className="col-md-4 float-left footer-partners">
              <div className="supportoff">With funding from: </div>
              <img
                alt="logo"
                src={require("../assets/images/footer/austrian-dev-coop.jpg")}
                style={{ marginTop: "10px", height: "80px" }}
              />
            </div>
            <div className="col-12 text-center copyright">
              <p>&copy; Copyright NALAS 2021</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
