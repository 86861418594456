import React, { useState, useEffect } from 'react'

// Counter Function with animations
const Counter = props => {

    const [countNr, setCountNr] = useState({
        nr: props.number,
        start: 0
    })

    let cnr = countNr.nr
    let snr = countNr.start
    function counterNumber() {
        const timer = setInterval(function () {
            if (cnr >= 0) {
                snr++
            }

            setCountNr({
                start: snr,
            })

            if (snr === cnr) {
                clearInterval(timer);
            }

        }, 10);
    }

    useEffect(() => {
        counterNumber()
    }, [])


    return (
        <>
            {countNr.start}
        </>
    )
}

export default Counter