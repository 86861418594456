const requests = {
  fetchNationalData: `/api/general-data/chart?type={type}`,
  fetchNationalList: `/api/data-visualization/local-finance-indicators/countries?type=national_use`,
  fetchNationalListNoChild: `/api/data-visualization/local-finance-indicators/countries?type=national_use&exclude_child_countries=true`,
  fetchAllNationalList: `/api/data-visualization/local-finance-indicators/countries?type=all`,
  fetchYears: `/api/data-visualization/local-finance-indicators/years`,
  fetchIndicators: `/api/data-visualization/local-finance-indicators/indicators`,
  fetchUnits: `/api/data-visualization/local-finance-indicators/units`,
  fetchCountryDefaultData: `/api/data-visualization/local-finance-indicators/country/default-data?country_id=`,
  generateChart: `/api/data-visualization/local-finance-indicators/generate-chart`,
  generateUnitChart: `/api/data-visualization/local-finance-indicators/generate-chart-unit`,
  fetchRegionalDefaultData: `/api/data-visualization/local-finance-indicators/regional/default-data`,
  generateRegionalTabs: `/api/data-visualization/local-finance-indicators/regional/generate-charts`,
  getCircularEconomyData: `/api/data-visualization/swm-indicators/circular-economy/generate-chart`,
  getSolidWasteManagementData: `/api/data-visualization/swm-indicators/swm/generate-chart`,
  getDigestItemData: `/api/web/digest`,
  getLegalFrameworks: `/api/web/legal-frameworks`,
  getLegalFrameworksCountry: `/api/web/legal-frameworks/country`,
  getRDOData: `/api/data-visualization/rdo-indicators/generate-chart`,
};

export default requests;
