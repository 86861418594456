import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldHigh from "@amcharts/amcharts4-geodata/worldHigh";
import "../../../assets/css/charts.css";

const MapChart = ({ id, type, title, chartData }) => {
  const newData = chartData.dataset_composed.dataset.map((x) => ({
    id: x.country_code,
    country: x.country,
    value: x.value,
    unit: x.unit,
  }));

  const seriesData = chartData.dataset_composed.properties;
  const chart = useRef(null);
  useLayoutEffect(() => {
    let chart = am4core.create(`${type}${id}`, am4maps.MapChart);

    // Set map definition
    chart.geodata = am4geodata_worldHigh;

    // Set projection
    chart.projection = new am4maps.projections.Miller();

    // Series for World map
    var worldSeries = chart.series.push(new am4maps.MapPolygonSeries());
    worldSeries.include = [
      "AL",
      "BA",
      // 'BA-BRC',
      // 'BA-SRP',
      // 'BA-BIH',
      "BG",
      "HR",
      "MK",
      "MD",
      "ME",
      "XK",
      "RO",
      "RS",
      "SI",
      "TR",
    ];

    worldSeries.data = newData;
    worldSeries.useGeodata = true;

    function createSeries(field, name) {
      var polygonTemplate = worldSeries.mapPolygons.template;
      polygonTemplate.tooltipText = "{name}: {value}{unit}";
      polygonTemplate.fill = chart.colors.getIndex(0);
      polygonTemplate.nonScalingStroke = true;

      // Hover state
      var hs = polygonTemplate.states.create("hover");
      hs.properties.fill = am4core.color("#fee900");

      return polygonTemplate;
    }

    Object.entries(seriesData).forEach((field) => {
      createSeries(field[0], field[1]);
    });

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = title.replace(/\s/g, "");

    const exportFields = { ...chart.exporting.dataFields };
    delete exportFields.color;
    delete exportFields.partOfChart;
    delete exportFields.unit_as_part_of_label;
    chart.exporting.dataFields = exportFields;

    return () => {
      chart.dispose();
    };
  }, [id, type, title, chartData]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <h5 className="header-with-after-line">{title}</h5>
        </div>
      </div>
      <div id={type + id} style={{ width: "100%", height: "500px" }}></div>
    </>
  );
};
export default MapChart;
