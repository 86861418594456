import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/publications.css";

// Publication List page
const PublicationList = ({ posts, loading, filtered }) => {
  if (loading) {
    return <h2>Loading...</h2>;
  }
  function shorten(text, max) {
    return text && text.length > max
      ? text.slice(0, max).split(" ").slice(0, -1).join(" ")
      : text;
  }

  return (
    <>
      {posts.map((post) => (
        <div key={post.id} className="margin-bottom-50">
          <div className="row">
            <div className="col-md-4">
              <div
                className="publication-img"
                style={{ backgroundImage: `url(${post.preview_image})` }}
              ></div>
            </div>
            <div className="col-md-8">
              <div className="publications-entry">
                <p className="time-created">{post.created_at}</p>
                <h5>
                  <Link to={`publications/${post.id}`}>{shorten(post.title, 120)+' ...'}</Link>
                </h5>
                <p>{shorten(post.description, 80) + ' ...'}</p>
                <Link to={`publications/${post.id}`} className="readmore">Read More</Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default PublicationList;
