import React from "react";
import "../assets/css/counter.css";
import Counter from "./Counter";

// Counter Section homepage
const CounterSection = () => {
  return (
    <>
      <div className="section-lg counter-cont">
        <div className="container text-center">
          <div className="row">
            <div className="col-12 col-md-3">
              <h3 className="counter-numbers">
                <Counter number={3} />
              </h3>
              <h6 className="">Types of Data</h6>
            </div>
            <div className="col-12 col-md-3">
              <h3 className="">
                <Counter number={250} />
              </h3>
              <h6 className="">Indicators</h6>
            </div>
            <div className="col-12 col-md-3">
              <h3 className="">
                <Counter number={60} />
              </h3>
              <h6 className="">Types of Visualizations</h6>
            </div>
            <div className="col-12 col-md-3">
              <h3 className="">
                <Counter number={14} />
              </h3>
              <h6 className="">Entities</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CounterSection;
