import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import NationalMap from "./NationalMap";
import RegionalMap from "./RegionalMap";
import "../../assets/css/lgfi.css";

const LGFI = () => {
  return (
    <>
      <Helmet>
        <title>Local Government Finance Indicators - NALAS Observatory</title>
      </Helmet>
      <div className="section lgfi mapbg">
        <div className="container-fluid">
          <div className="row">
            <h2 className="nalas-title">Local Government Finance Indicators</h2>
          </div>
        </div>

        <div className="section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 lgfi-map">
                <Link to="/data/lgfi/regional-data-visualization">
                  <h5 className="nalas-subtitle">
                    Regional Data Comparison and Visualisation
                  </h5>
                </Link>
                <Link to="/data/lgfi/regional-data-visualization">
                  <div>
                    <RegionalMap />
                  </div>
                </Link>
              </div>
              <div className="col-lg-6 lgfi-map">
                <Link to="/data/lgfi/national-data-visualization-list">
                  <h5 className="nalas-subtitle">
                    National Data Visualization
                  </h5>
                </Link>
                <div>
                  <NationalMap />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LGFI;
