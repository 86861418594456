import React, { useLayoutEffect } from "react";
import "../../assets/css/infographics.css";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "../../assets/css/swm.css";
import "../../assets/css/charts.css";

am4core.useTheme(am4themes_animated);

const RadarTimelineChart = ({ chartData, id, type, title }) => {
  useLayoutEffect(() => {
    const dataSet = chartData.dataset_composed.dataset;

    var colorSet = new am4core.ColorSet();

    var chart = am4core.create(`${type}${id}`, am4charts.RadarChart);
    chart.hiddenState.properties.opacity = 0;

    chart.startAngle = 270 - 180;
    chart.endAngle = 270 + 180;

    chart.padding(5, 15, 5, 10);
    chart.radius = am4core.percent(65);
    chart.innerRadius = am4core.percent(40);

    // zoomout button
    var zoomOutButton = chart.zoomOutButton;
    zoomOutButton.dx = 0;
    zoomOutButton.dy = 0;
    zoomOutButton.marginBottom = 15;
    zoomOutButton.parent = chart.rightAxesContainer;

    // scrollbar
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.parent = chart.rightAxesContainer;
    chart.scrollbarX.orientation = "vertical";
    chart.scrollbarX.align = "center";
    chart.scrollbarX.exportable = false;

    // vertical orientation for zoom out button and scrollbar to be positioned properly
    chart.rightAxesContainer.layout = "vertical";
    chart.rightAxesContainer.padding(120, 20, 120, 20);

    // category axis
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "category";

    var categoryAxisRenderer = categoryAxis.renderer;
    var categoryAxisLabel = categoryAxisRenderer.labels.template;
    categoryAxisLabel.location = 0.5;
    categoryAxisLabel.radius = 28;
    categoryAxisLabel.relativeRotation = 90;
    categoryAxisLabel.wrap = true;
    categoryAxisLabel.maxWidth = 100;

    categoryAxisRenderer.fontSize = 11;
    categoryAxisRenderer.minGridDistance = 10;
    categoryAxisRenderer.grid.template.radius = -25;
    categoryAxisRenderer.grid.template.strokeOpacity = 0.05;
    categoryAxisRenderer.grid.template.interactionsEnabled = false;

    categoryAxisRenderer.ticks.template.disabled = true;
    categoryAxisRenderer.axisFills.template.disabled = true;
    categoryAxisRenderer.line.disabled = true;

    categoryAxisRenderer.tooltipLocation = 0.5;
    categoryAxis.tooltip.defaultState.properties.opacity = 0;

    // value axis
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 1;
    valueAxis.max = 10;
    // valueAxis.strictMinMax = true;
    valueAxis.tooltip.defaultState.properties.opacity = 0;
    valueAxis.tooltip.animationDuration = 0;
    valueAxis.cursorTooltipEnabled = true;
    valueAxis.zIndex = 10;

    var valueAxisRenderer = valueAxis.renderer;
    valueAxisRenderer.axisFills.template.disabled = true;
    valueAxisRenderer.ticks.template.disabled = true;
    valueAxisRenderer.minGridDistance = 20;
    valueAxisRenderer.grid.template.strokeOpacity = 0.05;

    // series
    var series = chart.series.push(new am4charts.RadarColumnSeries());
    series.columns.template.width = am4core.percent(90);
    series.columns.template.strokeOpacity = 0;
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "category";
    series.tooltipText = "{categoryX}:{valueY.value}";

    // this makes columns to be of a different color, depending on value
    series.heatRules.push({
      target: series.columns.template,
      property: "fill",
      minValue: -3,
      maxValue: 6,
      min: am4core.color("#673AB7"),
      max: am4core.color("#F44336"),
      dataField: "valueY",
    });

    // cursor
    var cursor = new am4charts.RadarCursor();
    chart.cursor = cursor;
    cursor.behavior = "zoomX";

    cursor.xAxis = categoryAxis;
    cursor.innerRadius = am4core.percent(40);
    cursor.lineY.disabled = true;

    cursor.lineX.fillOpacity = 0.2;
    cursor.lineX.fill = am4core.color("#000000");
    cursor.lineX.strokeOpacity = 0;
    cursor.fullWidthLineX = true;

    var sliderContainer = chart.createChild(am4core.Container);
    sliderContainer.layout = "vertical";
    // sliderContainer.padding(0, 38, 0, 38);
    sliderContainer.width = am4core.percent(100);

    chart.data = generateRadarData();

    function generateRadarData() {
      var chartData = [];

      let j = 0;
      let oldParent = null;
      let parent = null;
      let categoryData = [];

      for (let i = 0; i < dataSet.length; i++) {
        if (dataSet[i].parent) {
          oldParent = parent;
          parent = dataSet[i];
        }

        const rawDataItem = { ...dataSet[i] };
        delete rawDataItem.parent;
        if (!dataSet[i].parent) {
          categoryData.push(rawDataItem);
          chartData.push(rawDataItem);
        }

        if ((dataSet[i].parent && i !== 0) || i === dataSet.length - 1) {
          createRange(
            i === dataSet.length - 1 ? parent.category : oldParent.category,
            i === dataSet.length - 1 ? parent.value : oldParent.value,
            categoryData,
            j
          );
          j++;
          categoryData = [];
        }
      }
      return chartData;
    }

    function createRange(name, value, categoryData, index) {
      var axisRange = categoryAxis.axisRanges.create();
      axisRange.axisFill.interactionsEnabled = true;
      axisRange.text = `${name} : ${value}`;
      // first country
      axisRange.category = categoryData[0].category;
      // last country
      axisRange.endCategory = categoryData[categoryData.length - 1].category;

      // every 3rd color for a bigger contrast
      axisRange.axisFill.fill = colorSet.getIndex(index * 3);
      axisRange.grid.disabled = true;
      axisRange.label.interactionsEnabled = false;
      axisRange.label.bent = true;

      var axisFill = axisRange.axisFill;
      axisFill.innerRadius = -0.001; // almost the same as 100%, we set it in pixels as later we animate this property to some pixel value
      axisFill.radius = -20; // negative radius means it is calculated from max radius
      axisFill.disabled = false; // as regular fills are disabled, we need to enable this one
      axisFill.fillOpacity = 1;
      axisFill.togglable = true;

      axisFill.showSystemTooltip = true;
      axisFill.readerTitle = "click to zoom";
      axisFill.cursorOverStyle = am4core.MouseCursorStyle.pointer;

      axisFill.events.on("hit", function (event) {
        var dataItem = event.target.dataItem;
        if (!event.target.isActive) {
          categoryAxis.zoom({ start: 0, end: 1 });
        } else {
          categoryAxis.zoomToCategories(
            dataItem.category,
            dataItem.endCategory
          );
        }
      });

      // hover state
      var hoverState = axisFill.states.create("hover");
      hoverState.properties.innerRadius = -10;
      hoverState.properties.radius = -25;

      var axisLabel = axisRange.label;
      axisLabel.location = 0.5;
      axisLabel.fill = am4core.color("#ffffff");
      axisLabel.radius = 3;
      axisLabel.relativeRotation = 0;
    }

    var slider = sliderContainer.createChild(am4core.Slider);
    slider.start = 1;
    slider.exportable = false;
    slider.events.on("rangechanged", function () {
      var start = slider.start;

      chart.startAngle = 270 - start * 179 - 1;
      chart.endAngle = 270 + start * 179 + 1;

      valueAxis.renderer.axisAngle = chart.startAngle;
    });

    return () => {
      chart.dispose();
    };
  }, [chartData, id, type, title]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <h5 className="header-with-after-line">{title}</h5>
        </div>
      </div>
      <div id={type + id} className="rdo-graph"></div>
    </>
  );
};

export default RadarTimelineChart;
