import React, { useState, useEffect } from "react";
import axios from "../../axios";
import requests from "../../requests";
import { useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import NDVCharts from "../LGFI/NDVCharts";
import "react-tabs/style/react-tabs.css";
import "../../assets/css/tabs.css";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const LGFINationalDataVisualization = () => {
  const history = useHistory();
  let query = useQuery();
  // Declare State
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [years, setYears] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [charts, setCharts] = useState([]);
  // const [tabActive, setTabActive] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(
    parseInt(query.get("country"))
  );
  const [selectedYear, setSelectedYear] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  // const [tabIndex, setTabIndex] = useState(0);

  const fireLoading = () => {
    if (loading) {
      var element = document.getElementsByClassName("loader-wrap")[0];
      element.classList.add("active");
      // ele ment.classList.remove("my-class");
    } else {
      var element = document.getElementsByClassName("loader-wrap")[0];
      element.classList.remove("active");
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // Get Datas
      const requestDefaultData = await axios.get(
        requests.fetchCountryDefaultData + query.get("country")
      );
      // Set State
      setSelectedYear(requestDefaultData.data.data.years.reverse()[0].id);
      setCountries(requestDefaultData.data.data.countries);
      setYears(requestDefaultData.data.data.years);
      setTabs(requestDefaultData.data.data.tabs);
      setCharts(requestDefaultData.data.data.charts);
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleTabSelect = (tabid) => {
    async function fetchData() {
      setLoading(true);
      // Get Datas
      const postGenerateChart = await axios.post(requests.generateChart, {
        country_id: selectedCountry,
        year_id: selectedYear,
        tab_id: tabid,
      });
      // Set State
      setCharts(postGenerateChart.data.data.charts);
      setLoading(false);
    }
    fetchData();
  };

  // On Change Handlers
  const onChangeYear = (event) => {
    setSelectedYear(event.target.value);
    async function fetchData() {
      setLoading(true);

      // Get Datas
      const postGenerateChart = await axios.post(requests.generateChart, {
        country_id: selectedCountry,
        year_id: parseInt(event.target.value),
        tab_id: selectedTab,
      });
      // Set State
      setCharts(postGenerateChart.data.data.charts);
      setLoading(false);
    }
    fetchData();
  };
  const onChangeCountry = (event) => {
    setSelectedCountry(event.target.value);
    async function fetchData() {
      setLoading(true);

      // Get Datas
      const postGenerateChart = await axios.post(requests.generateChart, {
        country_id: parseInt(event.target.value),
        year_id: selectedYear,
        tab_id: selectedTab,
      });
      // Set State
      setCharts(postGenerateChart.data.data.charts);
      setLoading(false);
    }
    fetchData();
  };

  return (
    <>
      <Helmet>
        <title>National Data Visualization - NALAS Observatory</title>
      </Helmet>
      <div className="ndv-cont mapbg">
        <div className="container">
          <div className="row mt-4 mb-4">
            <div className="col-lg-12">
              <div className="row ml-1">
                <div className="col-xl-4  m-0 p-0">
                  {countries.length > 0 ? (
                    <div className="nalas-select-cont">
                      <label className="d-block custom-label">Economy</label>
                      <select
                        value={selectedCountry}
                        className="custom-select"
                        onChange={onChangeCountry}
                      >
                        {countries.map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}
                </div>
                <div className="col-xl-4  m-0 p-0">
                  {years.length > 0 ? (
                    <div className="nalas-select-cont mr-1">
                      <label className="d-block custom-label">Year</label>
                      <select
                        value={selectedYear}
                        className="custom-select"
                        onChange={onChangeYear}
                      >
                        {years.map((year) => (
                          <option key={year.id} value={year.id}>
                            {year.year}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}
                </div>
                <div className="main-tabs">
                  <div className="main-tabs-titles">
                    {tabs &&
                      tabs.map((tab) => (
                        <div
                          key={tab.id}
                          tabid={tab.id}
                          onClick={() => {
                            handleTabSelect(tab.id);
                            setSelectedTab(tab.id);
                          }}
                          className={`main-tabs-title ${
                            selectedTab === tab.id ? "selected-tab" : ""
                          }`}
                        >
                          <div className="main-tabs-content">
                            <img
                              src={require(`../../assets/images/ndv-icons/${tab.id}.png`)}
                              alt="tab-icon"
                              className="mr-2 main-tab-item main-tab-image"
                            />
                            <p className="main-tab-item">{tab.title}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row ndv-chart-list">
            <div className="col-md-12">
              {loading ? fireLoading() : null}
              <NDVCharts charts={charts} loading={loading} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LGFINationalDataVisualization;
