import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import PublicationList from "../shared-components/publications/PublicationList";
import Pagination from "../shared-components/Pagination";
import "../assets/css/filter.css";

const Publications = () => {
  const [posts, setPosts] = useState([]);
  const [post, setPost] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(4);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const res = await axios.get(
        "https://core.nalas-observatory.eu/api/publications?category_id=0"
      );
      setPosts(res.data.data);
      setPost(res.data.data);
      setLoading(false);
    };

    fetchPosts();
  }, []);

  const allPosts = () => {
    setPost(posts);
  };

  const fetchCatPosts = async (ids) => {
    setLoading(true);
    const res = await axios.get(
      `https://core.nalas-observatory.eu/api/publications?category_id=${ids}`
    );
    setPost(res.data.data);
    setLoading(false);
  };

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = post.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <>
      <Helmet>
        <title>Publications - NALAS Observatory</title>
      </Helmet>
      <div className="pb-item-cont  mapbg">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <h3 className="nalas-title">Publications</h3>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row col-spacing-50">
            <div className="col-12 col-lg-12">
              <PublicationList posts={currentPosts} loading={loading} />

              <div className="margin-top-70 margin-bottom-70">
                <Pagination
                  currentPage={currentPage}
                  postsPerPage={postsPerPage}
                  totalPosts={post.length}
                  paginate={paginate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Publications;
