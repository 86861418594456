import React from "react";
import RegionalRealTimeChart from "../../shared-components/charts/regional/RegionalRealTimeChart";
import FullColumnStackedChart from "../../shared-components/charts/regional/FullColumnStackedChart";
import AnimatedTimelineChart from "../../shared-components/charts/regional/AnimatedTimelineChart";
import ZoomableBubbleChart from "../../shared-components/charts/regional/ZoomableBubbleChart";
import MapToPieChart from "../../shared-components/charts/regional/MapToPieChart";
import BarRaceChart from "../../shared-components/charts/regional/BarRaceChart";
import AxisColumnChart from "../../shared-components/charts/regional/AxisColumnChart";
import MapChart from "../../shared-components/charts/regional/MapChart";

const RDVCharts = ({ charts, loading }) => {
  if (loading) {
    var element = document.getElementsByClassName("loader-wrap")[0];
    if (element) element.classList.add("active");
  } else {
    var element = document.getElementsByClassName("loader-wrap")[0];
    if (element) element.classList.remove("active");
  }
  const chartsConditions = (chart_id, type, id, title, data) => {
    if (chart_id === "FE_REAL_TIME_COLUMN_CHART_1") {
      return (
        <RegionalRealTimeChart
          id={id}
          type={type}
          title={title}
          chartData={data}
        />
      );
    } else if (chart_id === "FE_MAP_CHART_1") {
      return <MapChart id={id} type={type} title={title} chartData={data} />;
    } else if (chart_id === "FE_FULL_STACKED_COLUMN_CHART_1") {
      return (
        <FullColumnStackedChart
          id={id}
          type={type}
          title={title}
          chartData={data}
        />
      );
    } else if (chart_id === "FE_AXIS_COLUMN_CHART_1") {
      return (
        <AxisColumnChart id={id} type={type} title={title} chartData={data} />
      );
    } else if (chart_id === "FE_BAR_RACE_CHART_1") {
      return (
        <BarRaceChart id={id} type={type} title={title} chartData={data} />
      );
    } else if (chart_id === "FE_MAP_TO_PYE_CHART_1") {
      return (
        <MapToPieChart id={id} type={type} title={title} chartData={data} />
      );
    }
    // else if (chart_id === "FE_ANIMATED_BUBBLE_CHART_1") {
    //   return (
    //     <AnimatedTimelineChart
    //       id={id}
    //       type={type}
    //       title={title}
    //       chartData={data}
    //     />
    //   );
    // }
    else if (chart_id === "FE_ZOOM_BUBBLE_CHART_1") {
      return (
        <ZoomableBubbleChart
          id={id}
          type={type}
          title={title}
          chartData={data}
        />
      );
    }
  };

  return (
    <div className="row">
      {charts.map((chart) => (
        <div key={chart.id} className="col-md-12">
          <div className="chartCont">
            {chartsConditions(
              chart.fe_chart_id,
              chart.type,
              chart.id,
              chart.title,
              chart.data
              // chart.filters
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default RDVCharts;

// FE_FULL_STACKED_COLUMN_CHART_1
// FE_MAP_CHART_1
// FE_REAL_TIME_COLUMN_CHART_1
// FE_AXIS_COLUMN_CHART_1
// FE_MAP_TO_PYE_CHART_1
// FE_ZOOM_BUBBLE_CHART_1
// FE_BAR_RACE_CHART_1
// FE_ANIMATED_BUBBLE_CHART_1
