import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import "../../assets/css/pagination.css";
import "../../assets/css/single-post.css";

// Publications Item page
const PublicationsItem = () => {
  let { publicationsId } = useParams();
  const [publicationItem, setPosts] = useState({});
  const [recent, setRecent] = useState([]);

  const fetchPost = async () => {
    const res = await axios.get(
      `https://core.nalas-observatory.eu/api/publications/${publicationsId}`
    );
    setPosts(res.data.data);
    setRecent(res.data.data.most_recents);
  };

  useEffect(() => {
    fetchPost();
  }, [publicationsId]);

  return (
    <>
      <div className="pb-item-cont mapbg">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <h3 className="nalas-title">Publications</h3>
            </div>
            <div className="single-post-title col-md-12">
              <p>{publicationItem.created_at}</p>
              <h1> {publicationItem.title}</h1>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-md-4">
                  <div className="single-post-img">
                    <img src={publicationItem.preview_image} />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="single-post-entry">
                    <p>{publicationItem.description}</p>
                  </div>
                  <div className="pdf">
                    <a href={publicationItem.pdf_url} download>
                      Download Report
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="related row">
            <h3 className="recent-title">Most Recent</h3>
            {recent.map((p) => (
              <div key={p.id} className="col-md-4">
                <div className="row">
                  <div className="col-md-5">
                    <img src={p.preview_image} />
                  </div>
                  <div className="col-md-7">
                    <p>{p.created_at}</p>
                    <h4>{p.title}</h4>
                    <Link
                      to={(location) => ({
                        ...location,
                        pathname: `/publications/${p.id}`,
                      })}
                      className="readmore"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicationsItem;
