import React, { useState, useEffect } from "react";
import axios from "../../axios";
import requests from "../../requests";
import { Helmet } from "react-helmet";
import RDVCharts from "./RDVCharts";
import "react-tabs/style/react-tabs.css";
import "../../assets/css/tabs.css";

const LGFIRegionalDataVisualizations = () => {
  // Declare State
  const [loading, setLoading] = useState(false);
  const [years, setYears] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [units, setUnits] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [charts, setCharts] = useState([]);

  const [selectedYear, setSelectedYear] = useState();
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedUnit, setSelectedUnit] = useState();
  const [selectedIndicator, setSelectedIndicator] = useState();

  const fireLoading = () => {
    if (loading) {
      var element = document.getElementsByClassName("loader-wrap")[0];
      element.classList.add("active");
    } else {
      var element = document.getElementsByClassName("loader-wrap")[0];
      element.classList.remove("active");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // Get Datas
      const requestDefaultData = await axios.post(
        requests.generateRegionalTabs,
        {
          tab_id: 1,
        }
      );
      // Set State
      setSelectedYear(requestDefaultData.data.data.years.reverse()[0].id);
      setYears(requestDefaultData.data.data.years);
      setCharts(requestDefaultData.data.data.charts);
      setTabs(requestDefaultData.data.data.tabs);
      setUnits(requestDefaultData.data.data.units);
      setIndicators(requestDefaultData.data.data.indicators);
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleTabSelect = (tabid) => {
    async function fetchData() {
      setLoading(true);
      // Get Datas
      const postGenerateTabs = await axios.post(requests.generateRegionalTabs, {
        year_id: tabid === 2 ? null : selectedYear,
        tab_id: tabid,
        indicator_id: selectedIndicator,
        unit_id: selectedUnit,
      });

      // Set State
      setYears(postGenerateTabs.data.data.years.reverse());
      setCharts(postGenerateTabs.data.data.charts);
      setIndicators(postGenerateTabs.data.data.indicators);
      setUnits(postGenerateTabs.data.data.units);
      setLoading(false);
    }
    fetchData();
  };

  // On Change Years Handlers
  const onChangeYear = (event) => {
    setSelectedYear(parseInt(event.target.value));
    async function fetchData() {
      setLoading(true);
      // Get Datas
      const postGenerateTabs = await axios.post(requests.generateRegionalTabs, {
        year_id: selectedTab === 2 ? null : parseInt(event.target.value),
        tab_id: selectedTab,
        indicator_id: selectedIndicator,
        unit_id: selectedUnit,
      });

      // Set State
      setCharts(postGenerateTabs.data.data.charts);
      setIndicators(postGenerateTabs.data.data.indicators);
      setUnits(postGenerateTabs.data.data.units);
      setLoading(false);
    }
    fetchData();
  };
  // On Change Indicators Handlers
  const onChangeIndicators = (event) => {
    setSelectedIndicator(parseInt(event.target.value));
    async function fetchData() {
      setLoading(true);
      // Get Datas
      const postGenerateTabs = await axios.post(requests.generateRegionalTabs, {
        year_id: selectedTab === 2 ? null : selectedYear,
        tab_id: selectedTab,
        indicator_id: parseInt(event.target.value),
        unit_id: selectedUnit,
      });

      // Set State
      setCharts(postGenerateTabs.data.data.charts);
      setUnits(postGenerateTabs.data.data.units);
      setLoading(false);
    }
    fetchData();
  };

  // On Change Indicators Handlers
  function onChangeUnits(event) {
    setSelectedUnit(event.target.value);
    async function fetchData() {
      setLoading(true);
      // Get Datas
      const postGenerateTabs = await axios.post(requests.generateRegionalTabs, {
        year_id: selectedTab === 2 ? null : selectedYear,
        tab_id: selectedTab,
        indicator_id: selectedIndicator,
        unit_id: parseInt(event.target.value),
      });

      // Set State
      setCharts(postGenerateTabs.data.data.charts);
      setIndicators(postGenerateTabs.data.data.indicators);
      setLoading(false);
    }
    fetchData();
  }

  return (
    <>
      <Helmet>
        <title>Regional Data Visualization - NALAS Observatory</title>
      </Helmet>
      <div className="ndv-cont mapbg">
        <div className="container">
          <div className="row mt-4 mb-4">
            <div className="col-md-12">
              <div className="row ml-1">
                <div className="col-xl-4 col-lg-5 col-md-12 m-0 p-0">
                  {years.length > 0 ? (
                    selectedTab === 2 ? null : (
                      <div className="nalas-select-cont mr-2">
                        <label className="d-block custom-label">Year</label>
                        <select
                          value={selectedYear}
                          className="custom-select"
                          onChange={onChangeYear}
                        >
                          {years.map((year) => (
                            <option key={year.id} value={year.id}>
                              {year.year}
                            </option>
                          ))}
                        </select>
                      </div>
                    )
                  ) : null}
                </div>
                <div className="col-xl-4 col-lg-5 col-md-12 m-0 p-0">
                  {indicators.length > 0 ? (
                    <div className="nalas-select-cont">
                      <label className="d-block custom-label">Indicators</label>
                      <select
                        value={selectedIndicator}
                        className="custom-select"
                        onChange={onChangeIndicators}
                      >
                        {indicators.map((ind) => (
                          <option key={ind.id} value={ind.id}>
                            {ind.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}
                </div>
                <div className="col-xl-4 col-lg-5 col-md-12 m-0 p-0">
                  {units.length > 0 ? (
                    <div className="nalas-select-cont">
                      <label className="d-block custom-label">Units</label>
                      <select
                        value={selectedUnit}
                        className="custom-select"
                        onChange={onChangeUnits}
                      >
                        {units.map((unit) => (
                          <option key={unit.id} value={unit.id}>
                            {unit.unit}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}
                </div>
                <div className="main-tabs">
                  <div className="main-tabs-titles">
                    {tabs &&
                      tabs.map((tab) => (
                        <div
                          key={tab.id}
                          tabid={tab.id}
                          onClick={() => {
                            handleTabSelect(tab.id);
                            setSelectedTab(tab.id);
                          }}
                          className={`main-tabs-title ${
                            selectedTab === tab.id ? "selected-tab" : ""
                          }`}
                        >
                          <div className="main-tabs-content">
                            <img
                              src={require(`../../assets/images/rdv-icons/${
                                tab.id - 1
                              }.png`)}
                              alt="tab-icon"
                              className="mr-2 main-tab-item main-tab-image"
                            />
                            <p className="main-tab-item">{tab.title}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12 mt-0 pt-0">
              {loading ? fireLoading() : null}
              <RDVCharts charts={charts} loading={loading} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LGFIRegionalDataVisualizations;
