import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import data from "../../data.json";

const newData = data.data.map((x) => ({
  country: x.country.code,
  population: x.average_population_first_tier,
}));
am4core.useTheme(am4themes_animated);

const Bar = ({ id, type, title }) => {
  const chart = useRef(null);

  useLayoutEffect(() => {
    let chart = am4core.create(`${type}${id}`, am4charts.XYChart);
    chart.data = newData;
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.dataFields.category = "country";
    categoryAxis.title.text = "Countries";
    categoryAxis.renderer.inside = true;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Population";
    valueAxis.renderer.inside = false;

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.name = "Population";
    series.columns.template.tooltipText =
      "Series: {name}\nCategory: {categoryX}\nValue: {valueY}";
    series.dataFields.valueY = "population";
    series.dataFields.categoryX = "country";

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = title.replace(/\s/g, "");

    const exportFields = { ...chart.exporting.dataFields };
    delete exportFields.color;
    delete exportFields.partOfChart;
    delete exportFields.unit_as_part_of_label;
    chart.exporting.dataFields = exportFields;

    return () => {
      chart.dispose();
    };
  }, [newData, id, type, title]);

  return <div id={type + id} style={{ width: "100%", height: "500px" }}></div>;
};
export default Bar;
