import React from "react";
import ColumnChart from "../../shared-components/charts/ColumnChart";
import ColumnStackedChart from "../../shared-components/charts/ColumnStackedChart";
import DonutChart from "../../shared-components/charts/DonutChart";
import LineChart from "../../shared-components/charts/LineChart";
import LineAndStackedChart from "../../shared-components/charts/LineAndStackedChart";
import MultiLineChart from "../../shared-components/charts/MultiLineChart";
import BigColumnsChart from "../../shared-components/charts/BigColumnsChart";
import RealTimeChart from "../../shared-components/charts/RealTimeChart";
import FullColumnStackedChart from "../../shared-components/charts/FullColumnStackedChart";
import ColumnRotatedSeriesChart from "../../shared-components/charts/ColumnRotatedSeriesChart";
import DateBasedChart from "../../shared-components/charts/DateBasedChart";
import CSCWithTabs from "../../shared-components/charts/CSCWithTabs";

const NDVCharts = ({ charts, loading }) => {
  if (loading) {
    var element = document.getElementsByClassName("loader-wrap")[0];
    if (element) element.classList.add("active");
  } else {
    var element = document.getElementsByClassName("loader-wrap")[0];
    if (element) element.classList.remove("active");
  }
  const chartsConditions = (
    chart_id,
    type,
    id,
    title,
    data,
    has_filters,
    filters
  ) => {
    if ((chart_id === "FE_COLUMN_CHART_1") & (has_filters === 0)) {
      return <ColumnChart id={id} type={type} title={title} />;
    } else if ((chart_id === "FE_BIG_COLUMN_CHART_1") & (has_filters === 0)) {
      return (
        <BigColumnsChart
          id={id}
          type={type}
          title={title}
          chartData={data.labels}
        />
      );
    } else if (
      (chart_id === "FE_REAL_TIME_COLUMN_CHART_1") &
      (has_filters === 0)
    ) {
      return (
        <RealTimeChart id={id} type={type} title={title} chartData={data} />
      );
    } else if (
      (chart_id === "FE_FULL_STACKED_COLUMN_CHART_1") &
      (has_filters === 0)
    ) {
      return (
        <FullColumnStackedChart
          id={id}
          type={type}
          title={title}
          chartData={data}
        />
      );
    } else if (
      (chart_id === "FE_COLUMN_ROTATED_SERIES_CHART_1") &
      (has_filters === 0)
    ) {
      return (
        <ColumnRotatedSeriesChart
          id={id}
          type={type}
          title={title}
          chartData={data}
        />
      );
    } else if ((chart_id === "FE_DATA_BASED_CHART_1") & (has_filters === 0)) {
      return (
        <DateBasedChart id={id} type={type} title={title} chartData={data} />
      );
    } else if ((chart_id === "FE_DONUT_CHART_1") & (has_filters === 0)) {
      return (
        <DonutChart id={id} type={type} title={title} chartData={data.labels} />
      );
    } else if (
      (chart_id === "FE_STACKED_COLUMN_CHART_1") &
      (has_filters === 0)
    ) {
      return (
        <ColumnStackedChart
          id={id}
          type={type}
          title={title}
          chartData={data}
        />
      );
    } else if (
      (chart_id === "FE_LINE_CHART_1") &
      (type === "multi_line_chart") &
      (has_filters === 0)
    ) {
      return (
        <MultiLineChart id={id} type={type} title={title} chartData={data} />
      );
    } else if (
      (chart_id === "FE_LINE_CHART_1") &
      (type === "line_chart") &
      (has_filters === 0)
    ) {
      return <LineChart id={id} type={type} title={title} chartData={data} />;
    } else if (
      (chart_id === "FE_MIXED_STACKED_COLUMN_CHART_1") &
      (has_filters === 0)
    ) {
      return (
        <LineAndStackedChart
          id={id}
          type={type}
          title={title}
          chartData={data}
        />
      );
    } else if (has_filters === 1) {
      return (
        <CSCWithTabs
          id={id}
          type={type}
          title={title}
          chartData={data}
          filters={filters}
        />
      );
    }
  };

  return (
    <div className="row">
      {charts.map((chart) => (
        <div key={chart.id} className="col-md-6">
          <div className="chartCont">
            {chartsConditions(
              chart.fe_chart_id,
              chart.type,
              chart.id,
              chart.title,
              chart.data,
              chart.has_filters,
              chart.filters
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default NDVCharts;
