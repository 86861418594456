import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import $, { post } from "jquery";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../assets/css/post-slider.css";
window.jQuery = $;
require("owl.carousel");

// Posts Slider Homepage
const PostSlider = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPostSlider = async () => {
      setLoading(true);
      const res = await axios.get(
        "https://core.nalas-observatory.eu/api/publications?category_id=0"
      );
      setPosts(res.data.data);
      setLoading(false);
      $(".post-slider").owlCarousel({
        loop: true,
        nav: true,
        dots: false,
        responsive: {
          0: {
            items: 1,
            nav: false,
          },
          768: {
            items: 3,
          },
          992: {
            items: 3,
          },
        },
      });
    };

    fetchPostSlider();
  }, []);

  return (
    <>
      <div className="section">
        <div className="container">
          <div className="post-slider owl-carousel">
            {posts.length > 0
              ? posts.map((post, index) => (
                  <div key={index}>
                    <div className="post-slider-image">
                      <Link to={`publications/${post.id}`}>
                        <img src={post.preview_image} alt="" />
                      </Link>
                    </div>
                    <div className="post-slider-entry">
                      <h5 className="font-weight-normal margin-0">
                        <Link to={`publications/${post.id}`}>{post.title}</Link>
                      </h5>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default PostSlider;
