import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "../assets/plugins/bootstrap/bootstrap.min.css";
import "../assets/css/navbar.css";
import $ from "jquery";
import logo from "../assets/images/logo.png";
import loader from "../assets/images/loading.gif";

function Navbar() {
  useEffect(() => {
    var headerMenu = $(".header-menu");
    var headerToggle = $(".header-toggle");

    headerToggle.on("click", function (e) {
      if (headerMenu.hasClass("show")) {
        headerMenu.removeClass("show");
        headerToggle.removeClass("toggle-close");
      } else {
        headerMenu.addClass("show");
        headerToggle.addClass("toggle-close");
      }
      e.stopPropagation();
    });

    $(document).on("click", function (e) {
      if (
        $(e.target).closest(".nav-dropdown-toggle, .nav-subdropdown-toggle")
          .length === 0
      ) {
        if (headerMenu.hasClass("show")) {
          headerMenu.removeClass("show");
          headerToggle.removeClass("toggle-close");
        }
      }
    });

    var navDropdown = $(".nav-dropdown");
    var navSubdropdown = $(".nav-subdropdown");

    navDropdown.each(function () {
      var $this = $(this);
      $this
        .parent(".nav-item")
        .children(".nav-link")
        .addClass("nav-dropdown-toggle");
    });
    navSubdropdown.each(function () {
      var $this = $(this);
      $this
        .parent(".nav-dropdown-item")
        .children(".nav-dropdown-link")
        .addClass("nav-subdropdown-toggle");
    });

    // Dropdown Menu //
    var navDropdownToggle = $(".nav-dropdown-toggle");
    var navSubdropdownToggle = $(".nav-subdropdown-toggle");

    navDropdownToggle.each(function () {
      var $this = $(this);
      if ($this.parent(".nav-item").children(".nav-dropdown").length) {
        var navDropdown = $this.parent(".nav-item").children(".nav-dropdown");
        $this.on("click", function (e) {
          if ($this.hasClass("active")) {
            $this.removeClass("active");
            navDropdown.removeClass("show");
          } else {
            $this.addClass("active");
            navDropdown.addClass("show");
          }
          // e.preventDefault();
        });
      }
    });

    // Subdropdown Menu //
    navSubdropdownToggle.each(function () {
      var $this = $(this);
      if (
        $this.parent(".nav-dropdown-item").children(".nav-subdropdown").length
      ) {
        var navSubDropdown = $this
          .parent(".nav-dropdown-item")
          .children(".nav-subdropdown");
        $this.on("click", function (e) {
          if ($this.hasClass("active")) {
            $this.removeClass("active");
            navSubDropdown.removeClass("show");
          } else {
            $this.addClass("active");
            navSubDropdown.addClass("show");
          }
          //  e.preventDefault();
        });
      }
    });
  });

  const handleDisableClick = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="loader-wrap text-center">
        <img src={loader} alt="loader" className="loader" />
      </div>
      <div className="header right dark sticky-autohide">
        <div className="container-fluid">
          <div className="header-logo">
            <h3>
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </h3>
          </div>
          <div className="header-menu">
            <ul className="nav">
              <li className="nav-item">
                <NavLink
                  exact
                  to="/"
                  className="nav-link"
                  activeClassName="active"
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/data/local-government-finance-indicators"
                  className="nav-link nav-dropdown-toggle"
                  activeClassName="active"
                  onClick={handleDisableClick}
                >
                  Data and visualizations
                </NavLink>
                <ul className="nav-dropdown">
                  <li className="nav-dropdown-item">
                    <NavLink
                      to="/data/local-government-finance-indicators"
                      className="nav-dropdown-link"
                      activeClassName="active"
                    >
                      <span className="nav-arrow">
                        <i className="fas fa-angle-left mr-1"></i>
                      </span>
                      Local Government Finance
                    </NavLink>
                    <ul className="nav-subdropdown">
                      <li className="nav-subdropdown-item">
                        <NavLink
                          to="/data/lgfi/national-data-visualization-list"
                          className="nav-subdropdown-link"
                          activeClassName="active"
                        >
                          National Indicators
                        </NavLink>
                      </li>
                      <li className="nav-subdropdown-item">
                        <NavLink
                          to="/data/lgfi/regional-data-visualization"
                          className="nav-subdropdown-link"
                          activeClassName="active"
                        >
                          Regional Indicators
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-dropdown-item">
                    <NavLink
                      to="#"
                      className="nav-dropdown-link"
                      activeClassName="active"
                    >
                      <span className="nav-arrow">
                        <i className="fas fa-angle-left mr-1"></i>
                      </span>
                      Waste Management
                    </NavLink>
                    <ul className="nav-subdropdown">
                      <li className="nav-subdropdown-item">
                        <NavLink
                          to="/data/swm-indicators/circular-economy"
                          className="nav-subdropdown-link"
                          activeClassName="active"
                        >
                          Circular Economy
                        </NavLink>
                      </li>
                      <li className="nav-subdropdown-item">
                        <NavLink
                          to="/data/swm-indicators/swm"
                          className="nav-subdropdown-link"
                          activeClassName="active"
                        >
                          Solid Waste Management
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-dropdown-item">
                    <NavLink
                      to="/decentralisation-progress"
                      className="nav-dropdown-link"
                      activeClassName="active"
                    >
                      Decentralisation progress
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <NavLink
                  to="/learning"
                  className="nav-link nav-dropdown-toggle"
                  activeClassName="active"
                >
                  Learning
                </NavLink>
                <ul className="nav-dropdown">
                  <li className="nav-dropdown-item">
                    <NavLink
                      to="/publications"
                      className="nav-dropdown-link"
                      activeClassName="active"
                    >
                      Publications
                    </NavLink>
                  </li>
                  <li className="nav-dropdown-item">
                    <NavLink
                      to="/#"
                      className="nav-dropdown-link"
                      activeClassName="active"
                    >
                      <span className="nav-arrow">
                        <i className="fas fa-angle-left mr-1"></i>
                      </span>
                      Resources
                    </NavLink>
                    <ul className="nav-subdropdown">
                      <li className="nav-subdropdown-item">
                        <NavLink
                          to="/legal-framework"
                          className="nav-subdropdown-link"
                          activeClassName="active"
                        >
                          Legislation
                        </NavLink>
                      </li>
                      {/* <li className="nav-subdropdown-item">
                        <NavLink
                          to="/articles"
                          className="nav-subdropdown-link"
                          activeClassName="active"
                        >
                          Articles
                        </NavLink>
                      </li> */}
                      <li className="nav-subdropdown-item">
                        <NavLink
                          to="/digest"
                          className="nav-subdropdown-link"
                          activeClassName="active"
                        >
                          Practices
                        </NavLink>
                      </li>
                      {/* <li className="nav-subdropdown-item">
                        <NavLink
                          to="/#"
                          className="nav-subdropdown-link"
                          activeClassName="active"
                        >
                          COVID-19 Updates
                        </NavLink>
                      </li> */}
                    </ul>
                  </li>
                  {/* <li className="nav-dropdown-item">
                    <NavLink
                      to="/infographics"
                      className="nav-dropdown-link"
                      activeClassName="active"
                    >
                      Infographics
                    </NavLink>
                  </li> */}
                </ul>
              </li>

              <li className="nav-item">
                <NavLink
                  to="/self-assessment"
                  className="nav-link"
                  activeClassName="active"
                >
                  Self-Assessment
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink
                  to="/useful-links"
                  className="nav-link"
                  activeClassName="active"
                >
                  Useful Links
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink
                  to="/about"
                  className="nav-link"
                  activeClassName="active"
                >
                  About us
                </NavLink>
                {/* <ul className="nav-dropdown"> */}
                {/* <li className="nav-dropdown-item">
                    <NavLink
                      to="/#"
                      className="nav-dropdown-link"
                      activeClassName="active"
                    >
                      Project
                    </NavLink>
                  </li> */}
                {/*  <li className="nav-dropdown-item">
                    <NavLink
                      to="/#"
                      className="nav-dropdown-link"
                      activeClassName="active"
                    >
                      Team
                    </NavLink>
                  </li> */}
                {/* <li className="nav-dropdown-item">
                    <NavLink
                      to="/#"
                      className="nav-dropdown-link"
                      activeClassName="active"
                    >
                      <span className="nav-arrow">
                        <i className="fas fa-angle-left mr-1"></i>
                      </span>
                      Policy Areas
                    </NavLink>
                    <ul className="nav-subdropdown">
                      <li className="nav-subdropdown-item">
                        <NavLink
                          to="/#"
                          className="nav-subdropdown-link"
                          activeClassName="active"
                        >
                          Fiscal Decentralisation
                        </NavLink>
                      </li>
                      <li className="nav-subdropdown-item">
                        <NavLink
                          to="/#"
                          className="nav-subdropdown-link"
                          activeClassName="active"
                        >
                          Local Government Services
                        </NavLink>
                      </li>
                      <li className="nav-subdropdown-item">
                        <NavLink
                          to="/#"
                          className="nav-subdropdown-link"
                          activeClassName="active"
                        >
                          Local Government Reform
                        </NavLink>
                      </li>
                    </ul>
                  </li> */}
                {/* <li className="nav-dropdown-item">
                    <NavLink
                      to="/#"
                      className="nav-dropdown-link"
                      activeClassName="active"
                    >
                      Members
                    </NavLink>
                  </li> */}
                {/* </ul> */}
              </li>
            </ul>
          </div>
          <button className="header-toggle">
            <span></span>
          </button>
        </div>
      </div>
    </>
  );
}

export default Navbar;
