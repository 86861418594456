import React from "react";
import { Helmet } from "react-helmet";
// Sections Components
import AboutSection from "../shared-components/AboutSection";
import PostSlider from "../shared-components/PostSlider";
import CounterSection from "../shared-components/CounterSection";
import PartnersSection from "../shared-components/PartnersSection";
import HomeVideo from "./HomeVideo";

function Home() {
  return (
    <>
      <Helmet>
        <title>NALAS Observatory</title>
      </Helmet>
      <HomeVideo />
      <AboutSection />
      <CounterSection />
      <PostSlider />
      <PartnersSection />
    </>
  );
}

export default Home;
